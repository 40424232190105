import classNames from 'classnames';

import styles from './lessons-schedule.module.css';

const LessonsScheduleHead = ({ current, week, onClickDay }) => {
  return (
    <div className={styles.days}>
      {week.map((itemDate, index) => {
        return (
          <div className={styles.day} onClick={() => onClickDay && onClickDay(index)} key={index}>
            <div className={classNames(
              styles.dayNumber,
              (itemDate.getDate() === new Date().getDate()) && styles.current,
              (itemDate.getDate() === new Date(current).getDate()) && styles.active
            )}>
              {itemDate.getDate()}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LessonsScheduleHead;
