import styles from './select-icon.module.css';

const SelectIcon = ({ label }) => {
  if (!label) return null;

  return (
    <div className={styles.root}>
      <div className={styles.item}>
        {label}
        <img src="/icons/up-down.svg" className={styles.icon} width={16} height={16} alt="" />
      </div>
    </div>
  );
};

export default SelectIcon;
