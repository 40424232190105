import { TailSpin } from 'react-loader-spinner';

import styles from './loader.module.css';

const Loader = ({ show }) => {
  if (!show) return null;
  return (
    <div className={styles.root}>
      <TailSpin color="var(--brand-color)" height="64" width="64" />
    </div>
  );
};

export default Loader;
