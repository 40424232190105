import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import NoItems from '../NoItems';

import styles from './rewards-list.module.css';
import Button from 'components/Button';

const medalColors = {
  M: '#05C1ED',
  Y: '#6470B5',
  W: '#99CC63',
  Q: '#FFD16A',
};

const RewardsList = ({ items = [1, 2, 3, 4, 5], onAcceptItem }) => {
  if (!Object.keys(items).length) return ( // no items
    <NoItems children="Нет назначенных наград" />
  );

  return (
    <div className={styles.items}>
      {Object.values(items).map(({ uid, name, type, used }, index) => {
        if (!uid) return (
          <div className={classNames(styles.item, 'skeleton-loader')} key={index} />
        );

        return (
          <div className={styles.item} key={index}>
            <ReactSVG className={styles.icon} src="/icons/reward.svg" wrapper="span" style={{ color: medalColors[type] }} />
            <div className={styles.text}>{name}</div>
            {(onAcceptItem && !used) && (
              <Button className={styles.button} butStyle="primary" onClick={() => onAcceptItem(uid)}>
                Получил
              </Button>
            )}
            {used && (
              <ReactSVG className={styles.checkmark} src="/icons/checkmark-circle.svg" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RewardsList;
