import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import PortfailUpdate from 'components/portfail/PortfailUpdate';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';

const PortfailUpdatePage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));

  const { data: portfail, reset: resetPortfail } = useApiQuery({
    name: ['portfail', user?.uid],
    path: `/getuserportfail/${user?.uid}/`,
    skip: !user
  });

  const handleClickItem = (uid = 0, name, customLessonUid = 0, itemUid) => {
    const formdata = new FormData();
    formdata.append('params[user_uid]', user.uid);
    formdata.append('params[name]', name);
    formdata.append('params[non_std_lesson_uid]', customLessonUid);
    formdata.append('params[std_uid]', uid);
    if (itemUid) formdata.append('params[uid]', itemUid);

    api
      .post(`/setuserportfail/`, formdata)
      .then(response => {
        const result = response.data.response;

        if (result) {
          resetPortfail();
          dispatch(setItem(['notifications', 'updateSchedule'], { type: 'success', content: 'Сохранено' }));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'updateSchedule'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  if (!user) return null;

  return (
    <MainLayout title="Портфель">
      <PortfailUpdate
        user={user}
        items={portfail}
        onClickItem={handleClickItem}
      />
    </MainLayout>
  );
};

export default PortfailUpdatePage;
