import { forwardRef, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import Modal from 'components/Modal';

import baseStyles from '../fields.module.css';
import styles from './color-select.module.css';
import SelectIcon from './SelectIcon';

const ColorSelect = forwardRef(({ id, name, hidden, value, onChange, options, className, disabled,
  containerClass, title, label, fullWidth, error, icon, notice, inputClass
}, ref) => {
  const [ isModalOpen, setModalOpen ] = useState(false);
  const [ tmpValue, setTmpValue ] = useState(value);

  const handleFocus = (e) => {
    setModalOpen(true);
  };
  const handleClick = (e) => {
    setModalOpen(true);
  };

  useEffect(() => {
    setTmpValue(value);
  }, [value]);

  return (
    <div className={classnames(baseStyles.container, fullWidth && baseStyles.fullWidth, containerClass, hidden && baseStyles.hidden)}>
      {title && <div className={baseStyles.title}>{title}</div>}
      <div className={baseStyles.inputContainer}>
        <SelectIcon label={value && options?.find(option => option.value === value)?.label} />
        <input
          type="hidden"
          id={id}
          name={name}
          value={value}
          readOnly={true}
          ref={ref}
        />
        <div
          onClick={handleClick}
          onFocus={handleFocus}
          className={classnames('input', styles.input, styles.input, icon && styles.withIcon, inputClass, className, error && 'error', value && 'filled', disabled && 'disabled')}
        >
          {label}
        </div>
        {icon &&
          <span className={baseStyles.icon}>{icon}</span>
        }
      </div>
      {error &&
        <span className={baseStyles.errorText}>{error}</span>
      }
      {notice &&
        <span className={baseStyles.notice}>{notice}</span>
      }
      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        viewStyle="bottom"
        closeOnDocumentClick={false}
        buttons={[
          {
            className: styles.backButton,
            butStyle: "tertiary",
            icon: <ReactSVG src="/icons/arrow-left.svg" wrapper="span" />,
            action: 'close'
          },
          {
            butStyle: "primary",
            text: 'Сохранить',
            onClick: () => onChange({ target: { name: name, value: tmpValue }}),
            action: 'close'
          }
        ]}
      >
        <div className={styles.modal}>
          {options?.map(({ value }, index) => (
            <div
              className={classnames(styles.box, (tmpValue === value) && styles.active)}
              style={{ backgroundColor: value.split(', ')[0] }}
              tabIndex="0"
              onClick={() => setTmpValue(value)}
              key={index}
            >
              <span className={styles.boxLine} style={{ backgroundColor: value.split(', ')[1] }} />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
});

export default ColorSelect;