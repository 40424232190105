export const player_menu = [
  {
    id: 'menu_home',
    name: 'Главная',
    href: '/',
    icon: '/icons/menu/home.svg',
    routes: []
  },
  {
    id: 'menu_shop',
    name: 'Магазин',
    href: '/store',
    icon: '/icons/menu/shop.svg',
    routes: []
  },
  {
    id: 'menu_shop',
    name: 'Сундук',
    href: '/chest',
    icon: '/icons/menu/chest.svg',
    routes: []
  },
  {
    id: 'rating',
    name: 'Рейтинг',
    href: '/rating',
    icon: '/icons/menu/rating.svg',
    routes: []
  },
  // {
  //   id: 'scan',
  //   name: 'Сканировать',
  //   href: '#code-scaner-start',
  //   icon: '/icons/menu/scan2.svg',
  //   routes: [],
  //   hidden: true
  // }
];

export const mentor_short_menu = [
  {
    id: 'menu_home',
    name: 'Главная',
    href: '/',
    icon: '/icons/menu/home.svg',
    routes: [
      '/briefcase-update', '/player-lessons-update', '/player-lessons-update/homework',
      '/player-lessons-update/time', '/player-lessons-update/schedule'
    ]
  },
  {
    id: 'menu_profile',
    name: 'Профиль',
    href: '/update',
    icon: '/icons/menu/user.svg',
    // routes: ['/update']
  },
  // {
  //   id: 'scan',
  //   name: 'Сканировать',
  //   href: '#code-scaner-start',
  //   icon: '/icons/menu/scan2.svg',
  //   routes: [],
  //   hidden: true
  // }
];

export const mentor_menu = [
  {
    id: 'menu_home',
    name: 'Главная',
    href: '/player',
    icon: '/icons/menu/home.svg',
    routes: [
      '/briefcase-update', '/player-lessons-update', '/player-lessons-update/homework',
      '/player-lessons-update/time', '/player-lessons-update/schedule'
    ]
  },
  {
    id: 'menu_shop',
    name: 'Магазин',
    href: '/store-update',
    icon: '/icons/menu/shop.svg',
    routes: []
  },
  {
    id: 'menu_shop',
    name: 'Сундук',
    href: '/chest',
    icon: '/icons/menu/chest.svg',
    routes: []
  },
  {
    id: 'menu_profile',
    name: 'Профиль',
    href: '/update-player',
    icon: '/icons/menu/user.svg',
    // routes: ['/update']
  },
  // {
  //   id: 'scan',
  //   name: 'Сканировать',
  //   href: '#code-scaner-start',
  //   icon: '/icons/menu/scan2.svg',
  //   routes: [],
  //   hidden: true
  // }
];

export const teenager_menu = [
  {
    id: 'menu_home',
    name: 'Главная',
    href: '/',
    icon: '/icons/menu/home.svg',
    routes: [
      '/briefcase-update', '/lessons-schedule', '/player-lessons-update', '/player-lessons-update/homework',
      '/player-lessons-update/time', '/player-lessons-update/schedule'
    ]
  },
  {
    id: 'menu_shop',
    name: 'Магазин',
    href: '/store',
    icon: '/icons/menu/shop.svg',
    routes: []
  },
  {
    id: 'menu_shop',
    name: 'Сундук',
    href: '/chest',
    icon: '/icons/menu/chest.svg',
    routes: []
  },
  {
    id: 'rating',
    name: 'Рейтинг',
    href: '/rating',
    icon: '/icons/menu/rating.svg',
    routes: []
  },
  // {
  //   id: 'scan',
  //   name: 'Сканировать',
  //   href: '#code-scaner-start',
  //   icon: '/icons/menu/scan2.svg',
  //   routes: [],
  //   hidden: true
  // }
];
