import classNames from 'classnames';
import { format, getHours } from 'date-fns';
import useApiQuery from 'hooks/useApiQuery';
import { useSelector } from 'react-redux';
import { selectItem } from 'store/app/getters';

import styles from './lessons-schedule.module.css';

const tableStartHour = 7;
const tableEndHour = 23;

const hourHeight = 41;
const firstHourOffsetTop = 20;

const LessonsScheduleDay = ({ dateValue, onClickItem, skip }) => {
  const user = useSelector(selectItem(['user']));
  const uid = user?.type === 'M' ? user.current_player?.uid_gamer : user?.uid;

  const { data: schedule } = useApiQuery({
    name: ['user_deals', uid, dateValue],
    path: `/getuserdeals/${uid}/${dateValue}/`,
    skip: !uid || !dateValue || skip
  });

  const now = new Date();
  const lineNowPosition = (now.getHours()-tableStartHour)*hourHeight + now.getMinutes()*hourHeight/60 + firstHourOffsetTop;

  return (
    <div className={styles.content}>
      <div className={styles.items}>
        {schedule?.map(({ uid, name, color, start, end, date, type }) => {
          const [ bgColor, textColor ] = color?.split(', ') ?? [];
          const [ startHour, startMinute ] = start?.split(':') ?? [];
          const [ endHour, endMinute ] = end?.split(':') ?? [];
          const itemPosition = firstHourOffsetTop + ((startHour-tableStartHour)*hourHeight) + (startMinute*hourHeight/60);
          const itemHeight = ((endHour - startHour)*hourHeight) + ((endMinute - startMinute)*hourHeight/60);

          const equalStart = schedule.filter(item => item.start === start);
          const width = 100 / equalStart.length;
          const left = width * equalStart.findIndex(item => item.uid === uid);

          return (
            <div
              className={styles.item}
              style={{
                top: itemPosition + 'px',
                left: left + '%',
                height: itemHeight + 'px',
                paddingTop: itemHeight < 20 ? '0' : '4px',
                paddingBottom: itemHeight < 20 ? '0' : '4px',
                background: bgColor,
                color: textColor,
                zIndex: ['L', 'P', 'W'].includes(type) ? -1 : 0,
                width: width + '%'
              }}
              onClick={() => (onClickItem && !['L', 'P', 'W'].includes(type)) && onClickItem(uid, date, type)}
              key={uid}
            >
              <span className={styles.itemName}>{name}</span>
              <span className={styles.itemTime}>{format(new Date(date + 'T' + start), 'HH:mm')}</span>
            </div>
          );
        })}
      </div>
      {[...Array(tableEndHour+1-tableStartHour).keys()].map(index => {
        const hour = index+tableStartHour;
        let hideHour = false;
        if (dateValue === format(now, 'yyyy-MM-dd')) {
          if (hour === Number(format(now, 'HH')) && Number(format(now, 'mm')) < 15) hideHour = true;
          else if (hour-1 === Number(format(now, 'HH')) && Number(format(now, 'mm')) > 45) hideHour = true;
        }

        return (
          <div className={styles.row} key={index}>
            <span className={styles.hour}>
              {!hideHour && format(new Date(new Date().setHours(hour, 0)), 'HH:mm')}
            </span>
            <span className={styles.line} />
          </div>
        );
      })}

      {(dateValue === format(now, 'yyyy-MM-dd') && getHours(now) >= tableStartHour) && (
        <div className={classNames(styles.row, styles.now)} style={{ top: lineNowPosition + 'px' }}>
          <span className={styles.hour}>{format(now, 'HH:mm')}</span>
          <span className={styles.line} />
        </div>
      )}
    </div>
  );
};

export default LessonsScheduleDay;
