import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Checkbox from 'components/fields/Checkbox';
import Input from 'components/fields/Input';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalSubscribe from 'components/modals/ModalSubscribe';

import styles from './lessons-update.module.css';

const LessonsUpdate = ({ user, lessons, onChange, onChangeAll, showCustom }) => {
  const [ customName, setCustomName ] = useState('');
  const [ isShowCustom, setShowCustom ] = useState(false);
  const [ isShowSubscribe, setShowSubscribe ] = useState(false);

  const handleClickCustom = () => {
    if (user?.tariff?.actual) setShowCustom(true);
    else setShowSubscribe(true);
  };

  return (
    <div className={styles.root}>
      <Checkbox
        id="all"
        name="all"
        label={<b>Выбрать все</b>}
        containerClass={styles.item}
        onChange={e => onChangeAll(e.target.checked)}
        checked={lessons?.findIndex(({ checked }) => !checked) === -1}
      />
      {lessons ? (
        lessons.map(({ uid, lesson_uid, name, checked }) => (
          <Checkbox
            id={uid}
            name={uid}
            label={name}
            containerClass={styles.item}
            onChange={e => onChange(uid, lesson_uid, name, e.target.checked)}
            checked={checked}
            key={uid}
          />
        ))
      ) : (
        [...Array(5).keys()].map(key => (
          <div className={classNames(styles.item, 'skeleton-loader')} key={key} />
        ))
      )}
      {(lessons && showCustom) && (
        <>
          <Button
            butStyle="lightBlueSimple"
            className={styles.custom}
            size="medium"
            icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
            iconClassName={styles.customIcon}
            onClick={handleClickCustom}
            fullWidth
          >
            Свой вариант
          </Button>
          <Modal
            open={isShowCustom}
            viewStyle="bottom"
            title="Введите название урока"
            onClose={() => { setShowCustom(false); setCustomName(''); }}
            buttons={[
              {
                butStyle: 'primary',
                disabled: customName.length < 3,
                text: 'Сохранить',
                action: 'close',
                onClick: () => onChange(null, null, customName, true)
              }
            ]}
          >
            <input type="hidden" autoFocus />
            <Input
              value={customName}
              onChange={e => setCustomName(e.target.value)}
              placeholder="Математика"
            />
          </Modal>
          <ModalSubscribe open={isShowSubscribe} onClose={() => setShowSubscribe(false)} />
        </>
      )}
    </div>
  );
};

export default LessonsUpdate;
