import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import Routing from './pages/Routing';
import getStore from './store';
import getCurrentHost from 'helpers/getCurrentHost';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';

const App = () => {
  const store = getStore();

  useEffect(() => {
    const currentHost = process.env.PUBLIC_URL || getCurrentHost();
    document.head.querySelector('link[rel="canonical"]').href = currentHost;
    document.head.querySelector('meta[property="og:site_name"]').content = window?.location?.hostname;
    document.head.querySelector('meta[property="og:url"]').content = currentHost;
  }, []);

  return (
    <Provider store={store}>
      <Routing />
    </Provider>
  );
};

export default App;
