import { useSelector } from 'react-redux';
import RewardsList from 'components/RewardsList';
import { MainLayout } from 'components/Layouts';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';

const Rewards = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;

  const { data: shop } = useApiQuery({
    name: ['shop', currentPlayer?.uid_gamer],
    path: `/getshop/${currentPlayer?.uid_gamer}/`,
    skip: !user || !currentPlayer
  });

  if (!user) return null;

  return (
    <MainLayout title="Награды от вас">
      <RewardsList items={shop?.byed} />
    </MainLayout>
  );
};

export default Rewards;
