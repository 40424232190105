import classNames from 'classnames';
import ScheduleHomePlaceholder from './ScheduleHomePlaceholder';

import styles from './schedule-home.module.css';

const ScheduleHome = ({ user, schedule }) => {
  if (!schedule) return (
    <div className={classNames(styles.root, 'skeleton-loader')} />
  );

  return (
    <ScheduleHomePlaceholder user={user} schedule={schedule} />
  );
};

export default ScheduleHome;
