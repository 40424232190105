import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';

import styles from './Schedule.module.css';

const SelectLessonType = () => {
  const user = useSelector(selectItem(['user']));

  if (!user) return null;

  return (
    <MainLayout
      title="Добавить в расписание"
    >
      <ButtonsContainer className={styles.selectLessontTypeButtons}>
        <Button
          className={styles.buttonImp}
          to="/lessons-schedule-update?type=Y"
          fullWidth
        >
          Важное дело
        </Button>
        <Button
          className={styles.buttonHw}
          to="/lessons-schedule-update?type=R"
          fullWidth
        >
          Внешкольное занятие
        </Button>
        <Button
          className={styles.buttonDeal}
          to="/lessons-schedule-update?type=H"
          fullWidth
        >
          Домашнее дело
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default SelectLessonType;
