import Slider from 'react-slick';
import classNames from 'classnames';
import HomeworkListItem from 'components/homework/HomeworkListItem';
import HomeworkPlaceholder from './HomeworkPlaceholder';
import HomeworkCrystalls from 'components/homework/HomeworkListItem/HomeworkCrystalls';

import styles from './homework.module.css';

const HomeworkHome = ({ user, schedule, isset_long, tasks, settings }) => {
  if (!schedule) return (
    <div className={classNames(styles.root, 'skeleton-loader')} />
  );

  const isSetted = schedule && Object.values(schedule).findIndex(({ shedule }) => !!(shedule && shedule.uid)) !== -1;

  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    // centerMode: true,
    // centerPadding: '20px',
  };

  if (!(isSetted && tasks && Object.keys(tasks).length)) return (
    <HomeworkPlaceholder user={user} isSetted={isSetted} />
  );

  return (
    <div className={styles.list}>
      <Slider {...sliderSettings}>
        {Object.values(tasks).slice(0, 1).map((item, index) => (
          <HomeworkListItem
            id={item.for_date}
            date={item.date}
            for_date={item.for_date}
            user={user}
            status={item.status}
            settings={settings}
            statusText={item.status_text}
            statusCode={item.status_code}
            items={item.tasks}
            bigReward={item.big_reward}
            key={index}
          />
        ))}
        {isset_long && (
          <HomeworkCrystalls />
        )}
        {Object.values(tasks).slice(1).map((item, index) => (
          <HomeworkListItem
            id={item.for_date}
            date={item.date}
            for_date={item.for_date}
            user={user}
            status={item.status}
            settings={settings}
            statusText={item.status_text}
            statusCode={item.status_code}
            items={item.tasks}
            bigReward={item.big_reward}
            key={index}
          />
        ))}
      </Slider>
    </div>
  );
};

export default HomeworkHome;
