import { useState } from 'react';
import Checkbox from 'components/fields/Checkbox';
import Switcher from 'components/fields/Switcher';

import styles from './lessons-schedule-update.module.css';

const ScheduleDay = ({ day, name, lessons, playerLessons, onChange }) => {
  const [ isSelected, setSelected ] = useState(!!lessons.length);

/* {
  "shedule_uid": 2,
  "user_uid": 10001,
  "lesson_uid": 2,
  "day": 1,
  "priority": 0,
  "name": "Русский язык",
  "set_task": 1
} */

  return (
    <div className={styles.item}>
      <Switcher
        id={'day_' + day}
        name={'day_' + day}
        label={name}
        containerClassName={styles.switcher}
        checked={isSelected}
        onChange={e => {
          setSelected(e.target.checked);
          if (!e.target.checked) onChange(day, []);
        }}
      />

      {(isSelected && playerLessons) && (
        <div className={styles.lessons}>
          {playerLessons?.map(({ uid, name }) => (
            <Checkbox
              id={`${day}_${uid}`}
              name={`${day}_${uid}`}
              containerClass={styles.lesson}
              label={name}
              onChange={e => {
                let next = [...lessons.map(({ lesson_uid }) => lesson_uid)];
                if (!e.target.checked) next.splice(next.findIndex(item => item === uid), 1);
                else next.push(uid)

                onChange(day, next);
              }}
              checked={lessons.findIndex(item => uid === item.lesson_uid) !== -1}
              key={`${day}_${uid}`}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const LessonsScheduleUpdate = ({ schedule, lessons, onChangeDay }) => {
  if (!schedule) return null;

  return (
    <div className={styles.root}>
      {Object.keys(schedule).map(day => (
        <ScheduleDay
          day={day}
          playerLessons={lessons}
          onChange={onChangeDay}
          {...schedule[day]}
          key={day}
        />
      ))}
    </div>
  );
};

export default LessonsScheduleUpdate;
