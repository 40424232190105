import { useSelector } from 'react-redux';
import RewardsList from 'components/RewardsList';
import { MainLayout } from 'components/Layouts';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';

import styles from './Rewards.module.css';

const RewardsMentor = () => {
  const user = useSelector(selectItem(['user']));
  const settings = useSelector(selectItem(['settings']));

  const { data: rewards } = useApiQuery({
    name: ['rewards_all'],
    path: `/playerrewardsall/${user.uid}/player/`,
    skip: !user || !user.uid
  });

  if (!user) return null;

  return (
    <MainLayout title="Награды от вас">
      <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: settings && settings['rewards-from-mentor-top-text'] }} />
      <RewardsList items={rewards} />
    </MainLayout>
  );
};

export default RewardsMentor;
