import { forwardRef, useState } from 'react';
import { format } from 'date-fns';
import classnames from 'classnames';
import ModalSelectDate from '../DateInput/ModalSelectDate';
import DateTimeIcon from './DateTimeIcon';

// import 'react-datepicker/dist/react-datepicker.min.css';
// import '../DateInput/date-input.css';

import baseStyles from '../fields.module.css';
// import styles from './date-select.module.css';

const DateSelect = forwardRef(({ id, name, placeholder, type='text', hidden, value, labelClass, onChange,
  showDatePicker = true, minDate, maxDate, showTimePicker, timeStep = 5,
  containerClass, title, label, fullWidth, error, notice, pattern, inputClass, ...props
}, ref) => {
  const [ isModalOpen, setModalOpen ] = useState(false);

  const handleFocus = (e) => {
    setModalOpen(true);
  };
  const handleClick = (e) => {
    setModalOpen(true);
  };

  const handleSave = (value) => {
    const saveDateFormat = `yyyy-MM-dd${showTimePicker ? ' HH:mm' : ''}`;
    const val = format(new Date(value), saveDateFormat);
    onChange && onChange({target: { name: name, value: val }});
  };

  const inputDateFormat = `${showDatePicker ? 'dd.MM.yyyy' : ''}${(showDatePicker && showTimePicker) ? ' ' : ''}${showTimePicker ? 'HH:mm' : ''}`;

  return (
    <div className={classnames(baseStyles.container, fullWidth && baseStyles.fullWidth, containerClass, hidden && baseStyles.hidden)}>
      {title && <div className={baseStyles.title}>{title}</div>}
      {/* {label && <label className={classnames(baseStyles.label, labelClass)} htmlFor={id}>{label}</label>} */}
      <div className={baseStyles.inputContainer}>
        <span
          onClick={handleClick}
          className={classnames('input', baseStyles.input, inputClass && inputClass, error && 'error', value && 'filled')}
        >
          {label}
        </span>
        <input
          style={{ display: 'none' }}
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          value={value ? format(new Date(value), inputDateFormat) : ''}
          pattern={pattern}
          onFocus={handleFocus}
          onClick={handleClick}
          readOnly
          ref={ref}
          {...props}
        />
        <DateTimeIcon value={value} />
      </div>
      {error &&
        <span className={baseStyles.errorText}>{error}</span>
      }
      {notice &&
        <span className={baseStyles.notice}>{notice}</span>
      }
      {isModalOpen && (
        <ModalSelectDate
          name={name}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          defaultValue={value}
          showDatePicker={showDatePicker}
          showTimePicker={showTimePicker}
          onSave={handleSave}
          minDate={minDate}
          maxDate={maxDate}
          timeStep={timeStep}
        />
      )}
    </div>
  );
});

export default DateSelect;