import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Shop from 'components/Shop';
import CrystalsBalance from 'components/CrystalsBalance';
import useApiQuery from 'hooks/useApiQuery';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import * as api from 'helpers/api';

const Store = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));

  const { data: shop } = useApiQuery({
    name: ['shop'],
    path: `/getshop/${user.uid}/`,
    skip: !user
  });

  const handleClickBuy = ({ uid }) => {
    api
      .get(`/getshop/${user.uid}/${uid}/`)
      .then(response => {
        const result = response.data.response;
        if (result.success_mess) {
          dispatch(setItem(['notifications', 'getshop'], { type: 'success', content: result.success_mess }));
          dispatch(setItem(['shop'], result, { isHard: true }));
        }
        if (result.error) {
          dispatch(setItem(['notifications', 'getshop'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <MainLayout
      title="Магазин"
      showBackButton={false}
      rightButton={<div style={{ marginLeft: 'auto' }}><CrystalsBalance crystals={shop?.crystalls} /></div>}
    >
      <Shop items={shop?.shop} crystalls={shop?.crystalls} onClickBuy={handleClickBuy} />
    </MainLayout>
  );
};

export default Store;
