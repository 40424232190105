import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import AboutBlocks from 'components/homepage/AboutBlocks';
import CrystalsBalance from 'components/CrystalsBalance';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';

import styles from './Backpack.module.css';

const Backpack = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const crystalls = currentPlayer.info ? currentPlayer.info.crystalls : currentPlayer.crystalls;

  if (!user) return null;

  return (
    <MainLayout title="Сундук" showBackButton={false}>
      <div className={styles.header}>
        <CrystalsBalance crystals={crystalls} />
        <Button
          to="/store-update"
          butStyle="primary"
          className={styles.button}
          size="medium"
        >
          В магазин
        </Button>
      </div>

      <AboutBlocks />
    </MainLayout>
  );
};

export default Backpack;
