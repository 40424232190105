import { Link } from 'react-router-dom';
import { forwardRef } from 'react';
import classnames from 'classnames';

import styles from './button.module.css';

const Button = forwardRef(({
    id, type = 'button', to, href, className, butStyle, size, onClick, fullWidth, disabled, icon, iconSize, iconClassName,
    target, onMouseDown, onMouseUp, onMouseLeave, children, state
  }, ref) => {

  let Component = 'button';
  if (href) Component = 'a';
  else if (to) Component = Link;

  return (
    <Component
      id={id}
      type={type}
      to={to}
      href={href}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      className={classnames(styles.main, fullWidth && styles.fullWidth, butStyle && styles[butStyle], size && styles[size], disabled && styles.disabled, className && className)}
      disabled={disabled}
      target={target}
      state={state}
      ref={ref}
    >
      {icon && <span className={classnames(styles.icon, iconSize && styles[iconSize], iconClassName)}> {icon} </span>} {children}
    </Component>
  );
});

export default Button;