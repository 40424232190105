import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Switch from 'components/fields/Switch';
import PlayersList from 'components/PlayersList';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import ButtonsGrid from 'components/ButtonsGrid';
import InfoBlock from 'components/InfoBlock';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';

import styles from './mentor.module.css';

const Rating = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player ? user.current_player : null;
  const [ type, setType ] = useState('');

  const tabs = [
    {
      label: 'В России',
      value: ''
    },
    {
      label: 'В классе',
      value: 'class'
    },
    {
      label: 'В школе',
      value: 'school'
    }
  ];

  const school = currentPlayer ? (currentPlayer.info ? currentPlayer.info.school : currentPlayer.school) : null;
  const class_s = currentPlayer ? (currentPlayer.info ? currentPlayer.info.class : currentPlayer.class) : null;
  const class_sign = currentPlayer ? (currentPlayer.info ? currentPlayer.info.class_sign : currentPlayer.class_sign) : null;

  const { data: ratingAll } = useApiQuery({
    name: ['rating_all', currentPlayer?.uid_gamer],
    path: `/ratingschoolclass/${currentPlayer?.uid_gamer}/`,
    skip: !user || !currentPlayer,
    mapper: (data = {}) => {
      if (!data.rating) return null;
      const next = Object.keys(data.rating).reduce((acc, current) => ({
        ...acc,
        [current]: data.rating[current].map(item => ({ ...item, rank: current === 'class' ? item.rank_class : item.rank_school }))
      }), {});
      return next;
    }
  });

  const { data: rating } = useApiQuery({
    name: ['rating', currentPlayer?.uid_gamer],
    path: `/ratingschoolclass/${currentPlayer?.uid_gamer}/${school}/${class_s}/${class_sign}/`,
    skip: !user || !currentPlayer || !school,
    mapper: (data = {}) => {
      if (!data.rating) return null;
      const next = Object.keys(data.rating).reduce((acc, current) => ({
        ...acc,
        [current]: data.rating[current].map(item => ({ ...item, rank: current === 'class' ? item.rank_class : item.rank_school }))
      }), {});
      return next;
    }
  });

  if (!user) return null;

  const onChangeTab = (e) => {
    setType(e.target.value);
  };

  return (
    <MainLayout title="Недельный рейтинг" showBackButton={false}>
      {currentPlayer ? (
        <>
          <Switch
            id="school"
            name="school"
            options={tabs}
            value={type}
            onChange={(e) => onChangeTab(e)}
          />
          {school && class_s && class_sign ? (
            (rating && ratingAll) && (
              <PlayersList
                players={type === '' ? [...ratingAll?.school].sort((a, b) => a.rank - b.rank) : rating[type].sort((a, b) => a.rank - b.rank)}
                current={currentPlayer ? currentPlayer.uid_gamer : null}
              />
            )
          ) : (
            <div style={{ marginTop: '25px' }}>
              <InfoBlock color="orange">
                <div className={styles.ratingNoSchool}>
                  В сервисе “настройки” <br /> выберите школу и класс
                  <ButtonsGrid buttons={[{ name: 'Настройки', link: '/update', icon: '/images/services/settings.png' }]} />
                </div>
              </InfoBlock>
            </div>
          )}
        </>
      ) : (
        // <>
        //   {(user.player && user.player.length) ? 'Выберите игрока для просмотра рейтинга' : 'Для просмотра рейтинга добавьте игрока'}
        //   <div style={{ marginTop: '24px' }} />
        //   <PlayersSelect users={user.player} />
        // </>
        <>
          <div className="text-center" style={{ marginTop: '60px' }}>Добавьте минимум 1 ребенка для просмотра рейтинга</div>
          <ButtonsContainer>
            <Button
              to="/add-player"
              fullWidth={true}
              butStyle="primary"
              state={{ from: '/create' }}
              size="medium"
            >
              {/* <ReactSVG src="/icons/plus.svg" className={styles.icon} /> */}
              Добавить ребенка
            </Button>
          </ButtonsContainer>
        </>
      )}
    </MainLayout>
  );
};

export default Rating;
