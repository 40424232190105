import { useDispatch } from 'react-redux';
import QRCode from 'react-qr-code';
import { ReactSVG } from 'react-svg';
import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
import { setItem } from '../../store/app/actions';
import getCurrentHost from '../../helpers/getCurrentHost';
import copyToClipboard from '../../helpers/copyToClipboard';

import styles from './player-login.module.css';

const PlayerLogin = ({ player, showStartButton }) => {
  const dispatch = useDispatch();

  if (!player) return null;

  const self_key = player.info ? player.info.self_key : player.self_key;

  const handleClickShare = (key) => {
    const result = key ? copyToClipboard(`${getCurrentHost()}/auth/${key}`) : false;

    if (result)
      dispatch(setItem(['notifications', 'copy_link'], { type: 'success', content: 'Ссылка скопирована' }));
    else
      dispatch(setItem(['notifications', 'copy_link'], { type: 'error', content: 'Ошибка копирования' }));
  }

  return (
    <div>
      <div className={styles.qrCode}>
        {self_key && <QRCode value={`${getCurrentHost()}/auth/${self_key}`} size={200} />}
      </div>
      <div className="text-center">Отсканируйте или поделитесь ссылкой с ребенком, чтобы он мог войти в свой Аккаунт и начать играть</div>
      <ButtonsContainer>
        <Button
          fullWidth={true}
          butStyle="secondary"
          size="small"
          onClick={() => handleClickShare(self_key)}
        >
          <ReactSVG src="/icons/attach.svg" wrapper="span" className={styles.buttonIcon} />
          Поделиться ссылкой
        </Button>
        {showStartButton &&
          <Button
            to="/"
            fullWidth={true}
            butStyle="primary"
            size="small"
          >
            Вперед в Me-Timer!
          </Button>
        }
      </ButtonsContainer>
    </div>
  )
};

export default PlayerLogin;