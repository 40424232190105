import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from 'components/Layouts';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import * as api from 'helpers/api';
import LessonsTimeUpdate from 'components/schedule/LessonsTimeUpdate';

const LessonsTimeUpdatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectItem(['user']));
  const player = useSelector(selectItem(['players', user.uid]));
  const [ values, setValues ] = useState(null);

  const handleSave = () => {
    if (!values) {
      navigate('/player-lessons-update/schedule');
      return null;
    }

    const formdata = new FormData();
    const { start, duration, recess } = values;

    formdata.append('params[user_uid]', user.uid);
    formdata.append('params[school_start]', start.replace('T', ' '));
    formdata.append('params[time_lesson]', duration.replace('T', ' '));
    formdata.append('params[turn_lesson]', recess).replace('T', ' ');

    api
      .post(`/updateUserProfile/${user.uid}/`, formdata)
      .then(response => {
        const result = response.data.response;
        if (result.user) {
          // dispatch(setItem(['players', user.uid, 'school_start'], start));
          // dispatch(setItem(['players', user.uid, 'time_lesson'], duration));
          // dispatch(setItem(['players', user.uid, 'turn_lesson'], recess));
          navigate('/player-lessons-update/schedule');
        } else {
          const error = result.error ? result.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'playerSchedule'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  if (!user || !player) return null;

  return (
    <MainLayout
      title="Расписание"
      description="Расписание уроков"
    >
      <LessonsTimeUpdate
        onChange={setValues}
        school_start={player?.school_start}
        time_lesson={player?.time_lesson}
        turn_lesson={player?.turn_lesson}
      />
      <ButtonsContainer>
        <Button
          butStyle="primary"
          onClick={handleSave}
          disabled={
            !(player && player.school_start && player.time_lesson && player.turn_lesson) &&
            !(values && values.start && values.duration && values.recess)
          }
        >
          Далее
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default LessonsTimeUpdatePage;
