import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import AboutBlocks from 'components/homepage/AboutBlocks';
import CrystalsBalance from 'components/CrystalsBalance';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';

import styles from './Backpack.module.css';

const Backpack = () => {
  const user = useSelector(selectItem(['user']));

  if (!user) return null;

  return (
    <MainLayout title="Сундук" showBackButton={false}>
      <div className={styles.header}>
        <CrystalsBalance crystals={user.crystalls} />
        <Button
          to="/store"
          butStyle="primary"
          className={styles.button}
          size="medium"
        >
          В магазин
        </Button>
      </div>

      <AboutBlocks />
    </MainLayout>
  );
};

export default Backpack;
