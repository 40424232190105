import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';

import styles from './time-picker.module.css';

const debounce = (func, timeout = 0) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
};

const scrollToTimeElem = (elem) => {
  elem.parentElement.scrollTo({ top: elem.offsetTop - elem.offsetHeight, left: 0, behavior: 'smooth' });
};

const TimePicker = ({ name, value, timeStep, minDate, maxDate, onChange }) => {
  const hoursRef = useRef(null);
  const minsRef = useRef(null);

  const [ selectedHour, selectedMinute ] = value ? format(value, 'HH:mm').split(':') : [];
  const minTime = minDate ? format(minDate, 'HH:mm').split(':') : ['00','00'];
  const maxTime = maxDate ? format(maxDate, 'HH:mm').split(':') : ['23','59'];

  useEffect(() => {
    const hoursScroller = hoursRef?.current;
    const minutesScroller = minsRef?.current;

    const handleScroll = debounce(e => {
      for (const elem of e.target.childNodes) {
        if (elem.offsetTop - elem.offsetHeight === e.target.scrollTop) {
          let val = value ?? new Date();

          if (e.target.dataset.type === 'hours') val = value ? val.setHours(elem.innerText) : val.setHours(elem.innerText, 0, 0);
          else val = value ? val.setMinutes(elem.innerText) : val.setHours(0, elem.innerText, 0);

          onChange(new Date(val));
        }
      }
    }, 100);

    if (hoursScroller) {
      hoursScroller.addEventListener('scroll', handleScroll);
      const hourElem = value ? hoursScroller.querySelector('.time-active') : hoursScroller.querySelector(`[data-time="${maxTime[0] > '09' ? '09' : minTime[0]}"]`);
      if (hourElem) scrollToTimeElem(hourElem);
    }

    if (minutesScroller) {
      minutesScroller.addEventListener('scroll', handleScroll);
      const minsElem = value ? minutesScroller.querySelector('.time-active') : minutesScroller.querySelector(`[data-time="${minTime[1]}"]`);
      if (minsElem) scrollToTimeElem(minsElem);
    }

    return () => {
      if (hoursScroller) hoursScroller.removeEventListener('scroll', handleScroll);
      if (minutesScroller) minutesScroller.removeEventListener('scroll', handleScroll);
    };

    // eslint-disable-next-line
  }, [hoursRef, minsRef, value]);

  const handleClickHour = (e, hours) => scrollToTimeElem(e.target);
  const handleClickMinute = (e, minutes) => scrollToTimeElem(e.target);

  let hours = [...Array(24).keys()].map(item => ('0' + item).slice(-2));
  let mins = [...Array(60).keys()];
  if (minTime) {
    const indexHour = hours.findIndex(hour => Number(hour) === Number(minTime[0]));
    hours.splice(0, indexHour);

    const indexMinute = mins.findIndex(minute => Number(minute) === Number(minTime[1]));
    if (minTime[0] === selectedHour) mins.splice(0, indexMinute);
  }

  if (maxTime) {
    const index = hours.findIndex(hour => Number(hour) > Number(maxTime[0]));
    if (index !== -1) {
      hours.splice(index);
      if (maxTime[0] === selectedHour) mins.splice(maxTime[1], mins.length);
    }
  }

  let minutes = [];
  for (let i = 0; i < mins.length; i += timeStep) {
    minutes.push(('0' + mins[i]).slice(-2));
  }

  return (
    <div className={styles.root}>
      <div className={styles.col} data-type="hours" ref={hoursRef}>
        <div className={classNames(styles.cell)}>&nbsp;</div>
        {hours.map(hour => (
          <div
            className={classNames(styles.cell, hour === selectedHour && styles.active, hour === selectedHour && 'time-active')}
            onClick={(e) => handleClickHour(e, hour)}
            data-time={hour}
            key={hour}
          >
            {hour}
          </div>
        ))}
        <div className={classNames(styles.cell)}>&nbsp;</div>
      </div>
      <div className={styles.divider}>:</div>
      <div className={styles.col} data-type="minutes" ref={minsRef}>
        <div className={classNames(styles.cell)}>&nbsp;</div>
        {minutes.map(minute => (
          <div
            className={classNames(styles.cell, minute === selectedMinute && styles.active, minute === selectedMinute && 'time-active')}
            onClick={(e) => handleClickMinute(e, minute)}
            data-time={minute}
            key={minute}
          >
            {minute}
          </div>
        ))}
        <div className={classNames(styles.cell)}>&nbsp;</div>
      </div>
    </div>
  );
};

export default TimePicker;
