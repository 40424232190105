import { forwardRef, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTimer } from 'react-timer-hook';
import classNames from 'classnames';
import * as api from 'helpers/api';
import Button from 'components/Button';
import Input from 'components/fields/Input';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';

import styles from './phone-code.module.css';

const resendTimeout = 60;

const CodeNotice = ({ resendTimeout, onClickResend, onExpire }) => {
  const expiryTimestamp = new Date();
  expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + resendTimeout);
  const { seconds, minutes, isRunning } = useTimer({ expiryTimestamp, onExpire: () => onExpire() });

  return (
    <>
      {/* На указанный номер телефона была отправлена СМС с кодом подтверждения <br /> */}
      {(isRunning) ? (
        <span className={classNames(styles.resendNotice)}>
          Отправить код повторно можно через {('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)} сек
        </span>
      ) : (
        <a href="/" onClick={e => { e.preventDefault(); onClickResend() }}>Отправить код повторно</a>
      )}
    </>
  );
};

const PhoneCode = forwardRef(({ title, value, error, inputClass, onPhoneSubmit, path, codeAdditionalUrl, showSubmit, disabled, notice, ...props }, ref) => {
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const code = useRef(null);
  const verification = useSelector(selectItem(['verification']));

  const setResendCode = () => {
    code.current.value = '';
    sendCode();
  }

  const setResendTimer = () => {
    dispatch(setItem(['verification', 'isTimerExpiried'], false));
  }

  const handleChangeCode = (code) => {
    if (code && !code.match(/_/)) { // simple check filling
      dispatch(setItem(['verification', 'code'], code));
      dispatch(setItem(['verification', 'checkCode'], true));
    }
  }

  useEffect(() => {
    dispatch(setItem(['verification'], {}, { isHard: true }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (verification.sendCode) {
      dispatch(setItem(['verification', 'sendCode'], false));
      setTimeout(() => sendCode(), 200);
    }

    if (verification.code && verification.checkCode) {
      dispatch(setItem(['verification', 'checkCode'], false));
      checkCode(verification.checkingPhone, verification.code);
    }

    // eslint-disable-next-line
  }, [verification]);

  useEffect(() => {
    if (verification.checkingPhone !== value) {
      dispatch(setItem(['verification'], {
        checkingPhone: value,
        isCodeValid: false,
        isCodeSended: false
      }));
    }

    // eslint-disable-next-line
  }, [verification, value]);

  useEffect(() => {
    if (error) dispatch(setItem(['verification', 'error'], error));
    else if (verification.error) dispatch(setItem(['verification', 'error'], null));

    // eslint-disable-next-line
  }, [verification, error]);

  const sendCode = (phone = verification.checkingPhone) => {
    if (!phone || error || verification.error) return;

    dispatch(setItem(['show_loader'], true));

    api
      .get(`${path}/${phone.split(' ').join('')}/`)
      .then(response => {
        const result = response.data.response;
        if (result.error) {
          dispatch(setItem(['verification'], {
            checkingPhone: phone,
            isCodeSended: false,
            resendTimeout: resendTimeout,
            error: result.error
          }));

          const error = response.data.response.error ? response.data.response.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'sendCode'], { type: 'error', content: error }));
        } else {
          dispatch(setItem(['verification'], {
            checkingPhone: phone,
            isCodeSended: true,
            resendTimeout: resendTimeout,
            notice: result.send_code
          }));

          timerRef.current = setTimeout(() => {
            dispatch(setItem(['notifications', 'sendCode'], { type: 'error', content: 'Если Вы используете VPN, отключите его на время, он может блокировать приход СМС' }));
          }, 5000);
        }
      })
      .catch(() => {
        dispatch(setItem(['notifications', 'login'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      })
      .finally(() => {
        dispatch(setItem(['show_loader'], false));
      });
  };

  const checkCode = (phone = verification.checkingPhone, code) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    if (code.replaceAll(' ', '').length < 4) return;

    dispatch(setItem(['show_loader'], true));

    api
      .get(`${path}/${phone.split(' ').join('')}/${code}/${codeAdditionalUrl ? codeAdditionalUrl : ''}`)
      .then(response => {
        const result = response.data.response;
        if (result && result.success) {
          if (onPhoneSubmit) onPhoneSubmit(result);
          dispatch(setItem(['verification', 'isCodeValid'], true));
        } else {
          dispatch(setItem(['verification', 'isCodeValid'], false));

          if (response.data.error) dispatch(setItem(['verification', 'codeError'], response.data.error));
          else dispatch(setItem(['verification', 'codeError'], 'Неверный код из смс'));
        }
      })
      .finally(() => {
        dispatch(setItem(['show_loader'], false));
      });
  };

  return (
    <>
      <Input
        {...props}
        value={value}
        title={title}
        ref={ref}
        error={verification.error}
        containerClass={(verification.isCodeSended && !verification.isCodeValid) && 'hidden'}
        inputClass={inputClass}
        notice={
          (showSubmit || verification.isCodeValid) &&
            <>
              {(showSubmit && !verification.isCodeValid) &&
                <Button
                  fullWidth={true}
                  butStyle="primary"
                  onClick={e => { e.preventDefault(); sendCode(); }}
                  disabled={error || disabled}
                >
                  Подтвердить
                </Button>
              }
              {verification.isCodeValid && 'Подтвержден'}
              {!showSubmit && notice}
            </>
        }
      />

      {(verification.isCodeSended && !verification.isCodeValid) &&
        <>
          <Input
            type="tel"
            title={title}
            label="Введите код"
            placeholder="0000"
            fullWidth={true}
            mask="####"
            onChange={e => handleChangeCode(e.target.value)}
            ref={code}
            error={verification.isCodeValid === false && verification.codeError}
            autoFocus={true}
            inputClass={inputClass}
            notice={
              <>
                {verification.notice &&
                  <div>{verification.notice}</div>
                }
                <CodeNotice
                  resendTimeout={resendTimeout}
                  onClickResend={setResendCode}
                  onExpire={() => setResendTimer(0)}
                />
              </>
            }
          />
        </>
      }
    </>
  );
});

export default PhoneCode;