import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { format } from 'date-fns';
import { MainLayout } from 'components/Layouts';
import Select from 'components/fields/Select';
import InputsContainer from 'components/fields/InputsContainer';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';
import DateSelect from 'components/fields/DateSelect';
import ColorSelect from 'components/fields/ColorSelect';
import ScheduleReward from 'components/schedule/ScheduleReward';
import Modal from 'components/Modal';
import { setItem } from 'store/app/actions';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';

import { tasksColors, notificationOptions, wayTimeOptions } from 'data/mock';

const UpdateLesson = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { date, uid } = useParams();
  const { state } = useLocation();
  const user = useSelector(selectItem(['user']));
  const dealsPeriod = useSelector(selectItem(['settings', 'deals_period']));
  const [ rewards, setRewards ] = useState({});

  const handleToogleReward = (key, reward) => {
    setRewards(prev => {
      const next = { ...prev };
      next[key] = reward;
      return prev;
    });
  };

  const { control, handleSubmit, setValue, watch, formState: { isValid } } = useForm({
    mode: 'onSubmit'
  });

  // const { data: lessonsPlayer } = useApiQuery({
  //   name: ['lessons_player', user?.uid],
  //   path: `/getlesson/${user?.uid}/selected/`,
  //   skip: !user
  // });

  const { data: scheduleList } = useApiQuery({
    name: ['user_deals', user?.uid, date],
    path: `/getuserdeals/${user?.uid}/${date}/`,
    skip: !user || !date || !uid
  });

  const { data: mainDeals } = useApiQuery({
    name: ['deals_main'],
    path: `/getdealsmain/`,
  });

  const schedule = scheduleList?.find(item => item.uid === Number(uid));

  const minDate = format(new Date(), 'yyyy-MM-dd') + 'T07:00';
  const minDateEnd = format(new Date(), 'yyyy-MM-dd') + 'T08:00';

  useEffect(() => {
    if (state?.date) setValue('start', `${state.date}T07:00`);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (schedule) {
      setValue('name', schedule.name ?? '');
      setValue('start', schedule.date ? `${schedule.date}T${schedule.start}` : minDate);
      setValue('end', schedule.date ? `${schedule.date}T${schedule.end}` : minDateEnd);
      setValue('notification', schedule.set_task ?? '');
      setValue('color', schedule.color ?? '');
      setValue('push_before_min', schedule.push_before_min ?? '');
      setValue('returned_type', schedule.returned_type ?? '');
      setValue('time_path', schedule.time_path ?? '');
    }
    // eslint-disable-next-line
  }, [schedule]);

  if (date && uid && !schedule) return null;

  const onFormSubmit = (data) => {
    const [ date, time ] = data.start.match('T') ? data.start.split('T') : data.start.split(' ');
    const formdata = new FormData();
    formdata.append('params[user_uid]', user?.uid);
    if (uid) formdata.append('params[deal_uid]', uid);
    if (uid) formdata.append('params[update]', true);
    else formdata.append('params[insert]', true);
    // formdata.append('params[delete]', '0');
    if (data.color) formdata.append('params[color]', data.color);
    formdata.append('params[date]', date);
    formdata.append('params[start]', time);
    formdata.append('params[end]', data.end.match('T') ? data.end.split('T')[1] : data.end.split(' ')[1]);

    if (data.push_before_min) {
      formdata.append('params[set_task]', 1);
      formdata.append('params[push_before_min]', data.push_before_min);
    } else {
      formdata.append('params[set_task]', 0);
    }

    if (data.time_path) formdata.append('params[time_path]', data.time_path);
    if (data.returned_type) formdata.append('params[returned_type]', data.returned_type);

    let name = data.name;
    let type = 'L';
    let reward = 0;
    if (data.outdoor) {
      type = 'R';
      name = data.outdoor;
    }
    else if (data.home) {
      type = 'H';
      name = data.home;
      if (rewards[data.home]) reward = rewards[data.home];
    }

    formdata.append('params[reward]', reward);
    formdata.append('params[type]', type);
    formdata.append('params[name]', name);
    formdata.append('params[description]', '');

    api
      .post(`/setuserdeals/`, formdata)
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['user_deals', user.uid, date], result, { isHard: true }));
          dispatch(setItem(['notifications', 'setLesson'], { type: 'success', content: uid ? 'Урок обновлен!' : 'Урок добавлен!' }));
          navigate(`/lessons-schedule/${date}`);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'setLesson'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickRemove = () => {
    const formdata = new FormData();
    formdata.append('params[user_uid]', user?.uid);
    formdata.append('params[deal_uid]', schedule.uid);
    formdata.append('params[delete]', '1');
    formdata.append('params[returned_type]', schedule.returned_type);
    formdata.append('params[date]', schedule.date);

    api
      .post(`/setuserdeals/`, formdata)
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['user_deals', user?.uid, date], result, { isHard: true }));
          dispatch(setItem(['notifications', 'setLesson'], { type: 'success', content: 'Урок удален!' }));
          navigate(`/lessons-schedule/${date}`);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'setLesson'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <MainLayout
      title={uid ? 'Изменить урок в расписании' : 'Добавить в расписание' }
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <InputsContainer>
          {/* <Controller
            name="name"
            control={control}
            rules={{ required: !watch('home') && !watch('outdoor') }}
            render={({ field: { value = '', name, onChange, ...rest } }) => (
              <Select
                id={name}
                name={name}
                label="Урок"
                placeholder="Математика"
                value={value}
                {...rest}
                onChange={e => {
                  onChange(e);
                  setValue('outdoor', '');
                  setValue('home', '');
                }}
                fullWidth
                options={lessonsPlayer?.map(({ name, uid }) => ({ label: name, value: name })) ?? []}
              />
            )}
          /> */}
          {/* <Controller
            name="outdoor"
            control={control}
            rules={{ required: !watch('name') && !watch('home') }}
            render={({ field: { value = '', name, onChange, ...rest } }) => (
              <Select
                id={name}
                name={name}
                label="Внешкольные занятия"
                placeholder="Художка"
                value={value}
                onChange={e => {
                  onChange(e);
                  setValue('name', '');
                  setValue('home', '');
                }}
                fullWidth
                options={mainDeals?.R?.items?.map(({ uid, name }) => ({ label: name, value: name })) ?? []}
                {...rest}
              />
            )}
          /> */}
          <Controller
            name="home"
            control={control}
            rules={{ required: !watch('name') && !watch('outdoor') }}
            render={({ field: { value = '', name, onChange, ...rest } }) => (
              <Select
                id={name}
                name={name}
                label="Домашние дела"
                placeholder="Полить цветы"
                value={value}
                onChange={e => {
                  onChange(e);
                  setValue('outdoor', '');
                  setValue('name', '');
                }}
                fullWidth
                options={
                  mainDeals?.H?.items?.map(({ uid, name, reward }) => ({
                    label: name,
                    value: name,
                    additional: <ScheduleReward value={reward} name={uid} onChange={e => handleToogleReward(name, e.target.checked ? reward : 0)} />
                  })) ?? []
                }
                {...rest}
              />
            )}
          />
          <Controller
            name="start"
            control={control}
            rules={{ required: true }}
            render={({ field: { value = '', name, ...rest } }) => (
              <DateSelect
                id={name}
                name={name}
                label="Начало"
                placeholder="10:00"
                value={value}
                showTimePicker
                fullWidth
                minDate={minDate}
                {...rest}
              />
            )}
          />
          <Controller
            name="end"
            control={control}
            rules={{ required: true }}
            render={({ field: { value = '', name, ...rest } }) => (
              <DateSelect
                id={name}
                name={name}
                label="Конец"
                placeholder="20:00"
                value={value}
                showTimePicker
                fullWidth
                minDate={watch('start')}
                {...rest}
              />
            )}
          />
          <Controller
            name="time_path"
            control={control}
            render={({ field: { value = '', name, ...rest } }) => (
              <Select
                id={name}
                name={name}
                label="Время в пути"
                placeholder="Нет"
                value={value}
                fullWidth
                options={wayTimeOptions}
                {...rest}
              />
            )}
          />
          <Controller
            name="returned_type"
            control={control}
            render={({ field: { value = '', name, ...rest } }) => (
              <Select
                id={name}
                name={name}
                label="Повтор"
                placeholder="Нет"
                value={value}
                fullWidth
                options={dealsPeriod.map(({ uid, name }) => ({ label: name, value: uid }))}
                {...rest}
              />
            )}
          />
          <Controller
            name="push_before_min"
            control={control}
            render={({ field: { value = '', name, ...rest } }) => (
              <Select
                id={name}
                name={name}
                label="Напоминание"
                placeholder="Нет"
                value={value}
                fullWidth
                options={notificationOptions}
                {...rest}
              />
            )}
          />
          <Controller
            name="color"
            control={control}
            render={({ field: { value = '', name, ...rest } }) => (
              <ColorSelect
                id={name}
                name={name}
                label="Цвет"
                placeholder="Синий"
                value={value}
                fullWidth
                options={tasksColors}
                {...rest}
              />
            )}
          />
        </InputsContainer>
        <ButtonsContainer>
          {schedule && (
            <Modal
              viewStyle="bottom"
              control={forwardRef(({ onClick }, ref) => (
                <Button
                  butStyle="secondary"
                  onClick={onClick}
                  fullWidth
                  ref={ref}
                >
                  Удалить
                </Button>
              ))}
              buttons={[
                {
                  text: 'Удалить',
                  style: 'danger',
                  onClick: handleClickRemove
                },
                {
                  action: 'close',
                  text: 'Отмена',
                  style: 'secondary',
                }
              ]}
            >
             <h3 style={{ textAlign: 'center' }}>Вы действительно хотите удалить {schedule.name}?</h3>
            </Modal>
          )}
          <Button
            type="submit"
            butStyle="primary"
            disabled={!isValid}
            fullWidth
          >
            Далее
          </Button>
        </ButtonsContainer>
      </form>
    </MainLayout>
  );
};

export default UpdateLesson;
