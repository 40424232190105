import Gallery from 'components/Gallery';

import styles from './homework.module.css';

const HomeworkComments = ({ name, user_type, is_rejected, is_done, oper_complete, comments }) => {
  if (!(comments.length || is_done)) return null;

  return (
    <div className={styles.messages}>
      {is_rejected ? (
        <div className={styles.messagesTitle}>
          Найдены ошибки
        </div>
      ) : null}
      <div className={styles.messagesList}>
        {comments.map(({ uid, comment, emotion, file }) => (
          <div className={styles.messageItem} key={uid}>
            {file && (
              <div className={styles.messageImages}>
                <div className={styles.messageGallery}>
                  <Gallery items={[ { link: file } ]} />
                </div>
              </div>
            )}
            <div className={styles.messageContent}>
              {comment}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HomeworkComments;
