import { useEffect, useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { format, addHours } from 'date-fns';
import { MainLayout } from 'components/Layouts';
import Input from 'components/fields/Input';
import Select from 'components/fields/Select';
import Select2 from 'components/fields/Select2';
import InputsContainer from 'components/fields/InputsContainer';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';
import DateSelect from 'components/fields/DateSelect';
import ColorSelect from 'components/fields/ColorSelect';
import ScheduleReward from 'components/schedule/ScheduleReward';
import Modal from 'components/Modal';
import ModalSubscribe from 'components/modals/ModalSubscribe';
import { setItem } from 'store/app/actions';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';

import { tasksColors, notificationOptions, wayTimeOptions } from 'data/mock';

const CustomModal = ({ user, onSave, showReward }) => {
  const [ customName, setCustomName ] = useState('');
  const [ customReward, setCustomReward ] = useState('');
  const [ isShowCustom, setShowCustom ] = useState(false);
  const [ isShowSubscribe, setShowSubscribe ] = useState(false);

  const handleClickCustom = () => {
    if (user?.tariff?.actual) setShowCustom(true);
    else setShowSubscribe(true);
  };

  return (
    <>
      <Button butStyle="secondary" fullWidth onClick={handleClickCustom}>Свой вариант</Button>
      <ModalSubscribe open={isShowSubscribe} onClose={() => setShowSubscribe(false)} />
      <Modal
        open={isShowCustom}
        onClose={() => setShowCustom(false)}
        title="Создание дела"
        viewStyle="bottom"
        buttons={[
          {
            text: 'Добавить дело',
            onClick: () => onSave({ name: customName, reward: customReward }),
            action: 'close',
            disabled: customName.length < 3
          }
        ]}
      >
        <InputsContainer>
          <input type="hidden" autoFocus />
          <Input
            name="custom_name"
            label="Введите название"
            value={customName}
            onChange={e => setCustomName(e.target.value)}
            fullWidth
          />
          {showReward && (
            <Input
              name="custom_reward"
              label="Цена в кристаллах (0 - бесплатно)"
              type="number"
              value={customReward}
              onChange={e => setCustomReward(e.target.value)}
              fullWidth
            />
          )}
        </InputsContainer>
      </Modal>
    </>
  );
};

const UpdateLesson = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { date, uid } = useParams();
  const [ searchParams ] = useSearchParams();
  const type = searchParams.get('type');
  const { state } = useLocation();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player ? user.current_player : null;
  const dealsPeriod = useSelector(selectItem(['settings', 'deals_period']));
  const { reset: resetDeals } = useApiQuery({
    name: ['homework_deals', currentPlayer?.uid_gamer],
    path: `/getuserhomeworksviget/${currentPlayer?.uid_gamer}/`,
    skip: !currentPlayer?.uid_gamer
  });

  const [ rewards, setRewards ] = useState({});
  const [ custom, setCustom ] = useState(null);

  const handleToogleReward = (key, reward) => {
    setRewards(prev => {
      const next = { ...prev };
      next[key] = reward;
      return next;
    });
  };

  const { control, handleSubmit, setValue, watch, formState: { isValid } } = useForm({
    mode: 'onChange'
  });

  // const { data: lessonsPlayer } = useApiQuery({
  //   name: ['lessons_player', currentPlayer?.uid_gamer],
  //   path: `/getlesson/${currentPlayer?.uid_gamer}/selected/`,
  //   skip: !currentPlayer
  // });

  const { data: scheduleList } = useApiQuery({
    name: ['user_deals', currentPlayer?.uid_gamer, date],
    path: `/getuserdeals/${currentPlayer?.uid_gamer}/${date}/`,
    skip: !currentPlayer || !date || !uid
  });

  const { data: mainDeals } = useApiQuery({
    name: ['deals_main'],
    path: `/getdealsmain/`,
  });

  const schedule = scheduleList?.find(item => item.uid === Number(uid));

  const minDate = format(new Date(), 'yyyy-MM-dd') + 'T07:00';
  const minDateEnd = format(new Date(), 'yyyy-MM-dd') + 'T08:00';

  useEffect(() => {
    if (state?.date) {
      setValue('start', `${state.date}T07:00`);
      setValue('end', `${state.date}T08:00`);
    }
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (schedule) {
      setValue('start', schedule.date ? `${schedule.date}T${schedule.start}` : minDate);
      setValue('end', schedule.date ? `${schedule.date}T${schedule.end}` : minDateEnd);
      if (schedule.set_task) setValue('notification', schedule.set_task);
      if (schedule.color) setValue('color', schedule.color);
      if (schedule.push_before_min) setValue('push_before_min', schedule.push_before_min);
      if (schedule.returned_type) setValue('returned_type', schedule.returned_type);
      if (schedule.time_path) setValue('time_path', schedule.time_path);
      if (schedule.name) setValue('name', schedule.name);
      if (schedule.name && schedule.reward) setRewards(prev => ({ ...prev, [schedule.name]: schedule.reward }));
    } else {
      setValue('color', tasksColors[type][0].value);
    }
    // eslint-disable-next-line
  }, [schedule]);

  useEffect(() => {
    if (mainDeals && mainDeals[type]) {
      const rewards = mainDeals[type].items?.reduce((acc, { name, reward }) => ({ ...acc, [name]: reward }), {});
      if (schedule?.name && schedule?.reward) rewards[schedule.name] = schedule.reward;
      setRewards(rewards);
    }
  }, [type, mainDeals, schedule]);

  if (date && uid && !schedule) return null;

  const onFormSubmit = (data) => {
    const [ date, time ] = data.start.match('T') ? data.start.split('T') : data.start.split(' ');
    const formdata = new FormData();
    formdata.append('params[user_uid]', currentPlayer?.uid_gamer);
    if (uid) formdata.append('params[deal_uid]', uid);
    if (uid) formdata.append('params[update]', true);
    else formdata.append('params[insert]', true);
    // formdata.append('params[delete]', '0');
    if (data.color) formdata.append('params[color]', data.color);
    formdata.append('params[date]', date);
    formdata.append('params[start]', time);
    formdata.append('params[end]', data.end.match('T') ? data.end.split('T')[1] : data.end.split(' ')[1]);

    if (data.push_before_min) {
      formdata.append('params[set_task]', 1);
      formdata.append('params[push_before_min]', data.push_before_min);
    } else {
      formdata.append('params[set_task]', 0);
    }

    if (data.time_path) formdata.append('params[time_path]', data.time_path);
    if (data.returned_type) formdata.append('params[returned_type]', data.returned_type);

    formdata.append('params[reward]', rewards[data.name] ?? 0);
    formdata.append('params[type]', type);
    formdata.append('params[name]', data.name);
    formdata.append('params[description]', '');

    api
      .post(`/setuserdeals/`, formdata)
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['user_deals', currentPlayer.uid_gamer, date], result, { isHard: true }));
          dispatch(setItem(['notifications', 'setLesson'], { type: 'success', content: uid ? 'Дело обновлено!' : 'Дело добавлено!' }));
          resetDeals();
          navigate(`/lessons-schedule/${date}`);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'setLesson'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickRemove = () => {
    const formdata = new FormData();
    formdata.append('params[user_uid]', currentPlayer?.uid_gamer);
    formdata.append('params[deal_uid]', schedule.uid);
    formdata.append('params[delete]', '1');
    formdata.append('params[returned_type]', schedule.returned_type);
    formdata.append('params[date]', schedule.date);

    api
      .post(`/setuserdeals/`, formdata)
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['user_deals', currentPlayer.uid_gamer, date], result, { isHard: true }));
          dispatch(setItem(['notifications', 'setLesson'], { type: 'success', content: 'Урок удален!' }));
          resetDeals();
          navigate(`/lessons-schedule/${date}`);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'setLesson'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  // const homeOptions = mainDeals?.H?.items?.map(({ uid, name, reward }) => ({
  //     label: name,
  //     value: name,
  //     additionalChecked: !!(rewards[name] && rewards[name] > 0),
  //     additional:
  //       <ScheduleReward
  //         value={reward}
  //         name={uid}
  //         checked={!!(rewards[name] && rewards[name] > 0)}
  //         onChange={e => handleToogleReward(name, e.target.checked ? reward : 0)}
  //       />
  //   })) ?? [];
  // if (custom) homeOptions.push({
  //   label: custom.name,
  //   value: custom.name,
  //   additionalChecked: !!(rewards[custom.name] && rewards[custom.name] > 0),
  //   additional:
  //       <ScheduleReward
  //         value={custom.reward}
  //         name={'custom'}
  //         checked={rewards[custom.name] && rewards[custom.name] > 0}
  //         onChange={e => handleToogleReward(custom.name, e.target.checked ? custom.reward : 0)}
  //       />
  // });
  // if (schedule?.type === 'H' && homeOptions.findIndex(({ value }) => value === schedule.name) === -1)
  //   homeOptions.push({
  //     label: schedule.name,
  //     value: schedule.name,
  //     additionalChecked: !!(rewards[schedule.name] && rewards[schedule.name] > 0),
  //     additional:
  //         <ScheduleReward
  //           value={schedule.reward}
  //           name={'custom_1'}
  //           checked={rewards[schedule.name] && rewards[schedule.name] > 0}
  //           onChange={e => handleToogleReward(schedule.name, e.target.checked ? schedule.reward : 0)}
  //         />
  //   });

  const getOption = (uid, name, reward) => reward ? (
      {
        label: name,
        displayLabel: <span style={{ display: 'flex', gap: '10px', justifyContent: 'space-between'}}>{name} {rewards[name] && <span style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>{rewards[name]} <img src="/images/crystal.svg" alt="" /></span>}</span>,
        value: name,
        additionalChecked: !!(rewards[name] && rewards[name] > 0),
        additional:
          <ScheduleReward
            value={reward}
            name={uid}
            checked={rewards[name] && !!(rewards[name] > 0)}
            onChange={e => handleToogleReward(name, e.target.checked ? reward : 0)}
          />
      }
    ) : (
      {
        label: <>{name}</>,
        displayLabel: <span style={{ display: 'flex', gap: '10px', justifyContent: 'space-between'}}>{name} {rewards[name] && <span style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>{rewards[name]} <img src="/images/crystal.svg" alt="" /></span>}</span>,
        value: name
      }
    );

  const options = (mainDeals && mainDeals[type]) ? mainDeals[type].items?.map(({ uid, name, reward }) => getOption(uid, name, reward)) : [];
  if (custom) options.push(getOption('custom', custom.name, custom.reward));
  if (schedule && options.findIndex(({ value }) => value === schedule.name) === -1) options.push(getOption(uid, schedule.name, schedule.reward));

  return (
    <MainLayout
      title={uid ? 'Изменить дело в расписании' : 'Добавить в расписание' }
    >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <InputsContainer>
          {/* <Controller
            name="name"
            control={control}
            rules={{ required: !watch('home') && !watch('outdoor') }}
            render={({ field: { value = '', name, onChange, ...rest } }) => (
              <Select
                id={name}
                name={name}
                label="Урок"
                placeholder="Математика"
                value={value}
                {...rest}
                onChange={e => {
                  onChange(e);
                  setValue('outdoor', '');
                  setValue('home', '');
                }}
                fullWidth
                options={lessonsPlayer?.map(({ name, uid }) => ({ label: name, value: name })) ?? []}
              />
            )}
          /> */}
          <Controller
            name="name"
            control={control}
            render={({ field: { value = '', name, onChange, ...rest } }) => (
              <Select
                id={name}
                name={name}
                label={type === 'R' ? 'Внешкольные занятия' : (type === 'H' ? 'Домашние дела' : 'Важные дела')}
                placeholder={type === 'R' ? 'Художка' : (type === 'H' ? 'Полить цветы' : 'Сделать зарядку')}
                value={value}
                onChange={onChange}
                fullWidth
                options={options}
                customButton={
                  <div style={{ marginTop: '12px' }}>
                    <CustomModal user={user} onSave={setCustom} showReward={type === 'H'} />
                  </div>
                }
                {...rest}
              />
            )}
          />
          <Controller
            name="start"
            control={control}
            rules={{ required: true }}
            render={({ field: { value = '', name, onChange, ...rest } }) => (
              <DateSelect
                id={name}
                name={name}
                label="Начало"
                placeholder="10:00"
                value={value}
                onChange={e => {
                  onChange(e);
                  const next = format(addHours(new Date(e.target.value.replace(' ', 'T')), 1), 'yyyy-MM-dd HH:mm');
                  setValue('end', next);
                }}
                showTimePicker
                fullWidth
                minDate={minDate}
                {...rest}
              />
            )}
          />
          <Controller
            name="end"
            control={control}
            rules={{ required: true }}
            render={({ field: { value = '', name, ...rest } }) => (
              <DateSelect
                id={name}
                name={name}
                label="Конец"
                placeholder="20:00"
                value={value}
                showTimePicker
                fullWidth
                minDate={watch('start') ?? minDate}
                {...rest}
              />
            )}
          />
          {(type === 'R' || type === 'Y') && (
            <Controller
              name="time_path"
              control={control}
              render={({ field: { value = wayTimeOptions[0].value, name, ...rest } }) => (
                <Select2
                  id={name}
                  name={name}
                  label="Время в пути"
                  placeholder="Нет"
                  value={value}
                  fullWidth
                  options={wayTimeOptions}
                  {...rest}
                />
              )}
            />
          )}
          <Controller
            name="returned_type"
            control={control}
            render={({ field: { value = 0, name, ...rest } }) => (
              <Select2
                id={name}
                name={name}
                label="Повтор"
                placeholder="Нет"
                value={value}
                fullWidth
                options={dealsPeriod?.map(({ uid, name }) => ({ label: name, value: uid })) ?? []}
                {...rest}
              />
            )}
          />
          <Controller
            name="push_before_min"
            control={control}
            render={({ field: { value = notificationOptions[0].value, name, ...rest } }) => (
              <Select2
                id={name}
                name={name}
                label="Напоминание"
                placeholder="Нет"
                value={value}
                fullWidth
                options={notificationOptions}
                {...rest}
              />
            )}
          />
          <Controller
            name="color"
            control={control}
            render={({ field: { value = tasksColors[type][0].value, name, ...rest } }) => (
              <ColorSelect
                id={name}
                name={name}
                label="Цвет"
                placeholder="Синий"
                value={value}
                fullWidth
                options={tasksColors[type]}
                {...rest}
              />
            )}
          />
        </InputsContainer>
        <ButtonsContainer>
          {schedule && (
            <Modal
              viewStyle="bottom"
              control={forwardRef(({ onClick }, ref) => (
                <Button
                  butStyle="secondary"
                  onClick={onClick}
                  fullWidth
                  ref={ref}
                >
                  Удалить
                </Button>
              ))}
              buttons={[
                {
                  text: 'Удалить',
                  style: 'danger',
                  onClick: handleClickRemove
                },
                {
                  action: 'close',
                  text: 'Отмена',
                  style: 'secondary',
                }
              ]}
            >
             <h3 style={{ textAlign: 'center' }}>Вы действительно хотите удалить {schedule.name}?</h3>
            </Modal>
          )}
          <Button
            type="submit"
            butStyle="primary"
            disabled={!isValid}
            fullWidth
          >
            Далее
          </Button>
        </ButtonsContainer>
      </form>
    </MainLayout>
  );
};

export default UpdateLesson;
