import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { MainLayout } from 'components/Layouts';
import Portfail from 'components/portfail/Portfail';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';

const PortfailPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const [ checkedItems, setCheckedItems ] = useState([]);

  const { data: portfail, reset: resetPortfail } = useApiQuery({
    name: ['portfail', user?.uid],
    path: `/getuserportfail/${user?.uid}/`,
    skip: !user
  });

  const portfailItems = portfail ? Object.values(portfail).reduce((acc, { units }) => (units ? [ ...acc, ...units.filter(({ checked }) => !!checked)] : acc), []) : [];

  const handleCheckItem = (id, checked) => {
    if (checked) setCheckedItems(prev => [ ...prev, id ]);
    else setCheckedItems(prev => prev.filter(item => item !== id));
  };

  const handleClickSubmit = () => {
    const date = format(new Date(), 'yyyy-MM-dd');
    const formdata = new FormData();
    formdata.append('params[user_uid]', user.uid);
    formdata.append('params[date]', date);
    api
      .post(`/checkuserportfail/`, formdata)
      .then(response => {
        const result = response.data.response;

        if (result.message) {
          resetPortfail();
          dispatch(setItem(['user', 'portfail_checked'], result.is_complete ));
          dispatch(setItem(['notifications', 'checkPortfail'], { type: result.is_complete ? 'success' : 'error', content: result.message }));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'checkPortfail'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  if (!user) return null;

  return (
    <MainLayout title="Портфель">
      <Portfail items={portfailItems} isDone={user.portfail_checked} readOnly={user.portfail_checked} onChangeItem={handleCheckItem} />
      <ButtonsContainer>
        <Button
          butStyle="secondary"
          disabled={user.portfail_checked || checkedItems.length !== portfailItems.length}
          onClick={handleClickSubmit}
        >
          Я собрал портфель!
          <span style={{ display: 'flex', alignItems: 'center', gap: '6px', color: '#FF9B7B' }}>+ 10 <img src="/images/crystal.svg" width={16} height={20} alt="" /></span>
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default PortfailPage;
