import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Shop from 'components/Shop';
import CrystalsBalance from 'components/CrystalsBalance';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';

const Store = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;

  const { data: shop } = useApiQuery({
    name: ['shop', currentPlayer.uid_gamer],
    path: `/getshop/${currentPlayer.uid_gamer}/`,
    skip: !currentPlayer || !currentPlayer.uid_gamer
  });

  return (
    <MainLayout title="Магазин" rightButton={<div style={{ marginLeft: 'auto' }}><CrystalsBalance crystals={shop?.crystalls} /></div>}>
      <Shop items={shop?.shop} crystalls={shop?.crystalls} />
    </MainLayout>
  );
};

export default Store;
