import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import Button from 'components/Button';
import copyToClipboard from 'helpers/copyToClipboard';
import getCurrentHost from 'helpers/getCurrentHost';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';

import styles from './share-block.module.css';

const ShareBlock = () => {
  const dispatch = useDispatch();
  const user_agent = useSelector(selectItem(['settings', 'user_agent']));

  const handleClickShare = () => {
    const result = copyToClipboard(`${getCurrentHost()}`);

    if (result)
      dispatch(setItem(['notifications', 'copy_link'], { type: 'success', content: 'Ссылка скопирована' }));
    else
      dispatch(setItem(['notifications', 'copy_link'], { type: 'error', content: 'Ошибка копирования' }));
  }

  return (
    <Button
      className={styles.root}
      onClick={handleClickShare}
      href={user_agent === 'timer-mobile' ? '#share-link' : undefined}
    >
      <div className={styles.content}>
        <div className={styles.title}>Поделиться с друзьями</div>
        <div className={styles.description}>Отправить ссылку на приложение</div>
      </div>
      <ReactSVG src="/icons/share.svg" className={styles.icon} />
    </Button>
  );
};

export default ShareBlock;
