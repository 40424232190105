import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Gallery from 'components/Gallery';
import HomeworkComments from './HomeworkComments';

import styles from './homework.module.css';

const MentorButtons = ({ current, onSendComment, onClickReject, onClickSubmit, bonus, onChangeFile }) => {
  const [ commentText, setCommentText ] = useState('');
  const [ isCommentOpen, setCommentOpen ] = useState(false);

  if (!current) return null;

  const { uid, files, is_done, is_rejected, status, comment, lesson } = current;

  const handleClickReject = (uid) => {
    setCommentOpen(true);
    onClickReject(uid);
  };

  const handleClickSubmit = (uid, withBonus) => {
    setCommentOpen(true);
    onClickSubmit(uid, withBonus);
  };

  return (
    <>
      <HomeworkComments {...current} user_type="M" />

      {(is_rejected && comment) &&
        <div className={styles.comment}>{comment}</div>
      }

      {files?.length ? (
        <div className={styles.gallery}>
          <Gallery
            items={files.map(({ file_url }) => ({ link: file_url }))}
            changeItems={true}
            onChangeFile={(file, old_file) => onChangeFile(file, uid, lesson, old_file)}
          />
        </div>
      ) : null}

      {(!is_done && status.status !== 'W') &&
        <ButtonsContainer>
          <>
            {!is_rejected && (
              <Button
                butStyle="secondary"
                onClick={() => handleClickReject(uid)}
                disabled={!files?.length}
                fullWidth
              >
                Переделать
              </Button>
            )}
            <Button
              butStyle="primary"
              onClick={() => handleClickSubmit(uid)}
              disabled={!files?.length}
              fullWidth
            >
              Выполнено
            </Button>
            <Button
              butStyle="primary"
              onClick={() => handleClickSubmit(uid, true)}
              disabled={!files?.length}
              className={styles.accurButton}
              fullWidth
            >
              Выполнено аккуратно <span className="nowrap">+{bonus} <img src="/images/crystal.svg" alt="" /></span>
            </Button>
          </>
        </ButtonsContainer>
      }
      <Modal
        title="Оставьте комменарий"
        open={isCommentOpen}
        viewStyle="bottom"
        closeOnDocumentClick={false}
        onClose={() => setCommentOpen(false)}
        buttons={[
          {
            className: styles.backButton,
            butStyle: "tertiary",
            icon: <ReactSVG src="/icons/arrow-left.svg" wrapper="span" />,
            action: 'close'
          },
          {
            text: 'Отправить комментарий',
            action: 'close',
            onClick: () => onSendComment(uid, commentText),
            style: 'primary'
          }
        ]}
      >
        <textarea className={styles.textarea} placeholder="Введите текст" onChange={e => setCommentText(e.target.value)} />
      </Modal>
    </>
  );
};

export default MentorButtons;
