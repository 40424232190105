import { useState, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import * as api from 'helpers/api';
import { MainLayout } from 'components/Layouts';
import Form from 'components/Form';
import Input from 'components/fields/Input';
import Select from 'components/fields/Select';
import Button from 'components/Button';
import Checkbox from 'components/fields/Checkbox';
import Modal from 'components/Modal';
// import Block from 'components/Block';
// import HeaderMentor from 'components/header/HeaderMentor';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';

import styles from './mentor.module.css';

import { grades, letters } from 'data/mock';

const CreatePlayer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [ userLocation, setUserLocation ] = useState(null);
  const [ isSubmitActive, setSubmitActive ] = useState(false);

  const user = useSelector(selectItem(['user']));

  let locationOpts = [];
  if (user.location) {
    locationOpts.push({
      label: user.location,
      value: `${user.gmt}_${user.location}`
    });
  }

  const minDateBirth = new Date();
  minDateBirth.setFullYear(minDateBirth.getFullYear() - 12);

  const maxDateBirth = new Date();
  maxDateBirth.setFullYear(maxDateBirth.getFullYear() - 6);

  let inputs = [
    { id: 'name', name: 'name', component: Input,
      props: { label: 'Никнейм', placeholder: 'Светик', fullWidth: true }
    },
    { id: 'location', name: 'location', component: Select, validation: 'select',
      props: {
        modalTitle: 'Город проживания', label: 'Ваш город', searchPlaceholder: 'Москва',
        options: locationOpts, showDropdownButton: false,
        placeholder: 'Москва', fullWidth: true, isSearchable: true,
        loadUrl: '/getcity', mapper: (data) => data.map(({ gmt, name }) => ({ label: name, value: `${gmt}_${name}` }))
      }
    },
    { id: 'school', name: 'school', component: Select, validation: 'select',
      props: {
        label: 'Номер школы', placeholder: 'Выберите школу', fullWidth: true, isSearchable: true,
        value: '', options: [], showDropdownButton: false,
        loadUrl: `/getschool/${userLocation}`, mapper: (data) => data.map(({ name, uid }) => ({ label: name, value: uid })),
        customParams: {
          user_uid: user.uid,
          city: userLocation,
          buttonUrl: '/newschool/',
          buttonText: 'Школы нет в списке',
          inputText: 'Введите название школы',
        }
      }
    },
    { id: 'class', name: 'class', component: Select, validation: 'select',
      props: {
        modalTitle: 'Класс', label: 'Класс', options: grades, showDropdownButton: false,
        placeholder: '8', showArrow: false
      }
    },
    { id: 'class_sign', name: 'class_sign', component: Select, validation: 'select',
      props: { placeholder: 'А', options: letters, value: '', showDropdownButton: false, }
    },
    { id: 'ac_domashka', name: 'ac_domashka', component: Checkbox,
      props: {
        label: (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', lineHeight: 1.2 }}>
            <span>Автоматически подтверждать <br /><b>Домашку</b></span>
            <Modal
              viewStyle="bottom"
              showClose
              control={forwardRef(({ onClick }, ref) => <ReactSVG src="/icons/question-2.svg" wrapper="span" className={styles.iconInfo} onClick={(e) => { e.preventDefault(); onClick(e) }} ref={ref} />)}
            >
              <b>Выбрав эту опцию</b>, домашнее задание будет подтверждено автоматически, и ребёнок <b>сразу получит кристаллы</b> после того, как отправит фото выполненной домашки в MeTimer. Если же вы <b>не поставите</b> галочку, то после того, как ребёнок выполнит домашнее задание и отправит его в MeTimer, <b>вам нужно будет подтвердить</b> выполнение, чтобы школьник <b>получил кристаллы</b>.
            </Modal>
          </div>
        ),
        checked: false,
        value: false
      }
    },
    { id: 'ac_homework', name: 'ac_homework', component: Checkbox,
      props: {
        label: (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', lineHeight: 1.2 }}>
            <span>Автоматически подтверждать <br /><b>Домашние дела</b></span>
            <Modal
              viewStyle="bottom"
              showClose
              control={forwardRef(({ onClick }, ref) => <ReactSVG src="/icons/question-2.svg" wrapper="span" className={styles.iconInfo} onClick={(e) => { e.preventDefault(); onClick(e) }} ref={ref} />)}
            >
              <b>Выбрав эту опцию</b>, Домашнее дело будет подтверждено автоматически, и ребёнок <b>сразу получит кристаллы</b> после того, как нажмет на кнопку “Выполнил” в разделе Домашних дел. Если же вы <b>не поставите</b> галочку, то после того, как ребёнок выполнит Домашнее дело и нажмет на кнопку “Выполнил”, <b>вам нужно будет подтвердить</b> выполнение, чтобы школьник <b>получил кристаллы</b>.
            </Modal>
          </div>
        ),
        checked: false,
        value: false
      }
    },
  ];

  // const inputs = [
    // { id: 'name', name: 'name', component: Input, validation: 'string',
    //   props: { label: 'Никнейм', placeholder: 'Светик', fullWidth: true }
    // },
    // { id: 'class', name: 'class', component: Switch, validation: 'select',
    //   props: { id: 'class', label: 'Выберите в каком классе учится Школьник', options: grades, columns: 4 }
    // }
  // ];

  const onFormChange = (data) => {
    if (data.location) setUserLocation(data.location.split('_')[1]);
  };

  const onFormSubmit = (data) => {
    api
      .get(`/createMentoringForChild/new_player_create/9999/`)
      .then(response => {
        const result = response.data.response;
        if (result.success && result.player) {
          saveData(result.player.uid, data);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const saveData = (uid, data) => {
    if (!uid) return;

    const fd = new FormData();
    for (let key in data) {
      fd.append(`params[${key}]`, data[key]);
    }

    const [ gmt, location ] = data.location.split('_');

    fd.append('params[gmt]', gmt);
    fd.append('params[location]', location);

    api
      .post(`/updateUserProfile/${uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          let playerKey = result.user.player.findIndex(({ uid_gamer }) => (String(uid_gamer) === String(uid)));
          dispatch(setItem(['user'], result.user));
          dispatch(setItem(['user', 'current_player'], result.user.player[playerKey]));
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'success', content: 'Ребенок добавлен!' }));
          navigate('/player');
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  return (
    <MainLayout title="Добавить ребёнка">
      {/* <HeaderMentor user={user} /> */}
      {/* <Block title="Карточка ребёнка"> */}
        <div className={styles.content}>
          <Form
            inputs={inputs}
            onSubmit={onFormSubmit}
            onChange={onFormChange}
            onValidChange={setSubmitActive}
            buttons={
              <Button
                type="submit"
                fullWidth={true}
                butStyle="primary"
                disabled={!isSubmitActive}
              >
                Добавить ребенка
              </Button>
            }
          />
        </div>
      {/* </Block> */}
    </MainLayout>
  );
}

export default CreatePlayer;
