import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { MainLayout } from 'components/Layouts';
import Homework from 'components/homework/Homework';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import * as api from 'helpers/api';

import styles from './Homework.module.css';

const HomeworkPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { date, task_uid } = useParams();
  const { hash } = useLocation();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  // const info = currentPlayer?.info;
  const settings = useSelector(selectItem(['settings']));
  const max_file_size = settings?.max_file_size;

  const { data: homeworkTasks, reset: resetTasks } = useApiQuery({
    name: ['homework_tasks', currentPlayer.uid_gamer],
    path: `/gettasksofuser/${currentPlayer.uid_gamer}/`,
    skip: !user || !currentPlayer || !date,
    update_param: hash
  });
  const task = homeworkTasks?.tasks[date].tasks.find(({ uid }) => String(uid) === String(task_uid));

  useEffect(() => {
    if (homeworkTasks && !task) navigate('/');
    // if (homeworkTasks && !homeworkTasks[task_uid]) navigate(`/homework/${date}`);
    // eslint-disable-next-line
  }, [ date, task_uid, homeworkTasks ]);

  const sendRequest = (task_uid, status, comment, status2) => {
    const fd = new FormData();
    fd.append('params[task_uid]', task_uid);
    fd.append('params[user_uid]', currentPlayer.uid_gamer);
    if (comment) fd.append('params[comment]', comment);
    if (status) fd.append(`params[${status}]`, true);
    if (status2) fd.append(`params[${status2}]`, true);

    api
      .post(`/setuserdomashkatasks/`, fd)
      .then(response => {
        const result = response.data.response;

        if (result) {
          // dispatch(pushItem(['homework_tasks', currentPlayer.uid_gamer], result, { isHard: true }));
          resetTasks();

          let notification = '';
          if (status === 'is_rejected') notification = 'Домашка отклонена';
          else if (status === 'is_done') notification = 'Домашка выполнена';
          else if (status === 'comment') notification = 'Комментарий сохранен';
          else notification = 'Сохранено';

          dispatch(setItem(['notifications', 'updateTask'], { type: 'success', content: notification }));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'updateTask'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  const handleClickReject = (task_uid) => {
    sendRequest(task_uid, 'is_rejected');
  };

  const handleClickSubmit = (task_uid, withBonus) => {
    // if (info?.tariff && !info?.tariff?.actual && settings?.pay_mobile_visible) {
    //   navigate(`/tariffs?from=homework&player=${currentPlayer.uid_gamer}`);
    // } else {
      sendRequest(task_uid, 'is_done', null, withBonus && 'is_acc');
    // }
  };

  const handleChangeFile = (file, task_uid, task_type, old_file) => {
    if (!file) return null;
    const isVideo = !!(file.type.match(/video*/));
    if (file.size > max_file_size) {
      dispatch(setItem(['notifications', 'upload'], { type: 'error', title: 'Ошибка', content: 'Макс. размер файла 20Мб' }));
      return null;
    }

    const fd = new FormData();
    fd.append('file', file, file.name);
    fd.append('user_uid', user.uid);
    fd.append('is_video', isVideo ? 1 : 0);
    fd.append('task_type', task_type);
    fd.append('task_uid', task_uid);
    if (old_file) fd.append('old_file', old_file);

    dispatch(setItem(['show_loader'], true));

    api
      .post(`/uploaddomashkafiles/`, fd, { mimeType: 'multipart/form-data', contentType: false })
      .then(response => {
        const result = response.data.response;

        if (result && result.file) {
          dispatch(setItem(['notifications', 'upload'], { type: 'success', content: 'Файл успешно загружен' }));
          resetTasks();
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка загрузки файла';
          dispatch(setItem(['notifications', 'upload'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      })
      .then(() => {
        dispatch(setItem(['show_loader'], false));
      });
  };

  const handleSendComment = (task_uid, text) => {
    sendRequest(task_uid, null, text);
  };

  return (
    <MainLayout title={<div>{task?.lesson_name} <div className={styles.date}>{format(new Date(date), 'dd.MM.yyyy')}</div></div>}>
      <Homework
        user={user}
        item={task}
        onClickReject={handleClickReject}
        onClickSubmit={handleClickSubmit}
        onSendComment={handleSendComment}
        onChangeFile={handleChangeFile}
        settings={settings}
      />
    </MainLayout>
  );
};

export default HomeworkPage;