import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Block from 'components/Block';
import RewardsUpdate from 'components/RewardsUpdate';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';
import { selectItem } from 'store/app/getters';
import { setItem, deleteItem, pushItem } from 'store/app/actions';

import styles from './Rewards.module.css';

const UpdateRewards = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const playerUid = currentPlayer?.uid_gamer;

  const { data: rewards } = useApiQuery({
    name: ['rewards', playerUid],
    path: `/getMentorRewards/${playerUid}/`
  });

  const handleChangeReward = (uid, name, checked, reward_uid) => {
    const fd = new FormData();

    fd.append('update[reward_uid]', uid);
    fd.append('update[reward_name]', name);
    fd.append('update[mentor_uid]', user.uid);
    fd.append('update[player_uid]', playerUid);

    if (!checked) fd.append('update[delete_reward]', reward_uid);

    api
      .post(`/updateMentorRewards/`, fd)
      .then(response => {
        const result = response.data.response;

        if (result) {
          dispatch(setItem(['rewards', playerUid], result, { isHard: true }));
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'success', content: 'Сохранено' }));
          dispatch(deleteItem(['rewards_mentor'], playerUid));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickInfo = () => {
    dispatch(setItem(['common', 'faq'], 'rewards_m_f_p'));
  };

  const handleClickNext = () => {
    dispatch(pushItem(['onboarding'], {
      key: 'store',
      image: <img src="/images/onboarding/store.svg" alt="" />,
      style: {
        top: '80px',
        right: '0px',
        left: 'auto',
        bottom: 'auto'
      },
    }));
  };

  return (
    <MainLayout
      title="Награды"
      description={
        <span>
          Награда - это ваша совметсная деятельность с ребеноком. <br />
          Выберите минимум <span className="accent-text">4 вида</span> наград для каждого временного периода. <br /><br />
          Награды будут выпадать ребенку за его достижения при выполнении квестов
        </span>
      }
    >
      <Button
        className={styles.buttonInfo}
        butStyle="tertiary"
        onClick={handleClickInfo}
        size="medium"
        fullWidth
      >
        Зачем это нужно
      </Button>
      <Block>
        <RewardsUpdate items={rewards} onChange={handleChangeReward} />
      </Block>

      {state?.onboarding && (
        <div className={styles.onboardButtons}>
          <ButtonsContainer className="container">
            <Button
              butStyle="secondary"
              to="/homework-update"
              // state={{ onboarding: true }}
            >
              Назад
            </Button>
            <Button
              butStyle="primary"
              to="/store-update"
              // state={{ onboarding: true }}
              onClick={handleClickNext}
              disabled={
                !rewards || Object.values(rewards).filter(items => (
                  Object.values(items).filter(({ rewards }) => (
                    rewards && Object.values(rewards).find(({ is_checked }) => !!is_checked)
                  )).length >= 4
                )).length < 4
              }
            >
              Далее
            </Button>
          </ButtonsContainer>
        </div>
      )}
    </MainLayout>
  );
};

export default UpdateRewards;
