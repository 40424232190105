import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import Button from 'components/Button';

import styles from './back-button.module.css';

const BackButton = ({ returnUrl }) => {
  let navigate = useNavigate();

  return (
    <Button
      onClick={() => (!returnUrl && window.history.length) ? navigate(-1) : returnUrl}
      butStyle="tertiary"
      className={styles.button}
      icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
      size="medium"
    />
  );
};

export default BackButton;
