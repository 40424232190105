import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { RegisterLayout } from 'components/Layouts';
import Form from 'components/Form';
import PhoneCode from 'components/fields/PhoneCode';
import Button from 'components/Button';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import standartPhone from 'helpers/standartPhone';
import parseSearchParams from 'helpers/parseSearchParams';
// import ruPlural from 'helpers/ruPlural';
import * as api from 'helpers/api';

import styles from './account.module.css';

const Register = () => {
  const { search, state } = useLocation();
  const { phone } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectItem(['user']));
  const verification = useSelector(selectItem(['verification']));
  // const settings = useSelector(selectItem(['settings']));
  // const visibleSettings = useSelector(selectItem(['visible_settings']));
  // const mobile_visible_payment = useSelector(selectItem(['settings', 'mobile_visible_payment']));

  useEffect(() => {
    if (!search) return null;
    let params = parseSearchParams(search);

    if (!params) return null;
    if (params.key) {
      api.get(`/api/keypost/${params.key.value}/`);
      delete params.key;
    }
    if (Object.keys(params).length) {
      dispatch(setItem(['tokens'], params));
    }

    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (!search || !verification.checkingPhone || !verification.isCodeSended) return null;
    const params = parseSearchParams(search);

    if (!params || !params.key) return null;
    if (verification.isCodeValid)
      api.get(`/api/keypost/${params.key.value}/${verification.checkingPhone.replace(/\s+/g, '')}/${verification.code}/`);
    else if (!verification.code)
      api.get(`/api/keypost/${params.key.value}/${verification.checkingPhone.replace(/\s+/g, '')}/`);
    // eslint-disable-next-line
  }, [verification]);

  const onPhoneSubmit = (result) => {
    dispatch(setItem(['user'], result.user));
  };

  const inputs = [
    { id: 'phone', name: 'phone', component: PhoneCode, validation: 'phone',
      props: {
        label: state?.class ? <>Введите <span className="accent-text">ваш</span> номер телефона</> : <>Bведите номер телефона <span className="accent-text">взрослого</span></>,
        placeholder: '+7',
        fullWidth: true,
        mask: '+7 ### ### ## ##',
        type: 'tel',
        value: standartPhone(phone),
        onPhoneSubmit: onPhoneSubmit,
        path: '/auth'
      }
    }
  ];

  useEffect(() => {
    if (user && user.uid) {
      if (!user.name) navigate('/create');
      else navigate('/');
    }

    // eslint-disable-next-line
  }, [user]);

  const onFormSubmit = (data) => {
    if (!verification.isCodeSended) dispatch(setItem(['verification', 'sendCode'], true));
    if (!verification.isCodeValid) return null;
  };
  return (
    <RegisterLayout showLogo>
      <h1 className={styles.title}>Регистрация</h1>
      <div className={styles.subtitle}>
        {verification.isCodeSended && (
          <>
            <div>На следующем экране разрешите уведомления. Это необходимо для полноценной работы MeTimer и формирования самостоятельности вашего ребёнка</div>
            <div style={{ marginTop: '20px' }}><img src="/images/notifications.png" width="100%" alt="" /></div>
          </>
        )}
        {/* {verification.isCodeSended && (
          <>
            Проверьте код из смс, он придет на номер
            <div><b>{verification.checkingPhone}</b></div>
          </>
        )} */}
      </div>
      {/* <div className={styles.subtitle}>
        <span className="accent-text">Попробуйте - это бесплатно!</span>
      </div> */}
      {/* {(mobile_visible_payment && visibleSettings?.reg_trial_text) && (
        <div className={styles.subtitle}>
          <span className="accent-text">
            {settings?.trial_period > 100 ?
              'Попробуйте бесплатно' : `Попробуйте ${settings.trial_period} ${ruPlural(settings.trial_period, ['день', 'дня', 'дней'])} бесплатно`
            }
          </span>
        </div>
      )} */}
      <div className={styles.content}>
        <div className={styles.regLogin}>
          <Form
            inputs={inputs}
            onSubmit={onFormSubmit}
            buttons={
              !verification.isCodeSended && (
                <>
                  <Button
                    type="submit"
                    butStyle="primary"
                    fullWidth
                  >
                    Получить код
                  </Button>

                  <div className={styles.terms}>
                    Нажимая кнопку ”<span className="accent-text">Получить код</span>” <br /> вы соглашаетесь
                    с <a href="https://me-timer.ru/docs/license.pdf">политикой обработки персональных данных</a> и
                    принимаете <a href="https://me-timer.ru/docs/oferta.pdf">условия оферты сервиса</a>
                  </div>
                </>
              )
            }
          />
        </div>
        {verification.isCodeSended && (
          <div className="form-buttons">
            <Button
              type="reset"
              butStyle="tertiary"
              icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
              onClick={() => dispatch(setItem(['verification', 'isCodeSended'], false))}
              className={styles.buttonBack}
            >
            </Button>
          </div>
        )}
      </div>
    </RegisterLayout>
  );
}

export default Register;
