import { useSelector, useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { MainLayout } from 'components/Layouts';
import HeaderPlayer from 'components/header/HeaderPlayer';
import Block from 'components/Block';
import Button from 'components/Button';
// import TariffNotification from 'components/tariffs/TariffNotification';
import SidebarPlayer from 'components/header/HeaderPlayer/SidebarPlayer';
import HomeworkHome from 'components/homepage/HomeworkHome';
import ScheduleHome from 'components/homepage/ScheduleHome';
import PortfailHome from 'components/homepage/PortfailHome';
import ShareBlock from 'components/homepage/ShareBlock';
import HomeworkDeals from 'components/homepage/HomeworkDeals';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';

import styles from './player.module.css';

const UserQuery = ({ uid }) => {
  useApiQuery({
    name: ['homework', uid],
    path: `/getuserdomashka/${uid}/`
  });

  useApiQuery({
    name: ['homework_tasks', uid],
    path: `/gettasksofuser/${uid}/`
  });

  useApiQuery({
    name: ['lesson_schedule', uid],
    path: `/getlessonshedule/${uid}/`
  });

  useApiQuery({
    name: ['portfail', uid],
    path: `/getuserportfail/${uid}/`
  });

  return null;
};

const Home = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const settings = useSelector(selectItem(['settings']));
  const schedule = useSelector(selectItem(['lesson_schedule', user?.uid]));
  const homework = useSelector(selectItem(['homework', user?.uid]));
  const homeworkTasks = useSelector(selectItem(['homework_tasks', user?.uid]));
  const portfail = useSelector(selectItem(['portfail', user?.uid]));
  const { data: homeworkDeals, reset: resetDeals } = useApiQuery({
    name: ['homework_deals', user?.uid],
    path: `/getuserhomeworksviget/${user?.uid}/`,
    skip: !user
  });

  const openSidebar = () => {
    dispatch(setItem(['common', 'side_menu', 'open'], true));
  };

  const handleCompleteDeal = (uid, returned) => {
    const formData = new FormData();
    formData.append('params[deal_uid]', uid);
    formData.append('params[user_uid]', user?.uid);
    formData.append('params[returned_type]', returned);
    formData.append('params[is_complete]', true);

    api.post('/getuserhomeworksviget/', formData).then(() => resetDeals());
  };

  return (
    <MainLayout>
      {(user && user.uid) && <UserQuery uid={user.uid} />}
      <HeaderPlayer user={user} />

      {(window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost') && (
        <Button
          id="sign_as_player"
          butStyle="green"
          size="medium"
          className={styles.signIn}
          onClick={openSidebar}
          fullWidth
        >
          Войти как взрослый
        </Button>
      )}

      <Block
        id="schedule"
        title="Расписание"
        rightButton={
          (user?.type === 'S' && schedule && Object.values(schedule).findIndex(({ lessons }) => !!lessons.length) >= 0) && (
            <Button
              butStyle="text"
              to="/player-lessons-update"
              icon={<ReactSVG src="/icons/settings.svg" wrapper="span" />}
            />
          )
        }
      >
        <ScheduleHome
          user={user}
          schedule={schedule}
        />
      </Block>

      <Block
        id="homework"
        title="Школьная Домашка"
        rightButton={
          (user?.type === 'S' && homework && Object.values(homework).findIndex(({ shedule }) => !!(shedule && shedule.uid)) >= 0) && (
            <Button
              butStyle="text"
              to="/homework"
              icon={<ReactSVG src="/icons/settings.svg" wrapper="span" />}
            />
          )
        }
      >
        <HomeworkHome
          user={user}
          schedule={homework}
          isset_long={homeworkTasks?.isset_long}
          tasks={homeworkTasks?.tasks}
          settings={settings}
        />
      </Block>


      {/* {settings?.user_agent !== 'timer-mobile' && ( */}
        <Block
          id="briefcase"
          title="Портфель"
          rightButton={
            (user?.type === 'S' && portfail && Object.values(portfail).findIndex(({ units }) => (units && units.findIndex(({ checked }) => !!checked) !== -1)) !== -1) && (
              <Button
                butStyle="text"
                to="/briefcase-update"
                icon={<ReactSVG src="/icons/settings.svg" wrapper="span" />}
              />
            )
          }
        >
          <PortfailHome
            user={user}
            items={portfail}
          />
        </Block>
      {/* )} */}


      <Block title="Дела на сегодня">
        <HomeworkDeals user={user} items={homeworkDeals} onComplete={handleCompleteDeal} />
      </Block>

      <ShareBlock />

      <SidebarPlayer user={user} mentors={user.mentor} />
    </MainLayout>
  );
}

export default Home;
