import classNames from 'classnames';
import { format } from 'date-fns';
// import ruPlural from 'helpers/ruPlural';
import getDuration from 'helpers/getDuration';
import MentorButtons from './MentorButtons';
import PlayerButtons from './PlayerButtons';

import styles from './homework.module.css';

const Homework = ({ user, item, onClickReject, onClickSubmit, onSendComment, onChangeFile, settings }) => {
  if (!item) return (
    <div className={classNames(styles.block, 'skeleton-loader')} />
  );

  const { end, is_done } = item;

  let rows = [
    {
      label: 'ДЗ выполнено вовремя',
      value: `<span>${settings?.domashka_std_crystall} <img src="/images/crystal.svg" alt="" /></span>`,
      status: 'danger'
    },
    {
      label: 'ДЗ выполнено не вовремя',
      value: `<span>${settings?.domashka_small_crystall} <img src="/images/crystal.svg" alt="" /></span>`,
      status: 'error'
    },
    {
      label: 'Бонус за аккуратность',
      value: `<span>${settings?.domashka_accur_crystall_bonus} <img src="/images/crystal.svg" alt="" /></span>`,
      status: 'success'
    }
  ];

  if (!is_done) {
    const nowTime = new Date().getTime()/1000;
    const endTime = new Date(end.replace(' ', 'T')).getTime()/1000;

    rows.push({
      label: nowTime > endTime ? 'Просрочено' : 'Осталось',
      value: getDuration(nowTime - endTime),
      status: nowTime > endTime ? 'error' : 'success'
    });
  } else {
    rows.push({
      label: 'Выполнено',
      value: format(new Date(is_done.replace(' ', 'T')), 'dd.MM.yyyy'),
      status: 'success'
    });
  }

  return (
    <div className={styles.root}>
      <div className={styles.block}>
        {rows.map(({ label, value, status }, index) => (
          <div className={styles.row} key={index}>
            <div className={classNames(styles.label, status && styles[status])}>{label}:</div>
            <div
              className={classNames(styles.value, status && styles[status])}
              dangerouslySetInnerHTML={{ __html: value }}
            />
          </div>
        ))}
      </div>
      <div className={styles.content}>
        Привет!
        <br /><br />
        1. Выполни домашние задания. <br />
        2. Сфотографируй само задание. <br />
        3. Сфотографируй, как ты его выполнил. <br />
        4. Прикрепи фотографии.
        <br /><br />
        Выполняй задания и зарабатывай кристаллы!
      </div>

      {user.type === 'M' ? (
        <MentorButtons
          current={item}
          onClickReject={onClickReject}
          onClickSubmit={onClickSubmit}
          onSendComment={onSendComment}
          onChangeFile={onChangeFile}
          bonus={settings?.domashka_accur_crystall_bonus}
        />
      ) : (
        <PlayerButtons
          current={item}
          onClickSubmit={onClickSubmit}
          onChangeFile={onChangeFile}
        />
      )}
    </div>
  )
};

export default Homework;
