import { useDispatch, useSelector } from 'react-redux';
import RewardsList from 'components/RewardsList';
import { MainLayout } from 'components/Layouts';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import * as api from 'helpers/api';

const Rewards = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));

  const { data: shop, reset: resetShop } = useApiQuery({
    name: ['shop'],
    path: `/getshop/${user.uid}/`,
    skip: !user
  });

  if (!user) return null;

  const handleAcceptItem = (uid) => {
    api
      .get(`/playerrewardsfrommentor/${user.uid}/accept/${uid}/S/`)
      .then(response => {
        const result = response.data.response;
        if (result) {
          resetShop();
          if (result.message) {
            dispatch(setItem(['notifications', 'acceptRewards'], { type: 'success', content: result.message }));
          }
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'acceptRewards'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <MainLayout title="Награды от родителя">
      <RewardsList items={shop?.byed} onAcceptItem={handleAcceptItem} />
    </MainLayout>
  );
};

export default Rewards;
