import { useState } from 'react';
import classNames from 'classnames';
import Switcher from 'components/fields/Switcher';

import styles from './schedule-reward.module.css';

const ScheduleReward = ({ name, value, onChange, checked: _checked }) => {
  const [ checked, setChecked ] = useState(_checked);
  const handleChange = (e) => {
    onChange(e);
    setChecked(e.target.checked);
  };

  return (
    <div className={classNames(styles.root, checked && styles.selected)}>
      <div className={classNames(styles.button, styles.buttonFree)}>
        0 <img src="/images/crystal.svg" className={styles.crystal} alt="" />
      </div>
      <div className={styles.switch}>
        <Switcher id={name} name={name} checked={checked} onChange={handleChange} />
      </div>
      <div className={classNames(styles.button, styles.buttonValue)}>
        {value} <img src="/images/crystal.svg" className={styles.crystal} alt="" />
      </div>
    </div>
  );
};

export default ScheduleReward;
