import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import LessonsUpdate from 'components/schedule/LessonsUpdate';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';

const LessonsAllUpdate = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player ? user.current_player : null;
  const lessonsPlayer = useSelector(selectItem(['lessons_player', currentPlayer?.uid_gamer]))

  const { data: lessons } = useApiQuery({
    name: ['lessons', currentPlayer?.uid_gamer],
    path: `/getlesson/${currentPlayer?.uid_gamer}/`,
    skip: !currentPlayer
  });

  useApiQuery({
    name: ['lessons_player', currentPlayer?.uid_gamer],
    path: `/getlesson/${currentPlayer?.uid_gamer}/selected/`,
    skip: !currentPlayer
  });

  const handleChange = (uid, lesson_uid, name, checked) => {
    const formdata = new FormData();
    formdata.append('params[user_uid]', currentPlayer.uid_gamer);
    if (uid) formdata.append('params[std_uid]', uid);
    if (name) formdata.append('params[lesson_name]', name);
    if (lesson_uid) formdata.append('params[lesson_uid]', lesson_uid);
    formdata.append('params[set_task]', false);
    if (checked) formdata.append('params[type]', 'insert');
    else formdata.append('params[type]', 'delete');

    api
      .post(`/setlesson/`, formdata)
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['lessons_player', currentPlayer.uid_gamer], result, { isHard: true }));
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'setLesson'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  if (!user) return null;

  let _lessonsPlayer = lessonsPlayer?.map(item => ({ ...item, lesson_uid: item.uid, checked: true }));
  const _lessons = lessons?.map(item => {
    if (!(_lessonsPlayer?.length)) return item;

    const index = _lessonsPlayer?.findIndex(({ std_uid }) => std_uid === item.uid);
    if (index === -1) return item;

    const lesson = _lessonsPlayer.splice(index, 1);
    return { ...item, lesson_uid: lesson[0].lesson_uid, checked: true };
  });

  const allLessons = (_lessons?.length && _lessonsPlayer?.length) ? [ ..._lessons, ..._lessonsPlayer ] : _lessons;

  const handleChangeAll = (checked) => {
    allLessons?.forEach(({ uid, lesson_uid, name, checked: itemChecked }) => {
      if (itemChecked !== checked) handleChange(uid, lesson_uid, name, checked);
    });
  };

  return (
    <MainLayout
      title="Расписание"
      description="Выберите предметы, которые изучают в вашем классе"
    >
      <LessonsUpdate
        user={user}
        lessons={allLessons}
        onChange={handleChange}
        onChangeAll={handleChangeAll}
        showCustom
      />

      <ButtonsContainer>
        <Button
          butStyle="primary"
          disabled={!lessonsPlayer?.length}
          to="/player-lessons-update/homework"
        >
          Далее
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default LessonsAllUpdate;
