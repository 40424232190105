import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import Block from 'components/Block';
import Radio from 'components/fields/Radio';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import ruPlural from 'helpers/ruPlural';
import * as api from 'helpers/api';

import styles from './Tariffs.module.css';
import { differenceInCalendarDays } from 'date-fns';

const SelectTariff = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectItem(['user']));
  const tariff_types = useSelector(selectItem(['settings', 'tariff_types']));

  const [ selectedTariff, setSelectedTariff ] = useState(null);

  const handleClickSubmit = () => {
    api
      .get(`/iwantpremium/${user?.uid}/${selectedTariff}/`)
      .then(response => {
        const result = response.data.response;
        const payUrl = result?.transaction?.data?.payUrl;
        if (payUrl) window.location.replace(payUrl);
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickCancel = () => {
    const formdata = new FormData();
    formdata.append('order_uid', user?.tariff?.order_uid);

    api
      .post('/cancelrecurrentpay/')
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['user', 'tariff', 'actual'], false));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <MainLayout title="Ваша подписка">
      <Block>
        {user?.tariff?.actual ? (
          <div className={styles.info}>
            <div className={styles.infoContent}>До окончания подписки осталось {differenceInCalendarDays(new Date(user.tariff.date_end), new Date())}&nbsp;дней</div>
            <div className={styles.infoButtons}>
              <Button
                butStyle="primary"
                size="medium"
                onClick={handleClickSubmit}
                disabled={!selectedTariff}
                fullWidth
              >
                Продлить подписку
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.info}>
            <div className={styles.infoContent}>У вас нет подписки</div>
            <div className={styles.infoButtons}>
              <Button
                butStyle="primary"
                size="medium"
                onClick={handleClickSubmit}
                disabled={!selectedTariff}
                fullWidth
              >
                Оформить подписку
              </Button>
            </div>
          </div>
        )}
      </Block>
      <Block title="Тарифы">
        <div className={styles.tariffs}>
          <Radio
            id="tariff"
            name="tariff"
            value={selectedTariff}
            onChange={e => setSelectedTariff(e.target.value)}
            options={tariff_types?.map(({ month, per_month }) => ({
              label: <span className={styles.tariffName}>{month} {ruPlural(month, ['месяц', 'месяца', 'месяцев'])} <span className={styles.tariffPrice}>{per_month} ₽ в месяц</span></span>,
              value: month,
              className: styles.tariffLabel
            }))}
          />
        </div>
        <ButtonsContainer className={styles.buttons}>
          <Button
            butStyle="primary"
            onClick={handleClickSubmit}
            disabled={!selectedTariff}
            fullWidth
          >
            Оформить подписку
          </Button>
          {user?.tariff?.actual && (
            <Button
              butStyle="text-primary"
              onClick={handleClickCancel}
              fullWidth
            >
              Отменить подписку
            </Button>
          )}
        </ButtonsContainer>
      </Block>
    </MainLayout>
  );
};

export default SelectTariff;
