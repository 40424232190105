import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import Modal from 'components/Modal';

import styles from './login-modal.module.css';

const LoginModal = ({ player, open, onClose, onClickLogin, onClickCopy, user_agent }) => {
  const name = player.info ? player.info.name : player.name;
  const self_key = player.info ? player.info.self_key : player.self_key;
  let avatar = player.info ? player.info.avatar : player.avatar;
  if (!avatar) avatar = `/icons/user-filled.svg`;

  return (
    <Modal
      viewStyle="bottom"
      open={open}
      title="Передать доступ школьнику"
      onClose={onClose}
    >
      <div className={styles.login}>
        <div className={styles.imageContainer}>
          <img src={avatar} className={styles.loginImage} alt="" />
        </div>
        <div className={styles.loginName}>{name}</div>

        <div className={styles.loginDescription}>
          1. Школьник скачивает приложение MeTimer <br />
          2. Школьник переходит по отправленной вами ссылке
        </div>

        <ButtonsContainer>
          <Button
            className={styles.loginButton}
            butStyle="primary"
            onClick={() => onClickCopy(self_key)}
            href={user_agent === 'timer-mobile' ? '#share-link' : undefined}
          >
            Отправить ссылку
          </Button>
          {(window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost') && (
            <Button
              className={styles.loginButtonMain}
              butStyle="secondary"
              fullWidth={true}
              onClick={() => onClickLogin(player.uid_gamer)}
            >
              Войти на этом телефоне
            </Button>
          )}
        </ButtonsContainer>
      </div>
    </Modal>
  );
};

export default LoginModal;