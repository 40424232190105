import { forwardRef, useState } from 'react';
import classNames from 'classnames';
import Button from 'components/Button';

import baseStyles from '../fields.module.css';
import styles from './switch.module.css';

const Switch = forwardRef(({
    id, name, onClick, onBlur, onChange, value, options, title, label, error, containerClass, columns = 2, size = 'medium'
  }, ref) => {
  const [ current, setCurrent ] = useState(value);

  if (!options) return null;

  //defaultValue = value ???

  const handleClickItem = (item) => {
    setCurrent(item.value);
    if (onChange) onChange({ target: { name: name, value: item.value} });
  }

  return (
    <div className={classNames(baseStyles.container, containerClass)}>
      {title && <div className={baseStyles.title}>{title}</div>}
      {label && <label className={baseStyles.label}>{label}</label>}
      <div className={classNames(baseStyles.inputContainer, styles.items)} style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
        {options.map(item =>
          <Button
            type="button"
            butStyle="quaternary"
            className={classNames(styles.item, String(current) === String(item.value) && styles.checked, item.className)}
            size={size}
            onClick={() => handleClickItem(item)}
            key={item.value}
          >
            <span dangerouslySetInnerHTML={{ __html: item.label }} />
          </Button>
        )}
      </div>
      {error &&
        <span className={baseStyles.errorText}>{ error }</span>
      }
    </div>
  );
});

export default Switch;
