// import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { RegisterLayout } from 'components/Layouts';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import copyToClipboard from 'helpers/copyToClipboard';
// import getCurrentHost from 'helpers/getCurrentHost';

import styles from './account.module.css';

const SendLink = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const user_agent = useSelector(selectItem(['settings', 'user_agent']));

  // useEffect(() => {
  //   if (!state.class) navigate('/');
  //   // eslint-disable-next-line
  // }, [state]);

  const handleClickShare = () => {
    const result = copyToClipboard('https://metimer.ru/api/devicedetecter/');

    if (result)
      dispatch(setItem(['notifications', 'copy_link'], { type: 'success', content: 'Ссылка скопирована' }));
    else
      dispatch(setItem(['notifications', 'copy_link'], { type: 'error', content: 'Ошибка копирования' }));
  };

  return (
    <RegisterLayout showLogo buttons={
      <div>
        <Button
          butStyle="tertiary"
          onClick={() => window.history.length && navigate(-1)}
          icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
          className={styles.buttonBack}
        />
      </div>
    }>
      <h1 className={styles.title}>
        {state?.class > 4 ? (
          'Отправь ссылку родителю для настройки сервиса или настрой самостоятельно'
        ) : (
          'Отправь ссылку родителю для настройки сервиса'
        )}
      </h1>
      <div className={styles.content}>
        <ButtonsContainer>
          <Button
            butStyle="primary"
            onClick={handleClickShare}
            href={user_agent === 'timer-mobile' ? '#share-link' : undefined}
            fullWidth
          >
            Отправить ссылку
          </Button>
          {state?.class > 4 && (
            <Button
              butStyle="secondary"
              to="/register"
              state={{ class: state?.class }}
              fullWidth
            >
              Настроить самостоятельно
            </Button>
          )}
        </ButtonsContainer>
      </div>
    </RegisterLayout>
  );
}

export default SendLink;
