import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { RegisterLayout } from 'components/Layouts';
import Form from 'components/Form';
import Button from 'components/Button';
import Switch from 'components/fields/Switch';

import { grades } from 'data/mock';

import styles from './account.module.css';

const SelectType = () => {
  const navigate = useNavigate();
  const [ isValid, setIsValid ] = useState(false);

  const inputs = [
    { id: 'class', name: 'class', component: Switch, validation: 'select',
      props: { fullWidth: true, options: grades, columns: 4 }
    },
  ];

  const onFormSubmit = data => {
    if (data.class < 5) navigate('/send-link', { state: { class: data.class } });
    else if (data.class < 9) navigate('/send-link', { state: { class: data.class } });
    else navigate('/register', { state: { class: data.class } });
  };

  return (
    <RegisterLayout showLogo>
      <h1 className={styles.title}>Регистрация</h1>
      <div className={styles.subtitle}>Выбери класс, в котором ты учишься</div>
      <div className={styles.content}>
        <Form
          inputs={inputs}
          onSubmit={onFormSubmit}
          onValidChange={setIsValid}
          buttons={
            <>
              <div>
                <Button
                  butStyle="tertiary"
                  onClick={() => window.history.length && navigate(-1)}
                  icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
                  className={styles.buttonBack}
                />
              </div>
              <Button
                disabled={!isValid}
                type="submit"
                butStyle="primary"
              >
                Далее
              </Button>
            </>
          }
        />
      </div>
    </RegisterLayout>
  );
}

export default SelectType;
