import { Link } from 'react-router-dom';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';

import styles from './portfail-home.module.css';

const PortfailHomePlaceholder = ({ user, items }) => {
  const needConfig = Object.values(items).findIndex(({ units }) => (units && units.findIndex(({ checked }) => !!checked) !== -1)) === -1;

  if (needConfig) {
    if (user.type === 'P' || user.type === 'S')
      return (
        <div className={styles.root}>
          Попроси родителя настроить портфель, чтобы получать кристаллы!
          <div style={{ textAlign: 'right', margin: '-10px -13px -25px 0', lineHeight: 0 }}>
            <img src="/images/corgi_1.png" width={120} alt="" />
          </div>
        </div>
      );

    return (
      <div className={styles.root}>
        <Link to="/briefcase-update" className={styles.link} />
        <div className={styles.text}>
          Взять все необходимое с собой
        </div>
        <ButtonsContainer className={styles.buttons}>
          <Button
            butStyle="secondary"
            size="medium"
            fullWidth
          >
            Настроить
          </Button>
        </ButtonsContainer>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Link to="/briefcase" className={styles.link} />
      <div className={styles.text}>
        Взять все необходимое с собой
      </div>
      <ButtonsContainer className={styles.buttons}>
        <Button
          butStyle="primary"
          size="medium"
          fullWidth
        >
          Перейти
        </Button>
      </ButtonsContainer>
    </div>
  );
};

export default PortfailHomePlaceholder;
