import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { MainLayout } from 'components/Layouts';
import Form from 'components/Form';
import PhoneCode from 'components/fields/PhoneCode';
import Button from 'components/Button';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import * as api from 'helpers/api';

import styles from './mentor.module.css';

const UpdatePhone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectItem(['user']));
  const verification = useSelector(selectItem(['verification']));

  const onPhoneSubmit = (result) => {
    const fd = new FormData();
    fd.append('params[phone]', verification.checkingPhone.replaceAll(' ', ''));

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          const data = {...result.user};
          delete(data.player);
          dispatch(setItem(['user'], data));
          dispatch(setItem(['notifications', 'updateUser'], { type: 'success', content: 'Изменения сохранены' }));
          dispatch(setItem(['verification'], {}, {isHard: true}));
          navigate('/update');
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const inputs = [
    { id: 'phone', name: 'phone', component: PhoneCode, validation: 'phone',
      props: { label: 'Введите ваш номер телефона', placeholder: '+7', fullWidth: true, mask: '+7 ### ### ## ##', type: 'tel',
        onPhoneSubmit: onPhoneSubmit, path: '/checksms'
      }
    },
  ];

  const onFormSubmit = data => {
    if (!verification.isCodeSended) dispatch(setItem(['verification', 'sendCode'], true));
    if (!verification.isCodeValid) return null;
  };

  return (
    <MainLayout showBackButton title="Изменить номер">
      {/* <div className={styles.subtitle}>
        {verification.isCodeSended ? (
          <>
            Проверьте код из смс, он придет на номер
            <div style={{ marginTop: '5px' }}><b className='accent-text'>{verification.checkingPhone}</b></div>
          </>
        ) : (
          <>
            Введите номер телефона, а затем код из смс
          </>
        )}
      </div> */}
      <div className={styles.content}>
        <div className={styles.regLogin}>
          <Form
            inputs={inputs}
            onSubmit={onFormSubmit}
            buttons={
              !verification.isCodeSended && (
                <>
                  <Button
                    type="submit"
                    butStyle="primary"
                    fullWidth
                  >
                    Получить код
                  </Button>
                  <div className={styles.terms}>
                    Нажимая кнопку ”<span className="accent-text">Войти</span>” <br /> вы соглашаетесь
                    с <a href="https://me-timer.ru/docs/license.pdf">политикой обработки персональных данных</a> и
                    принимаете <a href="https://me-timer.ru/docs/oferta.pdf">условия оферты сервиса</a>
                  </div>
                </>
              )
            }
          />
        </div>
        {verification.isCodeSended && (
          <div className="form-buttons">
            <Button
              type="reset"
              butStyle="tertiary"
              icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
              onClick={() => dispatch(setItem(['verification', 'isCodeSended'], false))}
              className={styles.buttonBack}
            >
            </Button>
          </div>
        )}
      </div>
    </MainLayout>
  );
}

export default UpdatePhone;
