import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as api from 'helpers/api';
import { RegisterLayout } from 'components/Layouts';
import Form from 'components/Form';
import Input from 'components/fields/Input';
// import Switch from 'components/fields/Switch';
import Select from 'components/fields/Select';
import Button from 'components/Button';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';

import styles from './account.module.css';

import { grades, letters } from 'data/mock';

const CreatePlayer = () => {
  // const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const [ userLocation, setUserLocation ] = useState(null);
  const [ isSubmitActive, setSubmitActive ] = useState(false);

  const user = useSelector(selectItem(['user']));
  // const tokens = useSelector(selectItem(['tokens']));

  let locationOpts = [];
  if (user.location) {
    locationOpts.push({
      label: user.location,
      value: `${user.gmt}_${user.location}`
    });
  }

  let inputs = [
    { id: 'name', name: 'name', component: Input, validation: 'string',
      props: { label: 'Никнейм', placeholder: 'Светик', fullWidth: true }
    },
    { id: 'location', name: 'location', component: Select, validation: 'select',
      props: {
        modalTitle: 'Город проживания', label: 'Ваш город', searchPlaceholder: 'Москва',
        options: locationOpts, showDropdownButton: false,
        placeholder: 'Москва', fullWidth: true, isSearchable: true,
        loadUrl: '/getcity', mapper: (data) => data.map(({ gmt, name }) => ({ label: name, value: `${gmt}_${name}` }))
      }
    },
    { id: 'school', name: 'school', component: Select, validation: 'select',
      props: {
        label: 'Номер школы', placeholder: 'Выберите школу', fullWidth: true, isSearchable: true,
        value: '', options: [], showDropdownButton: false,
        loadUrl: `/getschool/${userLocation}`, mapper: (data) => data.map(({ name, uid }) => ({ label: name, value: uid })),
        customParams: {
          user_uid: user.uid,
          city: userLocation,
          buttonUrl: '/newschool/',
          buttonText: 'Школы нет в списке',
          inputText: 'Введите название школы',
        }
      }
    },
    { id: 'class', name: 'class', component: Select, validation: 'select',
      props: {
        modalTitle: 'Класс', label: 'Класс', options: grades, showDropdownButton: false,
        placeholder: '8', showArrow: false
      }
    },
    { id: 'class_sign', name: 'class_sign', component: Select, validation: 'select',
      props: { placeholder: 'А', options: letters, value: '', showDropdownButton: false, }
    },
  ];
  // if (state?.class) {
  //   inputs.push({ id: 'location', name: 'location', component: Select, validation: 'select',
  //     props: {
  //       modalTitle: 'Город проживания', label: 'Ваш город', searchPlaceholder: 'Москва',
  //       options: locationOpts,
  //       placeholder: 'Москва', fullWidth: true, isSearchable: true,
  //       loadUrl: '/getcity', mapper: (data) => data.map(({ gmt, name }) => ({ label: name, value: `${gmt}_${name}` }))
  //     }
  //   });
  // } else {
  //   inputs.push({ id: 'class', name: 'class', component: Switch, validation: 'select',
  //     props: { id: 'class', label: 'Выберите в каком классе учится Школьник', options: grades, columns: 4 }
  //   });
  // }
  const onFormChange = (data) => {
    if (data.location) setUserLocation(data.location.split('_')[1]);
  };

  const onFormSubmit = (data) => {
    // if (state?.class) {
    //   const fd = new FormData();
    //   for (let key in data) {
    //     fd.append(`params[${key}]`, data[key]);
    //   }

    //   if (tokens && Object.keys(tokens).length) {
    //     for (let key in tokens) {
    //       const token = tokens[key];
    //       fd.append(`params[${token.key}]`, token.value);
    //     }
    //   }

    //   const [ gmt, location ] = data.location.split('_');

    //   fd.append('params[gmt]', gmt);
    //   fd.append('params[location]', location);
    //   // fd.append('params[user_uid]', user.uid);
    //   fd.append('params[class]', state.class);
    //   fd.append('params[type]', 'S');

    //   api
    //     .post(`/updateUserProfile/${user.uid}/`, fd)
    //     .then(response => {
    //       const result = response.data.response;
    //       if (result.success) {
    //         dispatch(setItem(['user'], result.user));
    //         navigate('/register-success');
    //       } else {
    //         const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
    //         dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
    //       }
    //     })
    //     .catch(error => {
    //       dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
    //     });
    // } else {
      api
        .get(`/createMentoringForChild/new_player_create/9999/`)
        .then(response => {
          const result = response.data.response;
          if (result.success && result.player) {
            saveData(result.player.uid, data);
          } else {
            const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
            dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
          }
        })
        .catch(error => {
          dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
        });
    // }
  };

  const saveData = (uid, data) => {
    if (!uid) return;

    const fd = new FormData();
    for (let key in data) {
      fd.append(`params[${key}]`, data[key]);
    }

    const [ gmt, location ] = data.location.split('_');

    fd.append('params[gmt]', gmt);
    fd.append('params[location]', location);

    api
      .post(`/updateUserProfile/${uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          dispatch(setItem(['user'], result.user));
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'success', content: 'Ребенок добавлен!' }));
          if (hash === '#update') navigate('/update');
          else navigate(`/register-success`);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  return (
    <RegisterLayout showLogo>
      <h1 className={styles.title}>Школьник</h1>
      <div style={{ position: 'relative' }}>
        <img style={{ position: 'absolute', top: '-80px', right: '10px', width: '134px' }} src="/images/corgi_1.png" alt="" />
      </div>
      <div className={styles.content}>
        <Form
          inputs={inputs}
          onSubmit={onFormSubmit}
          onChange={onFormChange}
          onValidChange={setSubmitActive}
          buttons={
            <Button
              type="submit"
              butStyle="primary"
              disabled={!isSubmitActive}
              fullWidth
            >
              Далее
            </Button>
          }
        />
      </div>
    </RegisterLayout>
  );
}

export default CreatePlayer;
