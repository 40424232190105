import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { format, startOfWeek, startOfDay, addDays, isEqual } from 'date-fns';
import { ru } from 'date-fns/locale';
import { dayNames } from 'data/mock';
import LessonsScheduleHead from './LessonsScheduleHead';
import LessonsScheduleDay from './LessonsScheduleDay';

import 'swiper/css';
import styles from './lessons-schedule.module.css';

const getWeek = (startDay) => (
  [...Array(7)].map((item, index) => addDays(startDay, index))
);

const LessonsSchedule = ({ onClickItem, onChangeDay }) => {
  const [ weekSwiper, setWeekSwiper ] = useState(null);
  const [ daysSwiper, setDaysSwiper ] = useState(null);

  const [ currentDay, setCurrentDay ] = useState(startOfDay(new Date()));
  const weekStart = startOfWeek(currentDay, { weekStartsOn: 1 });
  const [ weeks, setWeeks ] = useState([ getWeek(addDays(weekStart, -7)), getWeek(weekStart), getWeek(addDays(weekStart, 7)) ]);
  const currentDayIndex = weeks.flat().findIndex(day => isEqual(day, currentDay));

  useEffect(() => {
    if (onChangeDay) onChangeDay(format(currentDay, 'yyyy-MM-dd'));
    // eslint-disable-next-line
  }, [currentDay]);

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.days}>
          {[...Array(7)].map((item, index) => (
            <div className={styles.day} key={index}>
              <div className={styles.dayName}>{dayNames[index]}</div>
            </div>
          ))}
        </div>
        <Swiper
          initialSlide={1}
          loop={false}
          resistanceRatio={0.35}
          longSwipesMs={10000}
          longSwipesRatio={0.99}
          onSwiper={setWeekSwiper}
          onSlideChangeTransitionEnd={swiper => {
            console.log('change week');

            if (swiper.swipeDirection) {
              console.log('need change day')
              daysSwiper?.slideTo(swiper.swipeDirection === 'next' ? currentDayIndex+7 : currentDayIndex-7);
            }

            if (swiper.activeIndex === weeks.length-1) {
              // next
              setWeeks(prev => [...prev, getWeek(addDays(weekStart, 14))]);

            } else if (swiper.activeIndex === 0) {
              // prev
              setWeeks(prev => [getWeek(addDays(weekStart, -14)), ...prev]);

              swiper.slideNext(0);
              if (swiper.swipeDirection) {
                if (weeks.flat()[daysSwiper.activeIndex-7]) setCurrentDay(weeks.flat()[daysSwiper.activeIndex-7]);
              } else daysSwiper.slideTo(currentDayIndex + 7, 0);
            }
          }}
        >
          {weeks.map((week, index) => (
            <SwiperSlide key={index}>
              <LessonsScheduleHead
                current={currentDay}
                week={week}
                onClickDay={(dayIndex) => daysSwiper.slideTo(dayIndex + index*7)}
                key={index}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <div className={styles.currentDay}>
          {format(currentDay, 'EEEE, d MMMM yyyy', {locale: ru })} г.
        </div>
      </div>

      <Swiper
        initialSlide={currentDayIndex}
        loop={false}
        resistanceRatio={0.35}
        longSwipesMs={10000}
        longSwipesRatio={0.99}
        onSwiper={setDaysSwiper}
        onSlideChangeTransitionEnd={swiper => {
          console.log('change day');
          setCurrentDay(weeks.flat()[swiper.activeIndex]);
          const newWeekIndex = Math.ceil((swiper.activeIndex+1)/7) - 1;
          if (weekSwiper && weekSwiper.activeIndex !== newWeekIndex) {
            console.log('need change week')
            weekSwiper.slideTo(newWeekIndex);
          }
        }}
      >
        {weeks.flat().map((date, index) => (
          <SwiperSlide key={index}>
            {({ isActive, isPrev, isNext }) => {
              return (
                <LessonsScheduleDay
                  dateValue={format(date, 'yyyy-MM-dd')}
                  onClickItem={onClickItem}
                  skip={!isActive}
                  key={'day_' + index}
                />
            )}}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default LessonsSchedule;
