import { useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import Button from 'components/Button';
import { setItem } from 'store/app/actions';

import styles from './info-button.module.css';

const InfoButton = ({ slug }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setItem(['common', 'faq'], slug));
  };

  return (
    <Button
      onClick={handleClick}
      butStyle="text"
      className={styles.button}
      icon={<ReactSVG src="/icons/question.svg" wrapper="span" />}
    />
  )
};

export default InfoButton;
