import classNames from 'classnames';

import styles from './homework-item.module.css';

const HomeworkCrystalls = () => {
  return (
    <div className={classNames(styles.root, styles.long)}>
      <div className={styles.longTitle}>Не откладывай на потом и получи еще <b>больше кристаллов</b></div>
      <div className={styles.longContent}>
        <div className={styles.longRow}>
          <div className={styles.longLabel}>На 1 день раньше: </div>
          <div className={styles.longValue}>+10 <img src="/images/crystal.svg" alt="" width="12px" height="16px" /></div>
        </div>
        <div className={styles.longRow}>
          <div className={styles.longLabel}>На 2 дня раньше: </div>
          <div className={styles.longValue}>+20 <img src="/images/crystal.svg" alt="" width="12px" height="16px" /></div>
        </div>
        <div className={styles.longRow}>
          <div className={styles.longLabel}>На 3 дня раньше: </div>
          <div className={styles.longValue}>+30 <img src="/images/crystal.svg" alt="" width="12px" height="16px" /></div>
        </div>
      </div>
      <img className={styles.longImage} src="/images/long-bg.svg" alt="" />
    </div>
  );
};

export default HomeworkCrystalls;
