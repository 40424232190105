import { Navigate } from 'react-router-dom';
import * as Common from '../pages/Common';
import * as Account from '../pages/Account';
import * as Player from '../pages/Player';
import * as Mentor from '../pages/Mentor';

const routes = {
  main: [
    { path: '/login', element: <Navigate replace to="/" /> },
    { path: '/auth/:token', element: <Account.Auth /> },
    { path: '/register', element: <Navigate replace to="/" /> },
    { path: '/register/:phone', element: <Navigate replace to="/" /> },
    { path: '/maskot/:uid', element: <Common.Maskot /> },
    { path: '*', element: <Common.NotFound /> },
  ],

  register: [
    { path: '/', element: <Account.SelectType />, exact: true },
    { path: '/select-class', element: <Account.SelectClass /> },
    { path: '/send-link', element: <Account.SendLink /> },
    { path: '/register', element: <Account.Register /> },
    { path: '/register/:phone', element: <Account.Register /> },
    { path: '/login', element: <Account.Login /> },
    { path: '/auth/:token', element: <Account.Auth /> },
    { path: '/add-mentoring/:token', element: <Account.CreateMentoring /> },
    { path: '/maskot/:uid', element: <Common.Maskot /> },
    { path: '*', element: <Navigate replace to="/" /> }
  ],

  emptyPlayer: [
    { path: '/create-player', element: <Account.CreatePlayer /> },
    { path: '/create-player-success', element: <Account.CreatePlayerSuccess /> },
    { path: '/add-mentoring/:token', element: <Account.CreateMentoring /> },
    { path: '*', element: <Navigate replace to="/create-player" /> }
  ],
  player: [
    { path: '/', element: <Player.Home />, exact: true },
    { path: '/register-success', element: <Account.RegisterSuccess /> },

    { path: '/invite-mentor', element: <Player.InviteMentor /> },
    { path: '/invite-mentor/:phone', element: <Player.InviteMentor /> },
    { path: '/invite-player', element: <Player.InvitePlayer /> },
    { path: '/update', element: <Player.Update /> },
    { path: '/create-avatar', element: <Player.CreateAvatar /> },
    { path: '/rating', element: <Player.Rating /> },

    { path: '/invited', element: <Player.InvitedUsers /> },
    { path: '/chest', element: <Player.Backpack /> },
    { path: '/artefacts', element: <Player.Artefacts /> },
    { path: '/store', element: <Player.Store /> },

    { path: '/rewards', element: <Player.Rewards /> },
    { path: '/rewards-from-mentor', element: <Player.RewardsMentor /> },

    { path: '/briefcase', element: <Player.Portfail /> },
    { path: '/briefcase-update', element: <Player.PortfailUpdate /> },

    { path: '/lessons-schedule', element: <Player.LessonsSchedule /> },
    { path: '/lessons-schedule/:date', element: <Player.LessonsSchedule /> },
    { path: '/lessons-schedule-add', element: <Player.SelectLessonType />},
    { path: '/lessons-schedule-update', element: <Player.UpdateLesson />},
    { path: '/lessons-schedule-update/:date/:uid', element: <Player.UpdateLesson />},
    { path: '/player-lessons-update', element: <Player.LessonsAllUpdate /> },
    { path: '/player-lessons-update/homework', element: <Player.LessonsHomeworkUpdate /> },
    { path: '/player-lessons-update/time', element: <Player.LessonsTimeUpdate /> },
    { path: '/player-lessons-update/schedule', element: <Player.LessonsScheduleUpdate /> },

    // { path: '/homework', element: <Player.HomeworkList /> },
    // { path: '/homework/:date', element: <Player.HomeworkList /> },
    { path: '/homework/:date/:task_uid', element: <Player.Homework /> },
  ],

  emptyMentor: [
    { path: '/create', element: <Account.CreateMentor /> },
    { path: '/create-player', element: <Account.CreatePlayer /> },
    { path: '/create-player/:uid', element: <Account.CreatePlayer /> },
    { path: '/create-player-success', element: <Account.CreatePlayerSuccess /> },
    { path: '/add-mentoring/:token', element: <Account.CreateMentoring /> },
    { path: '*', element: <Navigate replace to="/create" /> }
  ],
  emptyMentorPlayer: [
    { path: '/create-player', element: <Account.CreatePlayer /> },
    { path: '/create-player/:uid', element: <Account.CreatePlayer /> },
    { path: '/create-player-success', element: <Account.CreatePlayerSuccess /> },
    { path: '/add-mentoring/:token', element: <Account.CreateMentoring /> },
    { path: '*', element: <Navigate replace to="/create-player" /> }
  ],
  mentor: [
    { path: '/', element: <Mentor.Mentor />, exact: true },
    { path: '/register-success', element: <Account.RegisterSuccess /> },

    { path: '/player', element: <Mentor.Home /> },
    { path: '/add-player', element: <Mentor.AddPlayer /> },
    { path: '/create-player', element: <Account.CreatePlayer /> },
    { path: '/create-player/:uid', element: <Account.CreatePlayer /> },
    { path: '/create-player-success', element: <Account.CreatePlayerSuccess /> },
    { path: '/invite-player/:uid', element: <Mentor.InvitePlayer /> },
    { path: '/add-mentoring/:token', element: <Account.CreateMentoring /> },

    { path: '/update', element: <Mentor.Update /> },
    { path: '/update/phone', element: <Mentor.UpdatePhone /> },
    { path: '/update-player', element: <Mentor.UpdatePlayer /> },
    { path: '/update-player/:uid', element: <Mentor.UpdatePlayer /> },
    { path: '/rating', element: <Mentor.Rating /> },
    { path: '/invite-mentor/:uid', element: <Mentor.InviteMentor /> },
    { path: '/invite-mentor/:uid/:phone', element: <Mentor.InviteMentor /> },
    { path: '/invited', element: <Mentor.InvitedUsers /> },

    { path: '/chest', element: <Mentor.Backpack /> },
    { path: '/artefacts', element: <Mentor.Artefacts /> },

    { path: '/update-rewards', element: <Mentor.UpdateRewards /> },
    { path: '/rewards', element: <Mentor.Rewards /> },
    { path: '/store-update', element: <Mentor.StoreUpdate /> },
    { path: '/store', element: <Mentor.Store /> },

    { path: '/briefcase', element: <Mentor.Portfail /> },
    { path: '/briefcase-update', element: <Mentor.PortfailUpdate /> },

    { path: '/lessons-schedule', element: <Mentor.LessonsSchedule /> },
    { path: '/lessons-schedule/:date', element: <Mentor.LessonsSchedule /> },
    { path: '/lessons-schedule-add', element: <Mentor.SelectLessonType />},
    { path: '/lessons-schedule-update', element: <Mentor.UpdateLesson />},
    { path: '/lessons-schedule-update/:date/:uid', element: <Mentor.UpdateLesson />},
    { path: '/player-lessons-update', element: <Mentor.LessonsAllUpdate /> },
    { path: '/player-lessons-update/homework', element: <Mentor.LessonsHomeworkUpdate /> },
    { path: '/player-lessons-update/time', element: <Mentor.LessonsTimeUpdate /> },
    { path: '/player-lessons-update/schedule', element: <Mentor.LessonsScheduleUpdate /> },

    { path: '/tariffs', element: <Mentor.SelectTariff /> },

    // { path: '/homework', element: <Mentor.HomeworkList /> },
    // { path: '/homework/:date', element: <Mentor.HomeworkList /> },
    { path: '/homework/:date/:task_uid', element: <Mentor.Homework /> },
    { path: '/homework-update', element: <Mentor.HomeworkSchedule /> },
  ]
};

export default routes;
