import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { RegisterLayout } from 'components/Layouts';
import Button from 'components/Button';

import styles from './account.module.css';

const RegisterSuccess = () => {
  const navigate = useNavigate();

  return (
    <RegisterLayout showLogo buttons={
      <>
        <div>
          <Button
            butStyle="tertiary"
            onClick={() => window.history.length && navigate(-1)}
            icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
            className={styles.buttonBack}
          />
        </div>
        <Button
          butStyle="primary"
          to="/"
        >
          Далее
        </Button>
      </>
    }>
      <h1 className={styles.title}>Самостоятельный ребенок — счастливый родитель!</h1>
      <div>
        <div style={{ marginTop: '20px', marginBottom: '16px' }}>
          Настройте:
        </div>
        <ol>
          <li style={{ margin: '0 0 16px', padding: 0, lineHeight: 1.25 }}>Расписание</li>
          <li style={{ margin: '0 0 16px', padding: 0, lineHeight: 1.25 }}>Домашку</li>
          <li style={{ margin: '0 0 16px', padding: 0, lineHeight: 1.25 }}>Портфель</li>
          <li style={{ margin: '0 0 16px', padding: 0, lineHeight: 1.25, position: 'relative' }}>
            Магазин, в котором ребёнок сможет приобрести себе награду от вас за заработанные кристаллы
            <img
              src="/images/crystal.svg"
              style={{ position: 'absolute', top: '60px', right: '25%', width: '18.15px', height: '21.63px', transform: 'rotate(-6.1deg)' }}
              alt=""
            />
            <img
              src="/images/crystal.svg"
              style={{ position: 'absolute', top: '65px', right: '17.5%', width: '21.53px', height: '24.66px', transform: 'rotate(15.3deg)' }}
              alt=""
            />
            <img
              src="/images/crystal.svg"
              style={{ position: 'absolute', top: '60px', right: '10%', width: '18.15px', height: '21.63px', transform: 'rotate(-23.1deg)' }}
              alt=""
            />
          </li>
        </ol>
      </div>
    </RegisterLayout>
  );
};

export default RegisterSuccess;