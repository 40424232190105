import * as yup from 'yup';

const getValidationSchema = (inputs) => {
  if (!inputs) return null;
  else return yup.object(createSchema(inputs));
}

const types = {
  email:    yup.string().email('Неверный формат email').required('Заполните поле'),
  phone:    yup.string().transform(value => value.replace(/ /g, '')).matches(/^\+[7][0-9]{10}$/, 'Неверный формат номера').required('Заполните поле'),
  string:   yup.string().typeError('Неккоректное значение').matches(/^[A-Za-zА-Яа-я ]*$/, 'Неккоректное значение').min(2, 'Минимум 2 символа').max(40, 'Максимум 40 символов').required('Заполните поле'),
  date:     yup.string().typeError('Неккоректное значение').required('Выберите дату'),
  select:   yup.string().typeError('Поле обязательно к выбору').required('Выберите из списка'),
  multiple: yup.array().min(1, 'Выберите из списка').required('Выберите из списка'),
  code:     yup.string().matches(/^[0-9]{2} [0-9]{2}$/, 'Неверный код из смс').required('Неверный код из смс'),
  default:  yup.string().typeError('Неккоректное значение').required('Заполните поле')
};

const createSchema = (inputs) => {
  return inputs.reduce((prev, { name, validation }) => {
    if (!validation) return { ...prev };
    else return { ...prev, [name]: types[validation] ? types[validation] : types['default'] };
  }, {});
}

export default getValidationSchema;
