import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';

import styles from './homework-deals.module.css';

const HomeworkDeals = ({ user, items, onComplete, onDone }) => {
  if (!items) return (
    <div className={classNames(styles.root, styles.empty, 'skeleton-loader')} />
  );

  if (!items.length) {
    if (user.type === 'P' || user.type === 'S')
      return (
        <div className={classNames(styles.root, styles.empty)}>
          Попроси родителя добавить тебе Дела, чтобы получать кристаллы.
          <div style={{ textAlign: 'right', margin: '-25px -3px -25px 0', lineHeight: 0 }}>
            <img src="/images/corgi_2.png" width={120} alt="" />
          </div>
        </div>
      );

    return (
      <div className={classNames(styles.root, styles.empty)}>
        <Link to="/lessons-schedule-add" className={styles.link} />
        <div className={styles.text}>
          Добавьте Дела, чтобы научить ребёнка ответственности и самостоятельности!
        </div>
        <ButtonsContainer className={styles.emptyButtons}>
          <Button
            butStyle="secondary"
            size="medium"
            fullWidth
          >
            Настроить
          </Button>
        </ButtonsContainer>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {items.map(({ uid, name, reward, is_complete, is_done, is_returned }) => (
        <div className={styles.item} key={uid}>
          <div className={styles.content}>
            <div className={styles.name}>{name}</div>
            {!!reward && (
              user?.type === 'M' ? (
                // <div className={styles.status}>
                //   {(!is_complete && !is_done) && (
                //     <span className={styles.gray}>Не выполнено</span>
                //   )}
                //   {(is_complete && !is_done) && (
                //     <span>Выполнено</span>
                //   )}
                //   {is_done && (
                //     <span>Подтверждено</span>
                //   )}
                // </div>
                <div className={styles.reward}>
                  {reward}
                  <img src="/images/crystal.svg" className={styles.rewardIcon} alt="" />
                </div>
              ) : (
                <div className={styles.reward}>
                  {reward}
                  <img src="/images/crystal.svg" className={styles.rewardIcon} alt="" />
                </div>
              )
            )}
          </div>
          {user?.type === 'M' ? (
            <div className={styles.buttons}>
              {(!is_complete && !is_done) && (
                <button className={classNames(styles.button, styles.disabled)}>Подтвердить</button>
              )}
              {(is_complete && !is_done) && (
                <button className={styles.button} onClick={() => onDone(uid, is_returned)}>Подтвердить</button>
              )}
              {is_done && (
                <img src="/icons/checkmark-circle.svg" className={styles.done} alt="" />
              )}
            </div>
          ) : (
            <div className={styles.buttons}>
              {(!is_complete && !is_done) && (
                <button className={styles.button} onClick={() => onComplete(uid, is_returned)}>Выполнил</button>
              )}
              {(is_complete && !is_done) && (
                <span className={styles.complete}>На подтверждении</span>
              )}
              {is_done && (
                <img src="/icons/checkmark-circle.svg" className={styles.done} alt="" />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default HomeworkDeals;
