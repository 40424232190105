import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import FileInput from 'components/fields/FileInput';

import styles from './user-header.module.css';

const UserHeader = ({ user, onChange }) => {
  if (!user) return (
    <div className={styles.root}>
      <div className={styles.image}>
        <div className={classnames(styles.icon, 'skeleton-loader')} alt="" />
      </div>
      <div className={styles.header}>
        <h1 className={classnames(styles.title, 'skeleton-loader')}>&nbsp;</h1>
        <span className={classnames(styles.subtitle, 'skeleton-loader')}>&nbsp;</span>
      </div>
    </div>
  );

  let { avatar, info } = user;

  // if (info && info.name) name = info.name;
  if (info && info.avatar) avatar = info.avatar;
  // if (info && info.nickname) nickname = info.nickname;

  const photo = avatar || '/icons/user-filled.svg';

  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <img src={photo} className={styles.icon} alt="" />

        {/* <h1 className={styles.title}>{name}</h1>
        {(nickname && nickname !== 'undefined') && <span className={styles.subtitle}>{nickname}</span>} */}
        {onChange &&
          <div className={styles.edit}>
            <FileInput
              id="photo"
              name="photo"
              butStyle="text"
              text={<ReactSVG src="/icons/edit.svg" wrapper="span" />}
              onChange={onChange}
            />
          </div>
        }
      </div>
    </div>
  );
}

export default UserHeader;
