import { useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from 'helpers/api';
import { MainLayout } from 'components/Layouts';
import Form from 'components/Form';
import Input from 'components/fields/Input';
import Select from 'components/fields/Select';
// import Checkbox from 'components/fields/Checkbox';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import UserHeader from 'components/UserHeader';
import Block from 'components/Block';
import Modal from 'components/Modal';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';

import styles from './player.module.css';

import { grades, letters } from 'data/mock';

const Update = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const visibleSettings = useSelector(selectItem(['visible_settings']));
  const [ userLocation, setUserLocation ] = useState(null);
  const [ count, setCount ] = useState(0);
  // const user_agent = useSelector(selectItem(['settings', 'user_agent']));

  let locationOpts = [];

  useEffect(() => {
    if (user.location) {
      locationOpts.push({
        label: user.location,
        value: `${user.gmt}_${user.location}`
      });
      setUserLocation(user.location);
    }

    // eslint-disable-next-line
  }, [user]);

  const inputs = [
    { id: 'name', name: 'name', component: Input,
      props: { label: 'Никнейм', placeholder: 'Светик', fullWidth: true,
      value: user.name ?? ''
    }
    },
    { id: 'location', name: 'location', component: Select, validation: 'select',
      props: {
        label: 'Ваш город', value: `${user.gmt}_${user.location}`, query: user.location, options: locationOpts,
        placeholder: 'Москва', fullWidth: true, isSearchable: true, searchPlaceholder: 'Москва',
        loadUrl: '/getcity', mapper: (data) => data.map(({ gmt, name }) => ({ label: name, value: `${gmt}_${name}` }))
      }
    },
    { id: 'school', name: 'school', component: Select, validation: 'select',
      props: {
        label: 'Номер школы', placeholder: 'Выберите школу', fullWidth: true, isSearchable: true,
        value: user.school || '', options: (user.school && user.school_name) ? [{ label: user.school_name, value: user.school }] : [],
        loadUrl: `/getschool/${userLocation}`, mapper: (data) => data.map(({ name, uid }) => ({ label: name, value: uid })),
        customParams: {
          user_uid: user.uid,
          city: user.location,
          buttonUrl: '/newschool/',
          buttonText: 'Школы нет в списке',
          inputText: 'Введите название школы',
        }
      }
    },
    { id: 'class', name: 'class', component: Select, validation: 'select',
      props: { label: 'Класс', placeholder: '1', options: grades, value: user.class || '', showDropdownButton: false }
    },
    { id: 'class_sign', name: 'class_sign', component: Select, validation: 'select',
      props: { placeholder: 'А', options: letters, value: user.class_sign || '', showDropdownButton: false }
    },
    // { id: 'ac_domashka', name: 'ac_domashka', component: Checkbox,
    //   props: { label: 'Автоматически подтверждать Домашку', checked: user.ac_domashka ?? false, value: user.ac_domashka ?? false }
    // },
    // { id: 'ac_homework', name: 'ac_homework', component: Checkbox,
    //   props: { label: 'Автоматически подтверждать Домашние дела', checked: user.ac_homework ?? false, value: user.ac_homework ?? false }
    // },
  ];

  const onFormChange = (data) => {
    if (data.location) setUserLocation(data.location);
  };

  const onSubmit = data => {
    const fd = new FormData();
    for (let key in data) {
      if (key !== 'phone') fd.append(`params[${key}]`, data[key]);
      if (key === 'ac_domashka' || key === 'ac_homework') {
        if (!data[key]) fd.append(`params[${key}]`, 0);
        else fd.append(`params[${key}]`, 1);
      }
    }

    const [ gmt, location ] = data.location.split('_');

    fd.append('params[gmt]', gmt);
    fd.append('params[location]', location);

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          dispatch(setItem(['user'], result.user));
          dispatch(setItem(['notifications', 'updateUser'], { type: 'success', content: 'Изменения сохранены' }));
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleChangePhoto = (e) => {
    const formdata = new FormData();
    formdata.append('file', e.target.files[0], e.target.files[0].name);
    formdata.append('user_uid', user.uid);

    api
      .post(`/uploadavatars/`, formdata)
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['user', 'avatar'], result.url));
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickRemoveProfile = () => {
    api
      .get(`/deleteaccount/${user.uid}/`)
      .then(response => {
        dispatch(setItem(['user'], {}, { isHard: true }));
        dispatch(setItem(['notifications', 'deleteUser'], { type: 'error', content: 'Профиль успешно удален' }));
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'deleteUser'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickSignOut = () => {
    dispatch(setItem(['user'], {}, { isHard: true }));
  };

  const buttons = (
    <>
      <Button
        type="submit"
        fullWidth={true}
        butStyle="primary"
      >
        Сохранить
      </Button>
    </>
  );

  return (
    <MainLayout title={<span onClick={() => setCount(count+1)}>Профиль</span>} showBackButton={false}>
      <UserHeader user={user} onChange={handleChangePhoto} />
      <div style={{ marginBottom: '30px' }} />

      {visibleSettings?.a_referal_block && (
        <Block title="Реферальная программа">
          <ButtonsContainer>
            <Button
              to="/invited"
              butStyle="secondary"
              fullWidth={true}
            >
              Мои приглашенные
            </Button>
          </ButtonsContainer>
        </Block>
      )}

      <Block title="Данные школьника">
        <Form inputs={inputs} onChange={onFormChange} onSubmit={onSubmit} buttons={buttons} inputClass="dark" />
        {user.type === 'S' && (
          <div className={styles.removeButton}>
            <Modal
              viewStyle="bottom"
              control={forwardRef(({ onClick }, ref) => (<button className={styles.link} onClick={onClick} ref={ref}>Удалить учетную запись</button>))}
              title="Вы уверены что хотите удалить свою учетную запись?"
              buttons={[
                {
                  text: 'Удалить',
                  style: 'danger',
                  onClick: handleClickRemoveProfile
                },
                {
                  action: 'close',
                  text: 'Отмена',
                  style: 'secondary',
                }
              ]}
            >
              <div className="text-center">Действие невозможно будет отменить!</div>
            </Modal>
          </div>
        )}
        {user && <div className={styles.userUid}>Пользователь ID {user.uid}</div>}
      </Block>

      {count > 10 && (
        <div className={styles.removeButton}>
          <button className={styles.link} onClick={handleClickSignOut}>Выйти</button>
        </div>
      )}
    </MainLayout>
  );
}

export default Update;
