import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { MainLayout } from 'components/Layouts';
import LessonsSchedule from 'components/schedule/LessonsSchedule';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';

import styles from './Schedule.module.css';

const LessonsSchedulePage = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  // const currentPlayer = user.current_player ? user.current_player : null;
  const [ date, setDate ] = useState(null);

  // const handleChangeDay = (day, lessons) => {
  //   const data = {
  //     user_uid: currentPlayer.uid_gamer,
  //     day: day,
  //     lessons: lessons
  //   };

  //   api
  //     .post(`/setlessonshedule/`, JSON.stringify(data))
  //     .then(response => {
  //       const result = response.data.response;

  //       if (result) {
  //         dispatch(setItem(['lessons_schedule', currentPlayer.uid_gamer], result, { isHard: true }));
  //         dispatch(setItem(['notifications', 'updateSchedule'], { type: 'success', content: 'Сохранено' }));
  //       }
  //       if (response.data.error) {
  //         dispatch(setItem(['notifications', 'updateSchedule'], { type: 'error', content: response.data.error }));
  //       }
  //     })
  //     .catch(error => {
  //       dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
  //     });
  // };

  const handleClickItem = (uid, date, type) => {
    navigate(`/lessons-schedule-update/${date}/${uid}?type=${type}`);
  };

  if (!user) return null;

  return (
    <MainLayout
      title="Расписание"
      rightButton={
        <Button
          className={styles.addButton}
          butStyle="secondary"
          size="medium"
          icon={<ReactSVG src="/icons/plus.svg" wrapper="span" />}
          to="/lessons-schedule-add"
          state={{ date }}
        >
          Добавить
        </Button>
      }
      showBackButton={false}
    >
      <LessonsSchedule
        onChangeDay={setDate}
        // onChangeDay={handleChangeDay}
        onClickItem={handleClickItem}
      />

      {/* <ButtonsContainer>
        <Button
          butStyle="primary"
          disabled={!lessonsPlayer?.length}
          to="/lessons-schedule"
        >
          Далее
        </Button>
      </ButtonsContainer> */}
    </MainLayout>
  );
};

export default LessonsSchedulePage;
