// import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
// import Button from 'components/Button';

import styles from './header.module.css';

const Avatar = ({ user, href, onClick, active, index }) => {
  const name = user.info ? user.info.name : user.name;
  let avatar = user.info ? user.info.avatar : user.avatar;
  if (!avatar) avatar = `/icons/user-filled.svg`;

  return (
    <Link to={href} className={classNames(styles.profile, active && styles.active)} onClick={onClick}>
      <div className={styles.profileImageContainer} id={index === 0 ? 'first_player' : null}>
        <img src={avatar} className={styles.profileImage} alt="" />
      </div>
      <div className={styles.profileName}>{name}</div>
    </Link>
  );
};

const HeaderPlayer = ({ user }) => {
  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        {/* <div className={styles.top}>
          <Button
            butStyle="text"
            icon={<ReactSVG src="/icons/user-small.svg" wrapper="span" />}
            className={styles.userButton}
            iconClassName={styles.userButtonIcon}
            to="/update"
          >
            {user.name}
          </Button>
        </div> */}
        <div className={classNames(styles.users, 'scroll-list')}>
          <div className={styles.usersItem}>
            <Avatar
              user={user}
              href="/update"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderPlayer;
