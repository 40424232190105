import { useSelector, useDispatch } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import ButtonsContainer from 'components/ButtonsContainer';
// import Button from 'components/Button';
import HomeworkSchedule from 'components/homework/HomeworkSchedule';
import { MainLayout } from 'components/Layouts';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import * as api from 'helpers/api';

// import styles from './Homework.module.css';

const HomeworkSchedulePage = () => {
  const dispatch = useDispatch();
  // const { state } = useLocation();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;

  const { data: homework } = useApiQuery({
    name: ['homework', currentPlayer.uid_gamer],
    path: `/getuserdomashka/${currentPlayer.uid_gamer}/`,
    skip: !user || !currentPlayer
  });

  const handleChangeSchedule = (day, time_uid, checked, start, end) => {
    const fd = new FormData();
    fd.append('user_uid', currentPlayer.uid_gamer);
    fd.append('day', day);
    fd.append('start', start);
    fd.append('end', end);
    if (time_uid) fd.append('uid', time_uid);
    if (!checked) fd.append('delete', 1);

    api
      .post(`/getuserdomashka/`, fd)
      .then(response => {
        const result = response.data.response;

        if (result) {
          dispatch(setItem(['homework', currentPlayer.uid_gamer], result, { isHard: true }));
          dispatch(setItem(['notifications', 'updateSchedule'], { type: 'success', content: 'Сохранено' }));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'updateSchedule'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  // const handleClickNext = () => {
  //   dispatch(pushItem(['onboarding'], {
  //     key: 'rewards',
  //     image: <img src="/images/onboarding/rewards.svg?v=2" alt="" />,
  //     style: {
  //       top: '70px',
  //       right: '0px',
  //       left: 'auto',
  //       bottom: 'auto'
  //     },
  //   }));
  // };

  return (
    <MainLayout title="Домашка" description="Настройте время дня, когда ребенку нужно начать и закончить выполнять школьные ДЗ">
      {homework && (
        <HomeworkSchedule
          items={homework}
          onChange={handleChangeSchedule}
        />
      )}

      {/* {(state?.onboarding && homework?.main && Object.values(homework.main).find(({ time }) => !!time.uid)) && (
        <div className={styles.onboardButtons}>
          <ButtonsContainer className="container">
            <Button
              butStyle="secondary"
              to="/select-success"
            >
              Назад
            </Button>
            <Button
              butStyle="primary"
              to="/update-rewards"
              // state={{ onboarding: true }}
              // onClick={handleClickNext}
            >
              Далее
            </Button>
          </ButtonsContainer>
          </div>
      )} */}
    </MainLayout>
  );
};

export default HomeworkSchedulePage;
