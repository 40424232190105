import { Link } from 'react-router-dom';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';

import styles from './schedule-home.module.css';

const ScheduleHomePlaceholder = ({ user, schedule }) => {
  const needChange = (Object.values(schedule).findIndex(({ lessons }) => !!lessons.length) === -1 && (user.type === 'M' || user.type === 'S'));
  return (
    <div className={styles.root}>
      <Link to={needChange ? '/player-lessons-update' : '/lessons-schedule'} className={styles.link} />
      <div className={styles.text}>
        Вовремя напоминает о планах на день
      </div>
      <ButtonsContainer className={styles.buttons}>
        {needChange ? (
          <Button
            butStyle="secondary"
            size="medium"
            fullWidth
          >
            Настроить
          </Button>
        ) : (
          <Button
            butStyle="primary"
            size="medium"
            fullWidth
          >
            Перейти
          </Button>
        )}
      </ButtonsContainer>
    </div>
  );
};

export default ScheduleHomePlaceholder;
