import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { format } from 'date-fns';
import { MainLayout } from 'components/Layouts';
import Block from 'components/Block';
// import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import HeaderMentor from 'components/header/HeaderMentor';
import TariffNotification from 'components/tariffs/TariffNotification';
import HomeworkHome from 'components/homepage/HomeworkHome';
import ScheduleHome from 'components/homepage/ScheduleHome';
import PortfailHome from 'components/homepage/PortfailHome';
import ShareBlock from 'components/homepage/ShareBlock';
import HomeworkDeals from 'components/homepage/HomeworkDeals';
import LoginModal from 'components/modals/LoginModal';
import LoginPlayer from 'components/modals/LoginPlayer';
import Modal from 'components/Modal';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import getCurrentHost from 'helpers/getCurrentHost';
import copyToClipboard from 'helpers/copyToClipboard';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';

import styles from './mentor.module.css';

const UserQuery = ({ uid }) => {
  useApiQuery({
    name: ['homework', uid],
    path: `/getuserdomashka/${uid}/`
  });

  useApiQuery({
    name: ['homework_tasks', uid],
    path: `/gettasksofuser/${uid}/`
  });

  useApiQuery({
    name: ['lesson_schedule', uid],
    path: `/getlessonshedule/${uid}/`
  });

  useApiQuery({
    name: ['portfail', uid],
    path: `/getuserportfail/${uid}/`
  });

  return null;
}

const Home = () => {
  const loginRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [ isShowLogin, setShowLogin ] = useState(false);
  const [ isShowSuccess, setShowSuccess ] = useState(false);

  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const uid = currentPlayer?.uid_gamer;
  const info = currentPlayer?.info;

  const pin_code = useSelector(selectItem(['pin_code']));
  const settings = useSelector(selectItem(['settings']));
  const homework = useSelector(selectItem(['homework', uid]));
  const homeworkTasks = useSelector(selectItem(['homework_tasks', uid]));
  const schedule = useSelector(selectItem(['lesson_schedule', uid]));
  const portfail = useSelector(selectItem(['portfail', uid]));

  useEffect(() => {
    if (hash === '#success-pay') setShowSuccess(true);
  }, [hash]);

  const { data: homeworkDeals, reset: resetDeals } = useApiQuery({
    name: ['homework_deals', uid],
    path: `/getuserhomeworksviget/${uid}/`,
    skip: !uid
  });

  useApiQuery({
    name: ['pin_code'],
    path: `/getpincode/${user.uid}/`,
    skip: !user
  });

  const handleDoneDeal = (uid, returned) => {
    const formData = new FormData();
    formData.append('params[deal_uid]', uid);
    formData.append('params[user_uid]', uid);
    formData.append('params[returned_type]', returned);
    formData.append('params[is_done]', true);

    api.post('/getuserhomeworksviget/', formData).then(() => resetDeals());
  };

  const handleClickCopy = async (key) => {
    const url = `${getCurrentHost()}/auth/${key}`;

    if (settings?.user_agent === 'timer-mobile') {
      copyToClipboard(url);
    } else {
      try {
        await navigator.share({
          title: 'Me Timer: Войти в приложение',
          text: `Привет! Скачивай приложение, чтобы сделать домашку и получить свои первые кристаллы в качестве награды! \n \n 1. Скачивай приложение по ссылке https://metimer.ru/api/devicedetecter/ \n 2. После того, как скачаешь приложение, переходи по этой ссылке, чтобы авторизоваться - ${url} \n 3. Разреши уведомления, чтобы выполнять задания вовремя и получать кристаллы!`
        });
      } catch (err) {
        const result = copyToClipboard(url);
        if (result) {
          dispatch(setItem(['notifications', 'copy_link'], { type: 'success', content: 'Ссылка для входа скопирована' }));
        } else {
          dispatch(setItem(['notifications', 'copy_link'], { type: 'error', content: 'Ошибка копирования' }));
        }
      }
    }

    setShowLogin(false);
  };

  const handleClickSignIn = () => {
    setShowLogin(true);
  };

  const handleClickLogin = (uid_gamer) => {
    setShowLogin(false);
    loginRef.current.login(uid_gamer)
  };

  return (
    <MainLayout>
      <HeaderMentor user={user} pin_code={pin_code} />
      {(user?.player) ? (
        <>
          <UserQuery uid={currentPlayer?.uid_gamer} />

          <Block className={styles.mainInfo}>
            {(currentPlayer && info?.tariff && !info?.tariff?.actual && settings?.pay_mobile_visible) &&
              <TariffNotification tariff={info.tariff} user_type={user.type} uid_gamer={currentPlayer.uid_gamer} />
            }

            {settings?.user_agent !== 'timer-mobile' && (
              <Button
                className={styles.downloadApp}
                butStyle="green"
                size="medium"
                href="https://metimer.ru/api/devicedetecter/"
                fullWidth
              >
                Скачать приложение
              </Button>
            )}

            <Button
              id="sign_as_player"
              butStyle="green"
              size="medium"
              onClick={() => handleClickSignIn(user.current_player.uid_gamer)}
              fullWidth
            >
              Передать доступ школьнику
            </Button>
          </Block>

          <Block
            id="schedule"
            title="Расписание"
            rightButton={
              (schedule && Object.values(schedule).findIndex(({ lessons }) => !!lessons.length) >= 0) && (
                <Button
                  butStyle="text"
                  to="/player-lessons-update"
                  icon={<ReactSVG src="/icons/settings.svg" wrapper="span" />}
                />
              )
            }
          >
            <ScheduleHome
              user={user}
              schedule={schedule}
            />
          </Block>

          <Block
            id="homework"
            title="Школьная Домашка"
            rightButton={
              (homework && Object.values(homework).findIndex(({ shedule }) => !!(shedule && shedule.uid)) >= 0) && (
                <Button
                  butStyle="text"
                  to="/homework-update"
                  icon={<ReactSVG src="/icons/settings.svg" wrapper="span" />}
                />
              )
            }
          >
            <HomeworkHome
              user={user}
              schedule={homework}
              isset_long={homeworkTasks?.isset_long}
              tasks={homeworkTasks?.tasks}
              settings={settings}
            />
          </Block>

          {/* {settings?.user_agent !== 'timer-mobile' && ( */}
            <Block
              id="briefcase"
              title="Портфель"
              rightButton={
                (portfail && Object.values(portfail).findIndex(({ units }) => (units && units.findIndex(({ checked }) => !!checked) !== -1)) !== -1) && (
                  <Button
                    butStyle="text"
                    to="/briefcase-update"
                    icon={<ReactSVG src="/icons/settings.svg" wrapper="span" />}
                  />
                )
              }
            >
              <PortfailHome
                user={user}
                items={portfail}
              />
            </Block>
          {/* )} */}

          <Block
            title="Дела на сегодня"
            rightButton={
              homeworkDeals?.length && (
                <Button
                  butStyle="text"
                  to="/lessons-schedule-add"
                  icon={<ReactSVG src="/icons/settings.svg" wrapper="span" />}
                />
              )
            }
          >
            <HomeworkDeals user={user} items={homeworkDeals} onDone={handleDoneDeal} />
          </Block>
        </>
      ) : (
        <Block>
          <img src="/images/add-players.svg" alt="" width="100%" />
        </Block>
      )}

      <ShareBlock />

      <LoginPlayer
        user={user}
        player={user.current_player}
        players={user.player}
        pin_code={pin_code}
        ref={loginRef}
      />
      {user?.current_player &&
        <LoginModal
          open={isShowLogin}
          player={user.current_player}
          onClose={() => setShowLogin(false)}
          onClickLogin={handleClickLogin}
          onClickCopy={handleClickCopy}
          user_agent={settings?.user_agent}
        />
      }

      <Modal
        open={isShowSuccess}
        title="Ваша подписка оформлена!"
        viewStyle="bottom"
        buttons={[
          {
            butStyle: 'primary',
            text: 'Ок',
            action: 'close',
            onClick: () => navigate('/')
          }
        ]}
      >
        {user?.tariff?.date_end && (
          <>Подписка действительна до {format(new Date(user?.tariff?.date_end), 'dd.MM.yyyy')}</>
        )}
      </Modal>
    </MainLayout>
  );
}

export default Home;
