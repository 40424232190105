import { useState, forwardRef, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import SelectPopup from 'components/fields/Select/components/SelectPopup';
import SelectIcon from './SelectIcon';

import selectStyles from './select2.module.css';
import styles from '../fields.module.css';

const findLabel = (options, value) => {
  if (options.length && (value || value === 0)) return options.find(item => String(item.value) === String(value))?.label;
  return null;
};

const Select2 = forwardRef(({ id, control, onChange, name, title, label, icon, fullWidth, value, modalTitle,
  error, hidden, notice, containerClass, iconClassName, submitText, options, inputClass, className, disabled }, ref) => {

  const [ tempValue, setTempValue ] = useState(value);
  const [ isPopupShow, setPopupShow ] = useState(false);
  const [ errorText, setErrorText ] = useState(null);

  const handleSelectClick = () => {
    setPopupShow(true);
  };

  const onCloseSelectPopup = () => {
    setPopupShow(false);
    setErrorText(false);
  };

  useEffect(() => {
    setTempValue(value);
  }, [value]);

  return (
    <div className={classnames(styles.container, fullWidth && styles.fullWidth, containerClass && styles[containerClass], hidden && styles.hidden)}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.inputContainer}>
        <SelectPopup
          label={label}
          title={title}
          modalTitle={modalTitle}
          isPopupShow={isPopupShow}
          setPopupShow={setPopupShow}
          id={id}
          name={name}
          options={options}
          tempValue={tempValue}
          errorText={errorText}
          setTempValue={setTempValue}
          onClose={() => onCloseSelectPopup()}
          buttons={[
            {
              className: selectStyles.backButton,
              butStyle: "tertiary",
              icon: <ReactSVG src="/icons/arrow-left.svg" wrapper="span" />,
              action: 'close'
            },
            {
              text: submitText ? submitText : 'Выбрать',
              action: 'click',
              style: 'primary',
              disabled: !tempValue,
              onClick: () => {
                setPopupShow(false);
                onChange({ target: { name: name, value: tempValue, label: findLabel(options, tempValue) } });
              }
            }
          ]}
        />
        <input
          type="hidden"
          id={id}
          name={name}
          value={value}
          readOnly={true}
          ref={ref}
        />
        <div
          onClick={handleSelectClick}
          onFocus={handleSelectClick}
          className={classnames('input', styles.input, selectStyles.input, icon && styles.withIcon, inputClass, className, error && 'error', value && 'filled', disabled && 'disabled')}
        >
          {label}
        </div>
        <SelectIcon label={findLabel(options, value)} />
        {icon &&
          <span className={classnames(styles.icon, selectStyles.icon, iconClassName)}>{ icon }</span>
        }
      </div>
      {error &&
        <span className={styles.errorText}>{ error }</span>
      }
      {notice &&
        <span className={styles.notice}>{ notice }</span>
      }
    </div>
  );
});

export default Select2;