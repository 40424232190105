import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import TabBar from 'components/TabBar';
import FeedbackPopup from 'components/modals/FeedbackPopup';
import BottomFaq from 'components/modals/BottomFaq';
import { selectItem } from 'store/app/getters';
import { player_menu, mentor_menu, teenager_menu, mentor_short_menu } from 'data/menu';
import BackButton from './BackButton';

import styles from './main-layout.module.css';

const MainLayout = ({ children, contentValign, showBottomMenu = true,
  title, subtitle, description, showBackButton = true, backButtonUrl, rightButton
}) => {
  const [ isSticked, setSticked ] = useState(false);
  const user = useSelector(selectItem(['user']));
  const { pathname } = useLocation();
  const isMentorPage = pathname === '/' || pathname === '/update';


  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop } = document.documentElement;
    setSticked((scrollHeight - 100) < (scrollTop + window.innerHeight));
  };

  return (
    <div className={styles.container}>
      {title && (
        <div className={classNames(styles.header, 'container')}>
          {showBackButton &&
            <div className={styles.headerButtons}>
              <BackButton returnUrl={backButtonUrl} />
            </div>
          }
          <h1 className={styles.title}>
            {title}
            {rightButton}
          </h1>
          {subtitle &&
            <div className={styles.subtitle}>{subtitle}</div>
          }
        </div>
      )}
      {description && (
        <div className={classNames(styles.description, 'container')}>
          {description}
        </div>
      )}
      <div className={classNames(styles.content, 'container', contentValign && styles['align-' + contentValign], showBottomMenu && styles.withMenu)}>
        {children}
      </div>
      {showBottomMenu && (
        <div className={classNames(styles.bottom, isSticked && styles.sticked)}>
          {user.type === 'M' && <TabBar menu={isMentorPage ? mentor_short_menu : mentor_menu} />}
          {user.type === 'S' && <TabBar menu={teenager_menu} />}
          {user.type === 'P' && <TabBar menu={player_menu} />}
        </div>
      )}
      <FeedbackPopup />
      <BottomFaq />
    </div>
  );
};

export default MainLayout;