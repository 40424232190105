import { Link } from 'react-router-dom';
import classNames from 'classnames';
import format from 'date-fns/format';
import { ru } from 'date-fns/locale';
// import Button from 'components/Button';

import styles from './homework-item.module.css';

const HomeworkListItem = ({ id, date, for_date, user, status, settings, items, statusText, statusCode, bigReward }) => {
  if (!items) return null;

  // let buttonStyle = 'button';

  // if (statusCode === 'O' || statusCode === 'W') buttonStyle = 'error';
  // else if (statusCode === 'A') buttonStyle = 'success';

  /*
  $GLOBALS["domashka_task_status"]["O"] = "Просрочено";
  $GLOBALS["domashka_task_status"]["A"] = "Выполняется";
  $GLOBALS["domashka_task_status"]["W"] = "Еще не началось";
  $GLOBALS["domashka_task_status"]["N"] = "Провалено";
  $GLOBALS["domashka_task_status"]["C"] = "Выполнено";
  */

 // const reward = doneDate  > endDate ? settings?.domashka_small_crystall : settings?.domashka_std_crystall;
 // buttonText = user.type === 'M' ? `За получено ${reward} <img src="/images/crystal.svg" alt="" />` : `За это ты получил ${reward} <img src="/images/crystal.svg" alt="" />`;

  // if (status === 'O' || status === 'N') {
  //   buttonStyle = 'danger';
  // } else if (status === 'A' || status === 'W') {
  //   buttonStyle = 'success';
  // } else if (status === 'C') {
  //   buttonStyle = 'text';
  // }

  return (
    <div className={styles.root}>
      {/* {accur ? (
        <span className={styles.accur}>
          +{settings?.domashka_accur_crystall_bonus} <img src="/images/crystal.svg" alt="" />
          <br /> За аккуратность
        </span>
      ) : null} */}
      {/* <Link to={`/homework/${date}/${uid}`} className={styles.link} /> */}
      <div className={styles.header}>
        <div className={styles.title}>
          На {format(new Date(for_date), 'EEEE', {locale: ru }).replace(/[а]$/, 'y')}
          <br /> {format(new Date(for_date), 'dd.MM.yyyy')}
        </div>
        <div className={styles.subtitle}>
          Сделать до {format(new Date(date.replace(' ', 'T')), 'EEEE', {locale: ru }).replace(/[е]$/, 'я').replace(/[а]$/, 'ы').replace(/[к]$/, 'ка').replace(/[г]$/, 'га')}
          {/* <br /> {format(new Date(date), 'dd.MM.yyyy HH:mm')} */}
        </div>
      </div>
      <div className={styles.lessons}>
        {items.map(({ uid, date, lesson_name, is_done, is_rejected, is_complete }, index) => {
          let statusText = 'В работе';
          let statusStyle = 'lessonButton';

          if (is_done) {
            statusText = user.type === 'M' ? 'Проверено' : 'Выполнено';
            statusStyle = 'lessonSuccess';
          } else if (is_rejected) {
            statusText = user.type === 'M' ? 'В работе' : 'Переделать';
            statusStyle = 'lessonButton';
          } else if (is_complete) {
            statusText = user.type === 'M' ? 'Проверить' : 'На проверке';
            statusStyle = 'lessonDanger';
          }

          return (
            <Link to={`/homework/${id}/${uid}`} className={styles.lesson} key={index}>
              <div className={styles.lessonLabel}>{lesson_name}</div>
              <div className={classNames(styles.lessonValue, styles[statusStyle])}>
                <span>{statusText}</span> {(is_done && user.type !== 'M') && <img src="/icons/checkmark-color.svg" alt="" width="24" height="24" />}
              </div>
            </Link>
          );
        })}
      </div>
      {/* <div className={styles.buttons}>
        <Button
          className={classNames(styles.button, styles[buttonStyle])}
          butStyle={buttonStyle === 'button' ? 'primary' : 'text'}
          size="medium"
          fullWidth
        >
          <span dangerouslySetInnerHTML={{ __html: statusText }} />
          {(bigReward && user.type !== 'M') ? (
            <span className={styles.buttonReward}>
              Сделай сейчас и получи <b>+{bigReward} <img src="/images/crystal.svg" width={10} height={12} alt="" /></b>
            </span>
          ) : null}
        </Button>
      </div> */}
    </div>
  );
};

export default HomeworkListItem;
