import { ReactSVG } from 'react-svg';
import classnames from 'classnames';

import styles from './players-list.module.css';

const Position = ({ position }) => {
  if (position > 3) {
    return <>{position}</>;
  } else if (position === 3) {
    return <ReactSVG src="/icons/position-third.svg" />;
  } else if (position === 2) {
    return <ReactSVG src="/icons/position-second.svg" />;
  } else if (position === 1) {
    return <ReactSVG src="/icons/position-first.svg" />;
  };

  return null;
}

const PlayerItem = ({ player }) => {
  const { name, surname, sum_crystal, avatar, rank } = player;

  const photo = avatar || '/icons/user-filled.svg';

  return (
    <div className={styles.player}>
      <div className={styles.position}>
        <Position position={rank} />
      </div>
      <div className={styles.image}>
        <img src={photo} alt="" />
      </div>
      <div className={styles.content}>
        <div className={styles.name}> {name} {surname} </div>
        {!!sum_crystal &&
          <div className={styles.rating}>
            {sum_crystal} <img src="/images/crystal.svg" className={styles.crystal} alt="" />
          </div>
        }
      </div>
    </div>
  );
};

const PlayersList = ({ players, current }) => {
  if (!players) return null;

  return (
    <div className={styles.list}>
      {players.map(player =>
        <div className={classnames(styles.listItem, Number(player.uid) === Number(current) && styles.active)} key={player.uid}>
          <PlayerItem player={player} />
        </div>
      )}
    </div>
  );
};

export default PlayersList;