import { useParams } from 'react-router-dom';
import { MainLayout } from 'components/Layouts';
import useApiQuery from 'hooks/useApiQuery';

import styles from './Maskot.module.css';
import Button from 'components/Button';

const Maskot = () => {
  const { uid } = useParams();

  const { data: maskot } = useApiQuery({
    name: ['maskot'],
    path: `/maskot/${uid}/`,
  });

  console.log(maskot);

  if (!maskot) return null;

  return (
    <MainLayout contentValign="stretch" showBottomMenu={false}>
      <div className={styles.root}>
        <img className={styles.image} src={maskot.image} alt="" />
        <div className={styles.content}>
          <span dangerouslySetInnerHTML={{ __html: maskot.text }}></span>
          <div className={styles.buttons}>
            <Button
              butStyle="primary"
              href={maskot.href ?? '/'}
              fullWidth
            >
              <span dangerouslySetInnerHTML={{ __html: maskot.button }} />
            </Button>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Maskot;
