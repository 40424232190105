import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Switch from 'components/fields/Switch';
import PlayersList from 'components/PlayersList';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';

const Rating = () => {
  const user = useSelector(selectItem(['user']));
  const [ type, setType ] = useState('');

  const tabs = [
    {
      label: 'В России',
      value: ''
    },
    {
      label: 'В классе',
      value: 'class'
    },
    {
      label: 'В школе',
      value: 'school'
    }
  ];

  const { data: ratingAll } = useApiQuery({
    name: ['rating_all', user.uid],
    path: `/ratingschoolclass/${user.uid}/`,
    skip: !user,
    mapper: (data = {}) => {
      if (!data.rating) return null;
      const next = Object.keys(data.rating).reduce((acc, current) => ({
        ...acc,
        [current]: data.rating[current].map(item => ({ ...item, rank: current === 'class' ? item.rank_class : item.rank_school }))
      }), {});
      return next;
    }
  });

  const { data: rating } = useApiQuery({
    name: ['rating', user.uid],
    path: `/ratingschoolclass/${user.uid}/${user.school}/${user.class}/${user.class_sign}/`,
    skip: !user || !user.school,
    mapper: (data = {}) => {
      if (!data.rating) return null;
      const next = Object.keys(data.rating).reduce((acc, current) => ({
        ...acc,
        [current]: data.rating[current].map(item => ({ ...item, rank: current === 'class' ? item.rank_class : item.rank_school }))
      }), {});
      return next;
    }
  });

  if (!user) return null;

  const onChangeTab = (e) => {
    setType(e.target.value);
  };

  return (
    <MainLayout title="Мой недельный рейтинг" showBackButton={false}>
      {user.school ? (
        <>
          <Switch
            id="school"
            name="school"
            options={tabs}
            value={type}
            view="radio"
            onChange={(e) => onChangeTab(e)}
            inputClass="dark"
          />
          {(rating && ratingAll) &&
            <PlayersList
              players={type === '' ? [...ratingAll?.school].sort((a, b) => a.rank - b.rank) : rating[type].sort((a, b) => a.rank - b.rank)}
              current={user.uid}
            />
          }
        </>
      ) : (
        <>
          <div className="text-center" style={{ marginTop: '60px' }}>Заполните аккаунт чтобы просматривать рейтинг</div>
          <ButtonsContainer>
            <Button
              to="/update"
              butStyle="primary"
              fullWidth
            >
              В настройки
            </Button>
          </ButtonsContainer>
        </>
      )}
    </MainLayout>
  );
};

export default Rating;
