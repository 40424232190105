import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';

import styles from './homework.module.css';

const HomeworkPlaceholder = ({ user, isSetted }) => {
  if (!isSetted) {
    if (user.type === 'M') return (
      <div className={styles.root}>
        <Link to="/homework-update" className={styles.link} />
        <div className={styles.text}>
          Контроль выполнения школьной домашки
        </div>

        <ButtonsContainer className={styles.buttons}>
          <Button
            to="/homework-update"
            butStyle="secondary"
            size="medium"
            fullWidth
          >
            Настроить
          </Button>
        </ButtonsContainer>
      </div>
    );

    return (
      <div className={classNames(styles.root, styles.empty)}>
        Попроси родителя настроить домашку, чтобы получать кристаллы!
        <div style={{ textAlign: 'right', margin: '-25px -3px -25px 0', lineHeight: 0 }}>
          <img src="/images/corgi_3.png" width={120} alt="" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      {/* <Link to={info ? `/homework/${info.date}` : '/homework-update'} className={styles.link} /> */}
      <div className={styles.text}>На сегодня домашних заданий нет</div>
    </div>
  );
};

export default HomeworkPlaceholder;