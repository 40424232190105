import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import NoItems from 'components/NoItems';
// import Button from 'components/Button';
import Checkbox from 'components/fields/Checkbox';

import styles from './shop-update.module.css';

const ShopUpdate = ({ items = [1, 2, 3, 4], onCheckItem, onClickCustom }) => {
  if (!Object.keys(items).length) return ( // no items
    <NoItems children="Нет назначенных наград" />
  );

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        Идеи для наград
      </div>
      {Object.values(items).map(({ name, units }, index) => (
        <div className={styles.row} key={index}>
          <div className={styles.rowTitle}>{name}</div>
          <div className={classNames(styles.list, 'scroll-list')}>
            {units?.map(({ uid, name, price, url, is_checked }) => {
              if (!uid) return (
                <div className={styles.item} key={`${index}_${uid}`}>
                  <div className={classNames(styles.imageWrapper, 'skeleton-loader')} />
                  <div className={styles.content}>
                    <div className={classNames(styles.title, 'skeleton-loader')} />
                    <div className={classNames(styles.price, 'skeleton-loader')} />
                  </div>
                </div>
              );
              return (
                <div className={styles.item} key={uid}>
                  <div className={styles.imageWrapper}>
                    <img className={classNames(styles.image, !is_checked && styles.disabled)} src={url} alt={name} />
                  </div>
                  <div className={styles.content}>
                    <div className={styles.name}>
                      {name}
                    </div>
                    <div className={styles.price}>
                      <div className={styles.priceValue}>
                        <ReactSVG src="/images/crystal.svg" className={styles.priceIcon} wrapper="span" />
                        {price}
                      </div>
                      <Checkbox
                        id={uid}
                        name={uid}
                        checked={is_checked}
                        containerClass={classNames(styles.checkbox, is_checked && styles.checked)}
                        onChange={() => onCheckItem(uid)}
                      />
                    </div>
                  </div>
                  {/* <div className={styles.buttons}>
                    <Button
                      className={classNames(styles.button, is_checked && styles.active)}
                      butStyle={is_checked ? 'lightBlue' : 'quaternary'}
                      size="small"
                      onClick={() => onCheckItem(uid)}
                      fullWidth
                    >
                      {is_checked ? 'Выбрано' : 'Выбрать'}
                    </Button>
                  </div> */}
                </div>
              );
            })}
            {units?.length && (
              <div className={styles.item}>
                <div className={styles.imageWrapper} onClick={onClickCustom}>
                  <div className={styles.imageText}>
                    Свой вариант
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ShopUpdate;