import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { ReactSVG } from 'react-svg';
import { ru } from 'date-fns/locale';
import Modal from 'components/Modal';
import { TimePicker } from '../TimePicker';

import styles from './modal-select-date.module.css';

registerLocale('ru', ru);

const ModalSelectDate = ({ name, isOpen, onClose, showDatePicker, showTimePicker, onSave, defaultValue, minDate, maxDate, timeStep }) => {
  const [ value, setValue ] = useState(new Date(defaultValue ? defaultValue.replace(' ', 'T') : (minDate ? minDate.replace(' ', 'T') : '')));

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      viewStyle="bottom"
      closeOnDocumentClick={false}
      buttons={[
        {
          className: styles.backButton,
          butStyle: "tertiary",
          icon: <ReactSVG src="/icons/arrow-left.svg" wrapper="span" />,
          action: 'close'
        },
        {
          butStyle: "primary",
          text: 'Сохранить',
          onClick: () => onSave(value),
          disabled: !value,
          action: 'close'
        }
      ]}
    >
      <div className={styles.modal}>
        {showDatePicker && (
          <div className={styles.datePicker}>
            <DatePicker
              name={name}
              selected={value}
              onChange={setValue}
              minDate={minDate ? new Date(minDate.replace(' ', 'T')) : null}
              maxDate={maxDate ? new Date(maxDate.replace(' ', 'T')) : null}
              locale="ru"
              inline
              calendarStartDay={1}
              dropdownMode="select"
              dateFormat="dd MMMM yyyy"
            />
          </div>
        )}
        {showTimePicker && (
          <div className={styles.timePicker}>
            <TimePicker
              name={name}
              timeStep={timeStep}
              minDate={minDate ? new Date(minDate.replace(' ', 'T')) : null}
              maxDate={maxDate ? new Date(maxDate.replace(' ', 'T')) : null}
              onChange={setValue}
              value={value}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalSelectDate;
