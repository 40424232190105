import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { RegisterLayout } from 'components/Layouts';
import Form from 'components/Form';
import PhoneCode from 'components/fields/PhoneCode';
import Button from 'components/Button';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';

import styles from './account.module.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectItem(['user']));
  const verification = useSelector(selectItem(['verification']));

  const onPhoneSubmit = (result) => {
    if (!result.user || result.user.error) {
      dispatch(setItem(['notifications', 'login'], { type: 'error', title: 'Профиль не найден', content: 'Чтобы пользоваться сервисом пройдите регистрацию.' }));
      dispatch(setItem(['verification'], {}, {isHard: true}));
      return null;
    }

    dispatch(setItem(['user'], result.user));
  };

  const inputs = [
    { id: 'phone', name: 'phone', component: PhoneCode, validation: 'phone',
      props: { label: 'Введите ваш номер телефона', placeholder: '+7', fullWidth: true, mask: '+7 ### ### ## ##', type: 'tel',
        onPhoneSubmit: onPhoneSubmit, path: '/auth'
      }
    },
  ];

  useEffect(() => {
    if (user && user.uid) {
      if (!user.name) navigate('/create-player');
      else navigate('/');
    }

    // eslint-disable-next-line
  }, [user]);

  const onFormSubmit = data => {
    if (!verification.isCodeSended) dispatch(setItem(['verification', 'sendCode'], true));
    if (!verification.isCodeValid) return null;
  };

  return (
    <RegisterLayout showLogo>
      <h1 className={styles.title}>Авторизация</h1>
      <div className={styles.subtitle}>
        {verification.isCodeSended ? (
          <>
            Проверьте код из смс, он придет на номер
            <div style={{ marginTop: '5px' }}><b className='accent-text'>{verification.checkingPhone}</b></div>
          </>
        ) : (
          <>
            Введите номер телефона, а затем код из смс
          </>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.regLogin}>
          <Form
            inputs={inputs}
            onSubmit={onFormSubmit}
            buttons={
              !verification.isCodeSended && (
                <>
                  <Button
                    type="submit"
                    butStyle="primary"
                    fullWidth
                  >
                    Войти
                  </Button>
                  <Button
                    to="/register"
                    butStyle="secondary"
                    fullWidth
                  >
                    Зарегистрироваться
                  </Button>

                  <div className={styles.terms}>
                    Нажимая кнопку ”<span className="accent-text">Войти</span>” <br /> вы соглашаетесь
                    с <a href="https://me-timer.ru/docs/license.pdf">политикой обработки персональных данных</a> и
                    принимаете <a href="https://me-timer.ru/docs/oferta.pdf">условия оферты сервиса</a>
                  </div>
                </>
              )
            }
          />
        </div>
        {verification.isCodeSended && (
          <div className="form-buttons">
            <Button
              type="reset"
              butStyle="tertiary"
              icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
              onClick={() => dispatch(setItem(['verification', 'isCodeSended'], false))}
              className={styles.buttonBack}
            >
            </Button>
          </div>
        )}
      </div>
    </RegisterLayout>
  );
}

export default Login;
