import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { RegisterLayout } from 'components/Layouts';
import Form from 'components/Form';
import Input from 'components/fields/Input';
// import Select from 'components/fields/Select';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import * as api from 'helpers/api';

import styles from './account.module.css';

const CreateMentor = () => {
  const [ isSubmitActive, setSubmitActive ] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const navigate = useNavigate();
  const tokens = useSelector(selectItem(['tokens']));

  let locationOpts = [];
  if (user.location) {
    locationOpts.push({
      label: user.location,
      value: `${user.gmt}_${user.location}`
    });
  }

  const inputs = [
    { id: 'name', name: 'name', component: Input, validation: 'string',
      props: {
        label: 'Имя',
        value: user.name || '',
        placeholder: 'Александр',
        fullWidth: true,
        autoFocus: true
      }
    },
    // { id: 'location', name: 'location', component: Select, validation: 'select',
    //   props: {
    //     label: 'Ваш город', placeholder: 'Москва', value: `${user.gmt}_${user.location}`, query: user.location, options: locationOpts,
    //     searchPlaceholder: 'Например, "Москва"', fullWidth: true, isSearchable: true,
    //     loadUrl: '/getcity', mapper: (data) => data.map(({ gmt, name }) => ({ label: name, value: `${gmt}_${name}` }))
    //   }
    // },
  ];

  const onSubmit = (data) => {
    const fd = new FormData();
    for (let key in data) {
      fd.append(`params[${key}]`, data[key]);
    }

    if (tokens && Object.keys(tokens).length) {
      for (let key in tokens) {
        const token = tokens[key];
        fd.append(`params[${token.key}]`, token.value);
      }
    }

    // const [ gmt, location ] = data.location.split('_');

    // fd.append('params[gmt]', gmt);
    // fd.append('params[location]', location);
    fd.append(`params[type]`, 'M');

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          dispatch(setItem(['user'], result.user));
          navigate('/');
          // navigate('/create-player');
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <RegisterLayout showLogo>
      <h1 className={styles.title}>Родитель</h1>
      {/* <div className={styles.subtitle}>Осталось пройти еще пару шагов, это займет меньше минуты =)</div> */}
      <div className={styles.content}>
        <Form
          inputs={inputs}
          onSubmit={onSubmit}
          onValidChange={setSubmitActive}
          buttons={
            <>
              <div>
                <Button
                  butStyle="tertiary"
                  onClick={() => window.history.length && navigate(-1)}
                  icon={<ReactSVG src="/icons/arrow-left.svg" wrapper="span" />}
                  className={styles.buttonBack}
                />
              </div>
              <Button
                type="submit"
                butStyle="primary"
                disabled={!isSubmitActive}
              >
                Далее
              </Button>
            </>
          }
        />
      </div>
    </RegisterLayout>
  );
}

export default CreateMentor;
