import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Dropdown from 'components/Dropdown';
import Modal from 'components/Modal';
import Input from 'components/fields/Input';
import ModalSubscribe from 'components/modals/ModalSubscribe';

import styles from './portfail-update.module.css';

const AddCustomButton = ({ user, onClick, customLessonUid }) => {
  const [ customName, setCustomName ] = useState('');
  const [ isShowCustom, setShowCustom ] = useState(false);
  const [ isShowSubscribe, setShowSubscribe ] = useState(false);

  const handleClickCustom = () => {
    if (user?.tariff?.actual) setShowCustom(true);
    else setShowSubscribe(true);
  };

  return (
    <>
      <div className={classNames(styles.item, styles.add)} onClick={handleClickCustom} tabIndex="0">
        Добавить вариант <ReactSVG className={styles.addIcon} src="/icons/plus.svg" wrapper="span" />
      </div>
      <ModalSubscribe open={isShowSubscribe} onClose={() => setShowSubscribe(false)} />
      <Modal
        open={isShowCustom}
        onClose={() => { setShowCustom(false); setCustomName(''); }}
        viewStyle="bottom"
        title="Введите название"
        buttons={[
          {
            butStyle: 'primary',
            disabled: customName.length < 3,
            text: 'Сохранить',
            action: 'close',
            onClick: () => onClick(0, customName, customLessonUid)
          }
        ]}
      >
        <input type="hidden" autoFocus />
        <Input
          value={customName}
          onChange={e => setCustomName(e.target.value)}
          placeholder="Ручка"
        />
      </Modal>
    </>
  );
};

const PortfailUpdate = ({ user, items, onClickItem }) => {
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Каждый день</h2>
      <Dropdown title="Ко всем предметам" className={styles.dropdown}>
        <div className={styles.list}>
          {items && items[0].units?.map(({ uid, name, checked }) => {
            return (
              <div
                className={classNames(styles.item, checked && styles.active)}
                onClick={() => onClickItem(0, name, items[0].user_less_uid, checked && uid)}
                tabIndex="0"
                key={uid}
              >
                {name}
              </div>
            );
          })}
          <AddCustomButton user={user} customLessonUid={items && items[0].user_less_uid} onClick={onClickItem} />
        </div>
      </Dropdown>
      <h2 className={styles.title}>По предметам</h2>
        {items && Object.values(items).map(({ uid, name, units, user_less_uid }) => {
          if (uid === 0) return null;
          return (
            <Dropdown title={name} className={styles.dropdown} key={uid}>
              <div className={styles.list}>
                {units?.map(({ uid: itemUid, name, checked }) => {
                  return (
                    <div
                      className={classNames(styles.item, checked && styles.active)}
                      onClick={() => onClickItem(uid, name, user_less_uid, checked && itemUid)}
                      tabIndex="0"
                      key={itemUid}
                    >
                      {name}
                    </div>
                  );
                })}
                <AddCustomButton user={user} customLessonUid={user_less_uid} onClick={onClickItem} />
              </div>
            </Dropdown>
          );
        })}
    </div>
  );
};

export default PortfailUpdate;
