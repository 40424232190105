import classNames from 'classnames';
import PortfailHomePlaceholder from './PortfailHomePlaceholder';

import styles from './portfail-home.module.css';

const PortfailHome = ({ user, items }) => {
  if (!items) return (
    <div className={classNames(styles.root, 'skeleton-loader')} />
  );

  return (
    <PortfailHomePlaceholder user={user} items={items} />
  );
};

export default PortfailHome;
