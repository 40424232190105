import { useState, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
// import { ReactSVG } from 'react-svg';
import * as api from 'helpers/api';
import useApiQuery from 'hooks/useApiQuery';
import { MainLayout } from 'components/Layouts';
import Form from 'components/Form';
import Input from 'components/fields/Input';
import Select from 'components/fields/Select';
import Checkbox from 'components/fields/Checkbox';
// import DateInput from 'components/fields/DateInput';
import Button from 'components/Button';
import UserHeader from 'components/UserHeader';
import Modal from 'components/Modal';
import { setItem, sliceItem, deleteItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
// import standartPhone from 'helpers/standartPhone';
// import { filterByKey } from 'helpers/keyBy';

import { grades, letters } from 'data/mock';

import styles from './mentor.module.css';

const UpdatePlayer = () => {
  let { uid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mentor = useSelector(selectItem(['user']));
  const [ userLocation, setUserLocation ] = useState(null);
  const [ isSubmitActive, setSubmitActive ] = useState(false);

  if (!uid && mentor && mentor.current_player) uid = mentor.current_player.uid_gamer;

  const { data: player, status, error } = useApiQuery({
    name: ['players', uid],
    path: `/getUserProfile/${uid}/`,
    mapper: (data = {}, prev) => {
      if (!data?.main) return prev;
      return {...prev, ...data.main};
    }
  });

  useEffect(() => {
    if (!player || !mentor) return null;
    if (player.location) {
      const [ , city ] = player.location.split('_');
      setUserLocation(city);
    }

    // eslint-disable-next-line
  }, [player]);

  if (status === 404) return <Navigate replace to="/404" />;
  if (error) return <Navigate replace to="/update" />;

  const minDateBirth = new Date();
  minDateBirth.setFullYear(minDateBirth.getFullYear() - 12);

  const maxDateBirth = new Date();
  maxDateBirth.setFullYear(maxDateBirth.getFullYear() - 6);

  // let birthLocationOpts = [];
  // if (player.birth_location) {
  //   birthLocationOpts.push({
  //     label: player.birth_location,
  //     value: player.birth_location
  //   });
  // }

  let locationOpts = [];
  if (player?.location) {
    locationOpts.push({
      label: player.location,
      value: `${player.gmt}_${player.location}`
    });
  }

  let selectedSchool = [];
  if (player?.school && player?.school_name) {
    selectedSchool.push({
      label: player.school_name,
      value: player.school
    });
  }

  if (!uid) return null;

  // const _player = filterByKey(mentor.player, 'uid_gamer', uid);
  // let relationUid = null;
  // if (_player.length && _player[0].relation) relationUid = _player[0].relation.uid;

  const inputs = [
    // { id: 'phone', name: 'phone', component: Input,
    //   props: { label: 'Номер телефона', value: player && standartPhone(player.phone), placeholder: '+7', fullWidth: true,
    //     mask: '+7 ### ### ## ##'
    //   }
    // },
    { id: 'name', name: 'name', component: Input,
      props: { label: 'Никнейм', placeholder: 'Светик', fullWidth: true,
        value: player?.name || ''
      }
    },
    { id: 'location', name: 'location', component: Select, validation: 'select',
      props: {
        label: 'Ваш город', placeholder: 'Москва', value: player?.location ? `${player?.gmt}_${player?.location}` : '',
        query: player?.location, options: locationOpts,
        searchPlaceholder: 'Например, "Москва"', fullWidth: true, isSearchable: true,
        loadUrl: '/getcity', mapper: (data) => data.map(({ gmt, name }) => ({ label: name, value: `${gmt}_${name}` }))
      }
    },
    { id: 'school', name: 'school', component: Select, validation: 'select',
      props: {
        label: 'Номер школы', placeholder: 'Выберите школу', fullWidth: true, isSearchable: true,
        value: player?.school || '', options: selectedSchool,
        loadUrl: `/getschool/${userLocation}`, mapper: (data) => data.map(({ name, uid }) => ({ label: name, value: uid })),
        customParams: {
          user_uid: uid,
          city: player && player.location,
          buttonUrl: '/newschool/',
          buttonText: 'Школы нет в списке',
          inputText: 'Введите название школы',
        }
      }
    },
    { id: 'class', name: 'class', component: Select, validation: 'select',
      props: { label: 'Класс', placeholder: '1', options: grades, value: player?.class || '', showDropdownButton: false }
    },
    { id: 'class_sign', name: 'class_sign', component: Select, validation: 'select',
      props: { placeholder: 'А', options: letters, value: player?.class_sign || '', showDropdownButton: false }
    },
    /* { id: 'birth_date', name: 'birth_date', component: DateInput,
      props: { type: 'date', label: 'Дата рождения', value: (player && player.birth_date) || '', placeholder: '5 июня 1990 года',
        fullWidth: true, icon: <ReactSVG src="/icons/calendar.svg" />, minDate: minDateBirth, maxDate: maxDateBirth
      }
    }, */
    /* { id: 'birth_time', name: 'birth_time', component: Input,
      props: { type: 'time', label: 'Время рождения', placeholder: 'Например, 12:30', fullWidth: true, value: player.birth_time || '',
        pattern: '[0-9]{2}:[09]{2}', icon: <ReactSVG src="/icons/time.svg" />
      }
    }, */
    /* { id: 'birth_location', name: 'birth_location', component: Select, validation: 'select',
      props: {
        label: 'Место рождения', placeholder: 'Выберите город', value: player.birth_location, query: player.birth_location, options: birthLocationOpts,
        searchPlaceholder: 'Сочи', fullWidth: true, isSearchable: true,
        loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
      }
    }, */
    /* { id: 'relation', name: 'relation', component: Select, validation: 'select',
      props: {
        label: 'Тип родства', placeholder: 'Выберите тип родства', fullWidth: true,
        value: relationUid || '', disabled: !relationUid,
        loadUrl: `/getmentoringstructure`, mapper: (data) => (data.for_sex[mentor.gender].map(item => ({ label: item.name, value: item.uid })))
      }
    }, */
  ];

  const onFormChange = (data) => {
    if (data.location) {
      const [ , city ] = data.location.split('_');
      setUserLocation(city);
    }
  };

  const onSubmit = data => {
    const fd = new FormData();
    for (let key in data) {
      if (key === 'phone') fd.append(`params[${key}]`, data[key].replace(/\s+/g, ''));
      else if (key === 'ac_domashka' || key === 'ac_homework') {
        if (!data[key]) fd.append(`params[${key}]`, 0);
        else fd.append(`params[${key}]`, 1);
      }
      else fd.append(`params[${key}]`, data[key]);
    }

    if (data.location) {
      const [ gmt, location ] = data.location.split('_');
      fd.append('params[gmt]', gmt);
      fd.append('params[location]', location);
    }

    api
      .post(`/updateUserProfile/${player.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          let playerKey = mentor.player.findIndex(({ uid_gamer }) => (String(player.uid) === String(uid_gamer)));

          if (playerKey !== -1) {
            dispatch(setItem(['user', 'player', playerKey, 'info'], data));
            dispatch(setItem(['players', player.uid], data));
            dispatch(setItem(['notifications', 'updateUser'], { type: 'success', content: 'Изменения сохранены' }));
            navigate(`/update-player/${player.uid}`);
          } else {
            dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка. Обновите страницу и попробуйте снова' }));
          }
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleChangePhoto = (e) => {
    const formdata = new FormData();
    formdata.append('file', e.target.files[0], e.target.files[0].name);
    formdata.append('user_uid', player.uid);

    api
      .post(`/uploadavatars/`, formdata)
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['players', uid, 'avatar'], result.url));
          dispatch(setItem(['user', 'current_player', 'avatar'], result.url));
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickRemoveProfile = () => {
    api
      .get(`/deleteaccount/${player.uid}/`)
      .then(response => {
        let playerKey = mentor.player.findIndex(({ uid_gamer }) => (String(player.uid) === String(uid_gamer)));
        if (playerKey !== -1) {
          dispatch(sliceItem(['user', 'player'], playerKey));
          dispatch(deleteItem(['user'], 'current_player'));
        }
        dispatch(setItem(['notifications', 'deleteUser'], { type: 'error', content: 'Профиль успешно удален' }));
        navigate('/');
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'deleteUser'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const buttons = (
    <>
      <Checkbox
        id="ac_domashka"
        name="ac_domashka"
        label={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', lineHeight: 1.2 }}>
            <span>Автоматически подтверждать <br /><b>Домашку</b></span>
            <Modal
              viewStyle="bottom"
              showClose
              control={forwardRef(({ onClick }, ref) => <ReactSVG src="/icons/question-2.svg" wrapper="span" className={styles.iconInfo} onClick={(e) => { e.preventDefault(); onClick(e) }} ref={ref} />)}
            >
              <b>Выбрав эту опцию</b>, домашнее задание будет подтверждено автоматически, и ребёнок <b>сразу получит кристаллы</b> после того, как отправит фото выполненной домашки в MeTimer. Если же вы <b>не поставите</b> галочку, то после того, как ребёнок выполнит домашнее задание и отправит его в MeTimer, <b>вам нужно будет подтвердить</b> выполнение, чтобы школьник <b>получил кристаллы</b>.
            </Modal>
          </div>
        }
        onChange={e => onSubmit({ ac_domashka: e.target.checked })}
        checked={player?.ac_domashka ?? false}
        value={player?.ac_domashka ?? false}
      />
      <Checkbox
        id="ac_homework"
        name="ac_homework"
        label={
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', lineHeight: 1.2 }}>
            <span>Автоматически подтверждать <br /><b>Домашние дела</b></span>
            <Modal
              viewStyle="bottom"
              showClose
              control={forwardRef(({ onClick }, ref) => <ReactSVG src="/icons/question-2.svg" wrapper="span" className={styles.iconInfo} onClick={(e) => { e.preventDefault(); onClick(e) }} ref={ref} />)}
            >
              <b>Выбрав эту опцию</b>, Домашнее дело будет подтверждено автоматически, и ребёнок <b>сразу получит кристаллы</b> после того, как нажмет на кнопку “Выполнил” в разделе Домашних дел. Если же вы <b>не поставите</b> галочку, то после того, как ребёнок выполнит Домашнее дело и нажмет на кнопку “Выполнил”, <b>вам нужно будет подтвердить</b> выполнение, чтобы школьник <b>получил кристаллы</b>.
            </Modal>
          </div>
        }
        onChange={e => onSubmit({ ac_homework: e.target.checked })}
        checked={player?.ac_homework ?? false}
        value={player?.ac_homework ?? false}
      />
      <Button
        type="submit"
        fullWidth={true}
        disabled={!isSubmitActive}
        butStyle="primary"
      >
        Сохранить
      </Button>
      {/* <Button
        to={`/invite-mentor/${player && player.uid}`}
        fullWidth={true}
        butStyle="primary"
      >
        Добавить взросого ребенку
      </Button> */}
    </>
  );

  return (
    <MainLayout title="Профиль" showBackButton={false}>
      <UserHeader user={player} onChange={handleChangePhoto} />

      {player &&
        <Form inputs={inputs} onChange={onFormChange} onSubmit={onSubmit} buttons={buttons} inputClass="dark" onValidChange={setSubmitActive} />
      }
      <div className={styles.removeButton}>
        <Modal
          viewStyle="bottom"
          control={forwardRef(({ onClick }, ref) => (<button className={styles.link} onClick={onClick} ref={ref}>Удалить учетную запись</button>))}
          title="Вы уверены что хотите удалить учетную запись?"
          buttons={[
            {
              text: 'Удалить',
              style: 'danger',
              onClick: handleClickRemoveProfile
            },
            {
              action: 'close',
              text: 'Отмена',
              style: 'secondary',
            }
          ]}
        >
          <div className="text-center">Действие невозможно будет отменить!</div>
        </Modal>
        {player && <div className={styles.userUid}>Пользователь ID {player.uid}</div>}
      </div>
      <div style={{ marginBottom: '40px' }} />
    </MainLayout>
  );
};

export default UpdatePlayer;
