import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RegisterLayout } from 'components/Layouts';
import Form from 'components/Form';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';

import styles from './account.module.css';
import Switch from 'components/fields/Switch';

const SelectType = () => {
  const navigate = useNavigate();
  const user = useSelector(selectItem(['user']));
  const [ isValid, setIsValid ] = useState(false);

  const inputs = [
    { id: 'type', name: 'type', component: Switch, validation: 'select',
      props: { size: 'large', containerClass: styles.types, options: [
        { label: 'Я школьник', value: 'P', className: styles.typeItem },
        { label: 'Я родитель', value: 'M', className: styles.typeItem }
      ] }
    },
  ];

  useEffect(() => {
    if (user && user.uid) {
      if (!user.name) navigate('/create-player');
      else navigate('/');
    }

    // eslint-disable-next-line
  }, [user]);

  const onFormSubmit = data => {
    // if (data.type === 'P') navigate('/select-class');
    if (data.type === 'P') navigate('/send-link');
    else navigate('/register');
  };

  return (
    <RegisterLayout showLogo>
      <h1 className={styles.title}>Регистрация</h1>
      <div className={styles.content} style={{ marginTop: '50px' }}>
        <Form
          inputs={inputs}
          onSubmit={onFormSubmit}
          onValidChange={setIsValid}
          buttons={
            <>
              <Button
                disabled={!isValid}
                type="submit"
                butStyle="primary"
                fullWidth
              >
                Далее
              </Button>
              <Button
                to="/login"
                butStyle="secondary"
                fullWidth
              >
                У меня уже есть профиль
              </Button>
            </>
          }
        />
      </div>
    </RegisterLayout>
  );
}

export default SelectType;
