import { useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
// import Button from 'components/Button';
import { setItem } from 'store/app/actions';

import styles from './header.module.css';

const AddButton = ({ active, onClick }) => {
  return (
    <Link to="/add-player" className={classNames(styles.profile, active && styles.active)} onClick={onClick}>
      <div className={styles.profileImageContainer}>
        <ReactSVG src="/icons/plus.svg" className={styles.iconAdd} alt="" />
      </div>
      <div className={styles.profileName}>Добавить</div>
    </Link>
  );
};

const Avatar = ({ user, href, onClick, active, index }) => {
  const name = user.info ? user.info.name : user.name;
  let avatar = user.info ? user.info.avatar : user.avatar;
  if (!avatar) avatar = `/icons/user-filled.svg`;

  return (
    <Link to={href} className={classNames(styles.profile, active && styles.active)} onClick={onClick}>
      <div className={styles.profileImageContainer} id={index === 0 ? 'first_player' : null}>
        <img src={avatar} className={styles.profileImage} alt="" />
      </div>
      <div className={styles.profileName}>{name}</div>
    </Link>
  );
};

const HeaderMentor = ({ user }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isAdd = pathname.startsWith('/add-player');
  const isMentorHome = pathname === '/';

  const handleClickUser = (uid_gamer) => {
    const player = user.player.find((player) => player.uid_gamer === uid_gamer);
    dispatch(setItem(['user', 'current_player'], player, { isHard: true }));
  };

  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        {/* <div className={styles.top}>
          <Button
            butStyle="text"
            icon={<img src={user.avatar ?? '/icons/user-small.svg'} width={18} height={18} alt="" />}
            className={styles.userButton}
            iconClassName={styles.userButtonIcon}
            to="/update"
          >
            {user.name}
          </Button>
        </div> */}
        <div className={classNames(styles.users, 'scroll-list')}>
          <div className={styles.usersItem}>
            <Avatar
              user={user}
              active={isMentorHome}
              href="/"
            />
          </div>
          {(user.player && user.player.length) ? (
            user.player.map((player, index) => {
              const isActive = !isAdd && user.current_player && user.current_player.uid_gamer === player.uid_gamer;
              return (
                <div className={styles.usersItem} key={player.uid_gamer}>
                  <Avatar
                    user={player}
                    active={!isMentorHome && isActive}
                    href="/player"
                    onClick={() => handleClickUser(player.uid_gamer)}
                    index={index}
                  />
                </div>
              );
            })
          ) : null}
          <AddButton active={isAdd} />
        </div>
      </div>
    </div>
  );
};

export default HeaderMentor;
