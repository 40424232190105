import { useNavigate } from 'react-router-dom';
import Modal from 'components/Modal';

// import styles from './modal-subscribe.module.css';

const ModalSubscribe = ({ open, onClose }) => {
  const navigate = useNavigate();

  return (
    <Modal
      open={open}
      viewStyle="bottom"
      title="Функция доступна по подписке"
      onClose={onClose}
      buttons={[
        {
          butStyle: 'primary',
          text: 'Оформить подписку - от 99 р/месяц',
          action: 'close',
          onClick: () => navigate('/tariffs')
        }
      ]}
    >
    </Modal>
  )
};

export default ModalSubscribe;
