import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import LessonsScheduleUpdate from 'components/schedule/LessonsScheduleUpdate';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';

const LessonsScheduleUpdatePage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));

  const { data: lessonsPlayer } = useApiQuery({
    name: ['lessons_player', user?.uid],
    path: `/getlesson/${user?.uid}/selected/`,
    skip: !user
  });

  const { data: schedule } = useApiQuery({
    name: ['lessons_schedule', user?.uid],
    path: `/getlessonshedule/${user?.uid}/`,
    skip: !user
  });

  const handleChangeDay = (day, lessons) => {
    const data = {
      user_uid: user.uid,
      day: day,
      lessons: lessons
    };

    api
      .post(`/setlessonshedule/`, JSON.stringify(data))
      .then(response => {
        const result = response.data.response;

        if (result) {
          dispatch(setItem(['lessons_schedule', user.uid], result, { isHard: true }));
          dispatch(setItem(['notifications', 'updateSchedule'], { type: 'success', content: 'Сохранено' }));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'updateSchedule'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  if (!user) return null;

  return (
    <MainLayout
      title="Расписание"
      description="Выберите дни занятий и настройте расписание уроков"
    >
      <LessonsScheduleUpdate
        lessons={lessonsPlayer}
        schedule={schedule}
        onChangeDay={handleChangeDay}
      />

      <ButtonsContainer>
        <Button
          butStyle="primary"
          disabled={!lessonsPlayer?.length}
          to="/lessons-schedule"
        >
          Далее
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default LessonsScheduleUpdatePage;
