import { forwardRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { format } from 'date-fns';
import classnames from 'classnames';
import ModalSelectDate from './ModalSelectDate';

import 'react-datepicker/dist/react-datepicker.min.css';
import './date-input.css';

import baseStyles from '../fields.module.css';
import styles from './date-input.module.css';

const DateInput = forwardRef(({ id, name, placeholder, type='text', hidden, value, labelClass, onChange, showDropdownButton = true,
  showDatePicker = true, minDate, maxDate, showTimePicker, timeStep = 5,
  containerClass, title, label, fullWidth, error, icon, notice, pattern, inputClass, ...props
}, ref) => {
  const [ isModalOpen, setModalOpen ] = useState(false);

  const handleFocus = (e) => {
    setModalOpen(true);
  };
  const handleClick = (e) => {
    setModalOpen(true);
  };

  const handleSave = (value) => {
    const saveDateFormat = `yyyy-MM-dd${showTimePicker ? ' HH:mm' : ''}`;
    const val = format(new Date(value), saveDateFormat);
    onChange && onChange({target: { name: name, value: val }});
  };

  const inputDateFormat = `${showDatePicker ? 'dd.MM.yyyy' : ''}${(showDatePicker && showTimePicker) ? ' ' : ''}${showTimePicker ? 'HH:mm' : ''}`;

  return (
    <div className={classnames(baseStyles.container, fullWidth && baseStyles.fullWidth, containerClass, hidden && baseStyles.hidden)}>
      {title && <div className={baseStyles.title}>{title}</div>}
      {label && <label className={classnames(baseStyles.label, labelClass)} htmlFor={id}>{label}</label>}
      <div className={baseStyles.inputContainer}>
        <input
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          className={classnames(baseStyles.input, icon && baseStyles.withIcon, inputClass && inputClass, error && 'error', value && 'filled')}
          value={value ? format(new Date(value), inputDateFormat) : ''}
          pattern={pattern}
          onFocus={handleFocus}
          onClick={handleClick}
          readOnly
          ref={ref}
          {...props}
        />
        {showDropdownButton && (
          <button type="button" className={styles.selectButton} onClick={handleClick}>
            <ReactSVG src="/icons/dropdown.svg" wrapper="span" />
          </button>
        )}
        {icon &&
          <span className={baseStyles.icon}>{icon}</span>
        }
      </div>
      {error &&
        <span className={baseStyles.errorText}>{error}</span>
      }
      {notice &&
        <span className={baseStyles.notice}>{notice}</span>
      }
      <ModalSelectDate
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        defaultValue={value}
        showDatePicker={showDatePicker}
        showTimePicker={showTimePicker}
        onSave={handleSave}
        minDate={minDate}
        maxDate={maxDate}
        timeStep={timeStep}
      />
    </div>
  );
});

export default DateInput;