import { format } from 'date-fns';
import { ru } from 'date-fns/locale';

import styles from './date-time-icon.module.css';

const DateTimeIcon = ({ value }) => {
  if (!value) return null;

  const date = new Date(value.replace(' ', 'T'));

  return (
    <div className={styles.root}>
      <div className={styles.item}>
        {format(date, 'EEEEEE, dd MMMM yyyy', { locale: ru })} г.
      </div>
      <div className={styles.item}>
        {format(date, 'HH:mm')}
      </div>
    </div>
  );
};

export default DateTimeIcon;
