import { useState, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import * as api from 'helpers/api';
import { MainLayout } from 'components/Layouts';
import Form from 'components/Form';
// import { PlayersSelect } from 'components/UsersSelect';
import Input from 'components/fields/Input';
// import Select from 'components/fields/Select';
// import DateInput from 'components/fields/DateInput';
import Button from 'components/Button';
import UserHeader from 'components/UserHeader';
import Modal from 'components/Modal';
import PinPad from 'components/header/PinPad';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import standartPhone from 'helpers/standartPhone';

import styles from './mentor.module.css';

const Update = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const [ isShowPinModal, setShowPinModal ] = useState(false);
  const [ pinCode, setPinCode ] = useState('');
  const [ count, setCount ] = useState(0);

  const minDateBirth = new Date();
  minDateBirth.setFullYear(minDateBirth.getFullYear() - 90);

  const maxDateBirth = new Date();
  maxDateBirth.setFullYear(maxDateBirth.getFullYear() - 18);

  // let locationOpts = [];
  // if (user.location) {
  //   locationOpts.push({
  //     label: user.location,
  //     value: `${user.gmt}_${user.location}`
  //   });
  // }

  const inputs = [
    { id: 'phone', name: 'phone', component: Input,
      props: {
        label: <div className={styles.phoneLabel}>Номер телефона <Link to="/update/phone"><ReactSVG src="/icons/edit.svg" /></Link></div>,
        value: standartPhone(user.phone), placeholder: '+7', fullWidth: true,
        mask: '+7 ### ### ## ##', readOnly: true
      }
    },
    { id: 'name', name: 'name', component: Input, validation: 'string',
      props: { label: 'Никнейм', value: user.name || '', placeholder: 'Михалыч', fullWidth: true }
    },
    // { id: 'location', name: 'location', component: Select, validation: 'select',
    //   props: {
    //     label: 'Ваш город', value: `${user.gmt}_${user.location}`, query: user.location, options: locationOpts,
    //     placeholder: 'Москва', fullWidth: true, isSearchable: true, searchPlaceholder: 'Москва',
    //     loadUrl: '/getcity', mapper: (data) => data.map(({ gmt, name }) => ({ label: name, value: `${gmt}_${name}` }))
    //   }
    // },
    // { id: 'birth_date', name: 'birth_date', component: DateInput,
    //   props: { type: 'date', label: 'Дата рождения', value: user.birth_date || '', placeholder: '5 июня 1990 года',
    //     fullWidth: true, icon: <ReactSVG src="/icons/calendar.svg" />, minDate: minDateBirth, maxDate: maxDateBirth
    //   }
    // },
    /* { id: 'birth_time', name: 'birth_time', component: Input,
      props: { type: 'time', label: 'Время рождения', placeholder: 'Например, 12:30', fullWidth: true, value: user.birth_time || '',
        pattern: '[0-9]{2}:[09]{2}', icon: <ReactSVG src="/icons/time.svg" />
      }
    }, */
  ];

  const onSubmit = data => {
    const fd = new FormData();
    for (let key in data) {
      if (key === 'phone') fd.append(`params[${key}]`, data[key].replaceAll(' ', ''));
      else fd.append(`params[${key}]`, data[key]);
    }

    // const [ gmt, location ] = data.location.split('_');

    // fd.append('params[gmt]', gmt);
    // fd.append('params[location]', location);

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          const data = {...result.user};
          delete(data.player);
          dispatch(setItem(['user'], data));
          dispatch(setItem(['notifications', 'updateUser'], { type: 'success', content: 'Изменения сохранены' }));
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const buttons = (
    <>
      {/* <Button
        type="button"
        fullWidth={true}
        butStyle=""
        onClick={() => setShowPinModal(true)}
      >
        Изменить ПИН-код
      </Button> */}
      <Button
        type="submit"
        fullWidth={true}
        butStyle="primary"
      >
        Сохранить
      </Button>
    </>
  );

  const handleClickPinButton = (number) => {
    setPinCode(state => state.length < 4 ? `${state}${number}` : state);
  };

  const handleClickPinRemove = () => {
    setPinCode(state => String(state).slice(0, String(state).length-1));
  };

  const handleSavePin = (code) => {
    if (String(code) === '0000') {
      dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Невозможно установить пин-код 0000' }));
      return null;
    };

    const fd = new FormData();
    fd.append('params[pin_code]', code);

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          setShowPinModal(false);
          dispatch(setItem(['pin_code'], true));
          dispatch(setItem(['notifications', 'updateUser'], { type: 'success', content: 'Пин-код сохранен' }));
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleChangePhoto = (e) => {
    const formdata = new FormData();
    formdata.append('file', e.target.files[0], e.target.files[0].name);
    formdata.append('user_uid', user.uid);

    api
      .post(`/uploadavatars/`, formdata)
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['user', 'avatar'], result.url));
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickRemoveProfile = () => {
    api
      .get(`/deleteaccount/${user.uid}/`)
      .then(response => {
        dispatch(setItem(['user'], {}, { isHard: true }));
        dispatch(setItem(['notifications', 'deleteUser'], { type: 'error', content: 'Профиль успешно удален' }));
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'deleteUser'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickSignOut = () => {
    dispatch(setItem(['user'], {}, { isHard: true }));
  };

  return (
    <MainLayout title={<span onClick={() => setCount(count+1)}>Настройки</span>}>
      <UserHeader user={user} onChange={handleChangePhoto} />
      {/* <PlayersSelect users={user.player} current={user.current_player?.uid_gamer} /> */}
      {/* <h2 className={styles.title}>Данные взрослого</h2> */}
      <Form inputs={inputs} onSubmit={onSubmit} buttons={buttons} inputClass="dark" />

      <div className={styles.removeButton}>
        <Modal
          viewStyle="bottom"
          control={forwardRef(({ onClick }, ref) => (<button className={styles.link} onClick={onClick} ref={ref}>Удалить учетную запись</button>))}
          title="Вы уверены что хотите удалить свою учетную запись?"
          buttons={[
            {
              text: 'Удалить',
              style: 'danger',
              onClick: handleClickRemoveProfile
            },
            {
              action: 'close',
              text: 'Отмена',
              style: 'secondary',
            }
          ]}
        >
          <div className="text-center">Действие невозможно будет отменить!</div>
        </Modal>
      </div>
      {count > 10 && (
        <div className={styles.removeButton}>
          <button className={styles.link} onClick={handleClickSignOut}>Выйти</button>
        </div>
      )}
      {user && <div className={styles.userUid}>Пользователь ID {user.uid}</div>}

      <Modal
        viewStyle="bottom"
        open={isShowPinModal}
        onClose={() => setShowPinModal(false)}
      >
        <div className={styles.pinModal}>
          <div className={styles.pinModalTitle}>Придумайте пин-код</div>
          <Input mask="####" placeholder="0000" inputClass={styles.pinModalInput} value={pinCode} readOnly={true} />
          <Button
            butStyle="primary"
            className={styles.pinModalSubmit}
            disabled={pinCode.length < 4}
            onClick={() => handleSavePin(pinCode)}
          >
            Далее
          </Button>
          <PinPad
            onClickButton={handleClickPinButton}
            onClickRemove={handleClickPinRemove}
          />
        </div>
      </Modal>
    </MainLayout>
  );
}

export default Update;
