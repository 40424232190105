import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import LessonsUpdate from 'components/schedule/LessonsUpdate';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';

const LessonsAllUpdate = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player ? user.current_player : null;
  const lessonsPlayer = useSelector(selectItem(['lessons_player', currentPlayer?.uid_gamer]))

  useApiQuery({
    name: ['lessons_player', currentPlayer?.uid_gamer],
    path: `/getlesson/${currentPlayer?.uid_gamer}/selected/`,
    skip: !currentPlayer
  });

  const handleChange = (uid, lesson_uid, name, checked) => {
    const formdata = new FormData();
    formdata.append('params[user_uid]', currentPlayer.uid_gamer);
    if (uid) formdata.append('params[std_uid]', uid);
    if (name) formdata.append('params[lesson_name]', name);
    if (lesson_uid) formdata.append('params[lesson_uid]', lesson_uid);
    formdata.append('params[set_task]', checked);
    formdata.append('params[type]', 'update');

    api
      .post(`/setlesson/`, formdata)
      .then(response => {
        const result = response.data.response;
        if (result) {
          dispatch(setItem(['lessons_player', currentPlayer.uid_gamer], result, { isHard: true }));
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'setLesson'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  if (!user) return null;

  const lessons = lessonsPlayer?.map(item => ({ ...item, lesson_uid: item.uid, checked: !!item.set_task }));

  const handleChangeAll = (checked) => {
    lessons?.forEach(({ uid, lesson_uid, name, checked: itemChecked }) => {
      if (itemChecked !== checked) handleChange(uid, lesson_uid, name, checked);
    });
  };

  return (
    <MainLayout
      title="Расписание"
      description="Выберите предметы, по которым задают домашние задания"
    >
      <LessonsUpdate
        user={user}
        lessons={lessons}
        onChange={handleChange}
        onChangeAll={handleChangeAll}
      />

      <ButtonsContainer>
        <Button
          butStyle="primary"
          disabled={!lessonsPlayer?.length}
          to="/player-lessons-update/time"
        >
          Далее
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default LessonsAllUpdate;
