import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import PortfailUpdate from 'components/portfail/PortfailUpdate';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';

const PortfailUpdatePage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player ? user.current_player : null;

  const { data: portfail, reset: resetPortfail } = useApiQuery({
    name: ['portfail', currentPlayer?.uid_gamer],
    path: `/getuserportfail/${currentPlayer?.uid_gamer}/`,
    skip: !currentPlayer
  });

  const handleClickItem = (uid = 0, name, customLessonUid = 0) => {
    const formdata = new FormData();
    formdata.append('params[user_uid]', currentPlayer.uid_gamer);
    formdata.append('params[name]', name);
    formdata.append('params[non_std_lesson_uid]', customLessonUid);
    formdata.append('params[std_uid]', uid);

    api
      .post(`/setuserportfail/`, formdata)
      .then(response => {
        const result = response.data.response;

        if (result) {
          resetPortfail();
          dispatch(setItem(['notifications', 'updatePortfail'], { type: 'success', content: 'Сохранено' }));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'updatePortfail'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  if (!user) return null;

  return (
    <MainLayout title="Портфель">
      <PortfailUpdate
        user={user}
        items={portfail}
        onClickItem={handleClickItem}
      />
      <ButtonsContainer>
        <Button
          butStyle="primary"
          to="/"
        >
          Далее
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default PortfailUpdatePage;
