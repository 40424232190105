import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';

import styles from './dropdown.module.css';

const Dropdown = ({ title, children, className }) => {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.header} onClick={() => setIsOpen(!isOpen)}>
        <div className={styles.title}>{title}</div>
        <ReactSVG src="/icons/dropdown.svg" className={classNames(styles.icon, isOpen && styles.open)} />
      </div>
      {isOpen &&
        <div className={styles.content}>
          {children}
        </div>
      }
    </div>
  );
};

export default Dropdown;