import { useState } from 'react';
import DateInput from 'components/fields/DateInput';
import styles from './lessons-time-update.module.css';

const LessonsTimeUpdate = ({ onChange, school_start, time_lesson, turn_lesson }) => {
  const [ start, setStart ] = useState(school_start ? `2000-01-01T${school_start.slice(0, 5)}` : null);
  const [ duration, setDuration ] = useState(time_lesson ? `2000-01-01T${time_lesson.slice(0, 5)}` : null);
  const [ recess, setRecess ] = useState(turn_lesson ? `2000-01-01T${turn_lesson.slice(0, 5)}` : null);

  return (
    <div className={styles.root}>
      <DateInput
        id="start"
        name="start"
        placeholder="00:00"
        label="Начало первого урока"
        onChange={e => {
          setStart(e.target.value);
          onChange({ start: e.target.value, duration, recess })
        }}
        minDate="2001-01-01 06:00"
        value={start}
        containerClass={styles.item}
        showDropdownButton={false}
        labelClass={styles.label}
        showDatePicker={false}
        showTimePicker
      />

      <DateInput
        id="duration"
        name="duration"
        placeholder="00:00"
        label="Продолжительность урока"
        onChange={e => {
          setDuration(e.target.value);
          onChange({ start, duration: e.target.value, recess })
        }}
        minDate="2001-01-01 00:05"
        maxDate="2001-01-01 00:59"
        value={duration}
        containerClass={styles.item}
        labelClass={styles.label}
        showDropdownButton={false}
        showDatePicker={false}
        showTimePicker
      />

      <DateInput
        id="recess"
        name="recess"
        placeholder="00:00"
        label="Продолжительность первой перемены"
        onChange={e => {
          setRecess(e.target.value);
          onChange({ start, duration, recess: e.target.value })
        }}
        minDate="2001-01-01 00:05"
        maxDate="2001-01-01 00:59"
        value={recess}
        containerClass={styles.item}
        labelClass={styles.label}
        showDropdownButton={false}
        showDatePicker={false}
        showTimePicker
      />
    </div>
  );
};

export default LessonsTimeUpdate;
