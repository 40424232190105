import { forwardRef, useState } from 'react';
import classnames from 'classnames';

import baseStyles from '../fields.module.css';
import styles from './radio.module.css';

const Radio = forwardRef(({ id, onChange, value, options, title, label, error, Notice, hidden, inputClass, ...props }, ref) => {
  const [ current, setCurrent ] = useState(value);

  if (!options) return null;

  const onRadioChange = (e) => {
    setCurrent(e.target.value);
    if (onChange) onChange(e);
  }

  return (
    <div className={classnames(styles.container, hidden && styles.hidden)}>
      {title && <div className={styles.title}>{title}</div>}
      {label && <label className={baseStyles.label}>{label}</label>}
      <div className={classnames(styles.radio)}>
        {options.map(({ label, value, primary, disabled, additional, className }) =>
          <>
            <label
              className={classnames(styles.option, className, String(current) === String(value) && styles.checked, primary && styles.primary, disabled && styles.disabled)}
              key={value} htmlFor={`${id}_${value}`}
            >
              <input
                type="radio"
                id={`${id}_${value}`}
                value={value}
                onChange={(e) => onRadioChange(e)}
                {...props}
                className={classnames(styles.input, inputClass && inputClass)}
                ref={ref}
              />
              <span className={classnames(styles.icon, String(current) === String(value) && styles.active)}></span>
              <span className={styles.text}>{label}</span>
            </label>
            {additional && (
              <div
                className={classnames(styles.additional, String(current) === String(value) && styles.checked)}
                onClick={() => onRadioChange({ target: { name: props.name, value, checked: true } })}
              >
                {additional}
              </div>
            )}
          </>
        )}
      </div>
      {error &&
        <span className={baseStyles.errorText}>{ error }</span>
      }
      {Notice &&
        <span className={classnames(baseStyles.notice, styles.notice)}><Notice /></span>
      }
    </div>
  );
});

export default Radio;
