import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './register-layout.module.css';

const RegisterLayout = ({ children, buttons, className, style }) => {
  return (
    <div className={classNames(styles.container, className)} style={style}>
      <div className={classNames(styles.header, 'container')}>
        <Link to="/" className={styles.logo}>
          <img src="/images/logo.svg" className={styles.logoImage} alt="Me Tracker" />
        </Link>
        <Link to="/" className={styles.textLogo}>
          <img src="/images/logo-text.svg" className={styles.textLogoImage} alt="Me Tracker" />
        </Link>
      </div>
      <div className={classNames(styles.content, 'container')}>
        {children}
      </div>
      {buttons &&
        <div className={classNames(styles.buttons, 'container')}>
          {buttons}
        </div>
      }
    </div>
  );
}

export default RegisterLayout;