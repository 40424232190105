import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { MainLayout } from 'components/Layouts';
import HeaderMentor from 'components/header/HeaderMentor';
import ShareBlock from 'components/homepage/ShareBlock';
import Block from 'components/Block';
import Modal from 'components/Modal';
import { selectItem } from 'store/app/getters';

const Mentor = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [ isShowSuccess, setShowSuccess ] = useState(false);

  const user = useSelector(selectItem(['user']));

  useEffect(() => {
    if (hash === '#success-pay') setShowSuccess(true);
  }, [hash]);

  return (
    <MainLayout>
      <HeaderMentor user={user} />

      <Block>
        <img src="/images/add-players.svg" alt="" width="100%" />
      </Block>

      <ShareBlock />

      <Modal
        open={isShowSuccess}
        title="Ваша подписка оформлена!"
        viewStyle="bottom"
        buttons={[
          {
            butStyle: 'primary',
            text: 'Ок',
            action: 'close',
            onClick: () => navigate('/')
          }
        ]}
      >
        {user?.tariff?.date_end && (
          <>Подписка действительна до {format(new Date(user?.tariff?.date_end), 'dd.MM.yyyy')}</>
        )}
      </Modal>
    </MainLayout>
  );
};

export default Mentor;