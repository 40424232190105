import Button from '../../Button';
import { forwardRef } from 'react';
import Modal from '../../Modal';

import styles from './tariff-notification.module.css';

const TariffNotification = ({ tariff, user_type, player_tariff_mes, uid_gamer }) => {
  if (!tariff || !tariff.message) return null;

  return (
    <div className={styles.root}>
      <div className={styles.text} dangerouslySetInnerHTML={{ __html: tariff.message }} />
      <div className={styles.buttons}>
        {user_type === 'M' &&
          <Button
            className={styles.button}
            to={`/tariffs?player=${uid_gamer}`}
            butStyle="tertiary"
            size="medium"
            fullWidth
          >
            Выбрать тариф
          </Button>
        }
        {(user_type === 'P' && player_tariff_mes) &&
          <Modal
            viewStyle="bottom"
            buttons={[
              { text: 'Закрыть', action: 'close', style: 'primary' }
            ]}
            control={forwardRef(({ onClick }, ref) =>
              <Button
                className={styles.button}
                butStyle="tertiary"
                size="medium"
                onClick={onClick}
                fullWidth
                ref={ref}
              >
                Выбрать тариф
              </Button>
            )}
          >
            {player_tariff_mes}
          </Modal>
        }
      </div>
    </div>
  );
};

export default TariffNotification;
