export const grades = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' }
];

export const letters = [
  { value: 'А', label: 'А' },
  { value: 'Б', label: 'Б' },
  { value: 'В', label: 'В' },
  { value: 'Г', label: 'Г' },
  { value: 'Д', label: 'Д' },
  { value: 'Е', label: 'Е' },
  { value: 'Ё', label: 'Ё' },
  { value: 'Ж', label: 'Ж' },
  { value: 'З', label: 'З' },
  { value: 'И', label: 'И' },
  { value: 'К', label: 'К' },
  { value: 'Л', label: 'Л' },
  { value: 'М', label: 'М' },
  { value: 'Н', label: 'Н' },
  { value: 'О', label: 'О' },
  { value: 'П', label: 'П' },
  { value: 'Р', label: 'Р' },
  { value: 'С', label: 'С' },
  { value: 'Т', label: 'Т' },
  { value: 'У', label: 'У' },
  { value: 'Ф', label: 'Ф' },
  { value: 'Х', label: 'Х' },
  { value: 'Ц', label: 'Ц' },
  { value: 'Ч', label: 'Ч' },
  { value: 'Ш', label: 'Ш' },
  { value: 'Щ', label: 'Щ' },
  { value: 'Ы', label: 'Ы' },
  { value: 'Э', label: 'Э' },
  { value: 'Ю', label: 'Ю' },
  { value: 'Я', label: 'Я' }
];

export const backpack_items = [
  {
    id: 'bp_artefacts',
    name: 'Достижения',
    href: '/achievements',
    disabled: true
    // image: '/images/about/chest.svg',
    // color: '#78C93E',
  },
  {
    id: 'bp_rewards',
    name: 'Награды от родителя',
    href: '/rewards',
    // image: '/images/about/mentor_rewards.svg',
    // color: '#2AA6FF'
  },
  {
    id: 'bp_gifts',
    name: 'Подарки от MeTimer',
    href: '/gifts',
    // image: '/images/about/skillary_gifts.svg',
    // color: '#FF803B',
    disabled: true
  },
];

export const tariffs = [
  { value: 0, label: 'Без ограничений' }
];

export const homeworks_bgs = [
  '/images/homework_bg/1.svg', '/images/homework_bg/2.svg', '/images/homework_bg/3.svg', '/images/homework_bg/4.svg', '/images/homework_bg/5.svg',
  '/images/homework_bg/6.svg', '/images/homework_bg/1.svg', '/images/homework_bg/2.svg', '/images/homework_bg/3.svg', '/images/homework_bg/4.svg'
];

export const wayTimeOptions = [
  { label: 'Нет', value: '0' },
  { label: '5 минут', value: '00:05' },
  { label: '15 минут', value: '00:15' },
  { label: '30 минут', value: '00:30' },
  { label: '45 минут', value: '00:45' },
  { label: '1 час', value: '00:60' },
  { label: '1 час 30 минут', value: '00:90' },
];

export const notificationOptions = [
  { label: 'Нет', value: '0' },
  { label: 'В момент события', value: '00:01' },
  { label: 'За 5 минут', value: '00:05' },
  { label: 'За 15 минут', value: '00:15' },
  { label: 'За 30 минут', value: '00:30' },
  { label: 'За 45 минут', value: '00:45' },
  { label: 'За 1 час', value: '01:00' },
  { label: 'За 2 часа', value: '02:00' },
  { label: 'За 1 день', value: '24:00' },
  { label: 'За 2 дня', value: '48:00' },
];

export const tasksColors = {
  R :[
    { label: 'Голубой', value: '#78E7FF80, #3F9BAF' },
    { label: 'Синий', value: '#78AEFF80, #4274BE' },
    { label: 'Фиолетовый', value: '#9B78FF80, #6E4FC6' },
  ],
  H :[
    { label: 'Желтый', value: '#FFE97880, #CBB751' },
    { label: 'Салатовый', value: '#DCFF7880, #91A94F' },
    { label: 'Зеленый', value: '#B7FF8B80, #66B138' },
  ],
  Y :[
    { label: 'Оранжевый', value: '#FFA28580, #CA7358' },
    { label: 'Красный', value: '#FF789880, #E24B6F' },
    { label: 'Розовый', value: '#FF78D980, #B34D96' },
  ]
};

export const dayNames = [ 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс' ];
