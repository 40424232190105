import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import getCurrentHost from './getCurrentHost';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || getCurrentHost() + '/api',
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
});

export const get = (param, track) => track ? trackPromise(api.post(param)) : api.get(param);
export const post = (param, body) => trackPromise(api.post(param, body));
export const put = (param, body) => trackPromise(api.put(param, body));
export const del = param => trackPromise(api.delete(param));
