import classNames from 'classnames';
import Checkbox from 'components/fields/Checkbox';

import styles from './portfail.module.css';

const Portfail = ({ items, isDone, readOnly, onChangeItem, onClickItem }) => {

  return (
    <div className={styles.root}>
      {items ? (
        items.map(({ uid, name, checked }) => (
          <div className={styles.item} onClick={onClickItem} key={uid}>
            <Checkbox
              id={uid}
              name={uid}
              label={name}
              checked={isDone}
              onChange={e => onChangeItem(uid, e.target.checked)}
              readOnly={readOnly}
            />
          </div>
        ))
      ) : (
        [...Array(5).keys()].map(key => (
          <div className={classNames(styles.item, 'skeleton-loader')} key={key} />
        ))
      )}
    </div>
  );
};

export default Portfail;
