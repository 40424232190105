import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Modal from 'components/Modal';
import NoItems from 'components/NoItems';

import styles from './shop.module.css';
import Button from 'components/Button';

const Shop = ({ items = [1, 2, 3, 4], crystalls, onClickBuy }) => {
  const [ itemToPay, setItemToPay ] = useState(null);

  if (!Object.keys(items).length) return ( // no items
    <NoItems children="Нет назначенных наград" />
  );

  return (
    <div className={styles.root}>
      {Object.values(items).map(({ name, units }, index) => (
        <div className={styles.row} key={index}>
          <div className={styles.rowTitle}>{name}</div>
          <div className={classNames(styles.list, 'scroll-list')}>
            {units?.map(({ uid, name, price, url, is_checked }, unitIndex) => {
              if (!uid) return (
                <div className={styles.item} key={`${unitIndex}_${index}`}>
                  <div className={classNames(styles.imageWrapper, 'skeleton-loader')} />
                  <div className={styles.content}>
                    <div className={classNames(styles.name, 'skeleton-loader')} />
                    <div className={classNames(styles.price, 'skeleton-loader')} />
                  </div>
                </div>
              );
              if (!is_checked) return null;
              return (
                <div className={styles.item} key={`${unitIndex}_${index}`}>
                  <div className={styles.imageWrapper}>
                    <img className={styles.image} src={url} alt={name} />
                  </div>
                  <div className={styles.content}>
                    <div className={styles.name}>
                      {name}
                    </div>
                    <div className={styles.price}>
                      <div className={styles.priceValue}>
                        <ReactSVG src="/images/crystal.svg" className={styles.priceIcon} wrapper="span" />
                        {price}
                      </div>
                    </div>
                  </div>
                  {onClickBuy && (
                    <div className={styles.buttons}>
                      <Button
                        butStyle="primary"
                        size="small"
                        onClick={() => setItemToPay({ uid, name, url, price })}
                        fullWidth
                      >
                        Купить
                      </Button>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ))}
      <Modal
        viewStyle="bottom"
        open={!!itemToPay}
        onClose={() => setItemToPay(null)}
        buttons={[
          {
            text: 'Да, купить',
            style: 'primary',
            onClick: () => {
              onClickBuy(itemToPay);
              setItemToPay(null);
            }
          },
          {
            action: 'close',
            text: 'Нет, я передумал',
            style: 'secondary',
          }
        ]}
      >
        {itemToPay && (
          <>
            <div className={styles.modalImageContainer}>
              <img src={itemToPay.url} className={styles.image} alt={itemToPay.name} />
            </div>
            <div className={styles.modalTitle}>
              Купить за {itemToPay.price} <ReactSVG src="/images/crystal.svg" className={styles.modalPriceIcon} wrapper="span" /> ?
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default Shop;