import ScheduleDay from './ScheduleDay';

import styles from './homework-schedule.module.css';

const minHour = 8;
const maxHour = 22;

const HomeworkSchedule = ({ items, onChange }) => {
  let timeOptionsFrom = [];
  let timeOptionsTo = [];
  for (let hour=minHour; hour<=maxHour; hour++) {

    if (hour < maxHour) timeOptionsFrom.push({ label: `С ${hour}:00`, value: `0${hour}:00`.slice(-5, 6)});
    if (hour < maxHour) timeOptionsFrom.push({ label: `С ${hour}:30`, value: `0${hour}:30`.slice(-5, 6)});

    timeOptionsTo.push({ label: `До ${hour}:00`, value: `0${hour}:00`.slice(-5, 6)});
    if (hour < maxHour) timeOptionsTo.push({ label: `До ${hour}:30`, value: `0${hour}:30`.slice(-5, 6)});
  };

  const handleChangeDay = (day, time_uid, checked, start, end) => {
    onChange(day, time_uid, checked, start, end);
  };

  const lastSelectedDay = Object.keys(items).findLast(day => !!(items[day].shedule && items[day].shedule.uid));

  return (
    <div className={styles.root}>
      {Object.keys(items).map(day => {
        let time = null;
        if (items[day].shedule && items[day].shedule.uid) time = items[day].shedule;
        else if (lastSelectedDay) time = { start: items[lastSelectedDay].shedule.start, end: items[lastSelectedDay].shedule.end };

        return (
          <ScheduleDay
            id={day}
            name={items[day].name}
            time={time}
            timeOptionsFrom={timeOptionsFrom}
            timeOptionsTo={timeOptionsTo}
            onChange={handleChangeDay}
            key={day}
          />
        );
      })}
    </div>
  );
};

export default HomeworkSchedule;
