import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import FileInput from 'components/fields/FileInput';
import Gallery from 'components/Gallery';
import HomeworkComments from './HomeworkComments';

import styles from './homework.module.css';


const PlayerButtons = ({ current, onClickSubmit, onChangeFile }) => {
  if (!current) return null;

  const { uid, lesson_name, files, is_complete, is_done, is_rejected, comment, lesson } = current;

  return (
    <>
      <HomeworkComments {...current} user_type="P" />

      {(is_rejected && current.comment) &&
        <div className={comment}>{comment}</div>
      }

      {files?.length ? (
        <div className={styles.gallery}>
          <Gallery
            items={files.map(({ file_url }) => ({ link: file_url }))}
            onChangeFile={(file, old_file) => onChangeFile(file, uid, lesson, old_file)}
          />
        </div>
      ) : null}

      <ButtonsContainer>
        {is_done ? (
          <Button>Кристаллы начислены</Button>
        ) : (
          is_complete ? (
            <Button>Отправлено на проверку</Button>
          ) : (
            <>
              <FileInput
                id="photo"
                name="photo"
                butStyle="secondary"
                className={styles.downloadButton}
                text={`Загрузить ${lesson_name}`}
                onChange={e => onChangeFile(e.target.files[0], uid, lesson)}
              />
              <Button
                butStyle="primary"
                fullWidth={true}
                onClick={() => onClickSubmit(uid)}
                disabled={!files.length}
              >
                Я выполнил
              </Button>
            </>
          )
        )}
      </ButtonsContainer>
    </>
  );
};

export default PlayerButtons;
