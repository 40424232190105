import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Portfail from 'components/portfail/Portfail';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import useApiQuery from 'hooks/useApiQuery';

const PortfailPage = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player ? user.current_player : null;

  const { data: portfail } = useApiQuery({
    name: ['portfail', currentPlayer?.uid_gamer],
    path: `/getuserportfail/${currentPlayer?.uid_gamer}/`,
    skip: !currentPlayer
  });

  const portfailItems = portfail && Object.values(portfail).reduce((acc, { units }) => (units ? [ ...acc, ...units.filter(({ checked }) => !!checked)] : acc), []);

  const handleClickItem = () => {
    dispatch(setItem(['notifications', 'portfailMessage'], { type: 'success', content: 'Когда ребёнок кладёт предмет в портфель, он проставляет галочку напротив предмета, который положил. После того, как он проставит все галочки, он получит награду!' }));
  };

  if (!user) return null;

  return (
    <MainLayout title="Портфель">
      <Portfail items={portfailItems} onClickItem={handleClickItem} readOnly isDone={currentPlayer?.portfail_checked} />
    </MainLayout>
  );
};

export default PortfailPage;
