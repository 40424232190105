import { forwardRef, useEffect } from 'react';
import Popup from 'reactjs-popup';
import classnames from 'classnames';
import Button from 'components/Button';
import { stopScroll, startScroll } from 'helpers/scroll';

import 'reactjs-popup/dist/index.css';
import './modal.css';

const Modal = forwardRef(({ title, viewStyle, open, children, buttons, showClose, control: Control, onClose, closeOnDocumentClick = true }, ref) => {
  useEffect(() => {
    return () => startScroll();
  }, []);

  return (
    <Popup
      className={classnames('modal', viewStyle && 'modal-'+viewStyle)}
      trigger={Control && <Control />}
      nested={!buttons}
      open={open}
      onClose={() => {
        startScroll();
        onClose && onClose();
      }}
      onOpen={() => {
        stopScroll();
      }}
      closeOnDocumentClick={closeOnDocumentClick}
      lockScroll
      modal
      ref={ref}
    >
      {close => (
        <>
          <div className="modal__container container">
            {title && <div className="modal__title"> {title} </div>}
            {children && <div className={classnames("modal__content", buttons && "modal__content--with-buttons")}> {children} </div>}
            {buttons &&
              <div className="modal__buttons">
                {buttons.map(({ text, action, onClick, disabled, butStyle = 'primary', className, fullWidth, icon }, index) =>
                  <Button
                    butStyle={butStyle}
                    className={classnames('modal__button', disabled && 'disabled', className)}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    icon={icon}
                    onClick={() => {
                      if (onClick) onClick();
                      if (action === 'close') close();
                    }}
                    key={index}
                  >
                    {text}
                  </Button>
                )}
              </div>
            }
          </div>
          {showClose &&
            <div className="modal__close" onClick={() => close()}>
              <img src="/icons/close.svg" className="modal__close-icon" alt="close" />
            </div>
          }
        </>
      )}
    </Popup>
  );
});

export default Modal;